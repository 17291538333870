import React from "react";
import LoginForm from "../../components/LoginForm";
import "./style.css";

const loginpage = ({ loginState }) => {
  return (
    <div className="login-page">
      <LoginForm loginState={loginState} />
    </div>
  );
};

export default loginpage;
 