import React, { useState, useEffect } from "react";
import "./Resumaker.css";
import $ from "jquery";
import { getToken } from "../../authetication/loginState";
import Images from "../../Images"
import {
  SkillForm,
  ProjectForm,
  AwardForm,
  EducationForm,
  ExperienceForm,
  ProfileForm,
} from "../../components/Forms";
import { getFormattedDate } from "../../helper/helper";
import { logout } from "../../authetication/loginState";
import { useNavigate } from 'react-router-dom';

function Resumaker({ loginState }) {
  const createResumeEndpoint = process.env.REACT_APP_CREATE_RESUME;
  const getResumeEndpoint = process.env.REACT_APP_GET_RESUMES;
  const deleteResumeEndpoint = process.env.REACT_APP_DELETE_RESUME;
  const formList = [
    "profile",
    "education",
    "work",
    "skills",
    "projects",
    "awards",
  ];
  
  const [formPageTracker, setFormPageTracker] = useState(0);
  const [selectedForm, setSelectedForm] = useState(formList[formPageTracker]);
  const [resumeId, setResumeId] = useState("");
  const [resumeName, setResumeName] = useState("");
  const [isDefault, setIsDefault] = useState(true);
  const [userResumes, setUserResumes] = useState([]);
  let navigate = useNavigate();

  const [loggedIn, setLoggedIn] = useState(getToken() ? true : false);
    const logoutUser = () => {
      logout();
      setLoggedIn(false);
      navigate({
        pathname: '/login',
        state: "userSessionExpired" 
      });
    
    };

  const handlePagination = (num,string) => {
    const newIndex = formPageTracker+1;
    if (newIndex >= 0 && newIndex <= 5) {
      if(formList.indexOf(string) !== formPageTracker)
      {
       setFormPageTracker(formPageTracker);
       setSelectedForm(formList[formPageTracker]);
      }
      else
      {
        setFormPageTracker(formPageTracker+1); 
        setSelectedForm(formList[newIndex]);
      }
    } 
    else {
      navigate(`/viewTemplates/${resumeId}`);
    }
  };

  const handleClickedForm = (form) => {
    if(formList.includes(form))
    { 
      setSelectedForm(form);
      if(resumeId !== "")
      {
        setFormPageTracker(formList.indexOf(form));
      }
    }
  };

  const generateNewResume = () => {
    $.ajaxSetup({
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `bearer ${getToken()}`,
      },
    });
    $.post(
      `${createResumeEndpoint}`,
      JSON.stringify({
        name: resumeName || "My Resume",
        defaultResume: isDefault,
      }),
      (data) => setResumeId(data)
    );
  };

  const getResumes = () => {
    $.ajaxSetup({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `bearer ${getToken()}`,
      },
    });
    $.get(`${getResumeEndpoint}`, (data) => {
      setUserResumes(
        data.sort(
          (x, y) =>
            new Date(y.creationTimeStamp) - new Date(x.creationTimeStamp)
        )
      );
    }).catch(error => {

        console.log("user session expired")
        logoutUser();
    })
  };
  const deleteResume = (id) => {
    if (window.confirm("This will delete the resume permanently. Are you sure?")){
      $.ajaxSetup({
        headers: {
          "Content-Type": "application/json",
          Accept: "text/plain",
          Authorization: `bearer ${getToken()}`,
        },
      });
      $.post(
        `${deleteResumeEndpoint}?resumeId=${id}`,
        (data) => getResumes()
      );
    }
  };

  useEffect(() => {
    loginState?.loggedIn ? getResumes() : loginState?.history.replace("/login");
  }, []);

  return (
    <div className="resumaker" style={{ 
      backgroundImage: `url(${Images.BACKGROUND})`,
      backgroundRepeat: 'repeat-y',
      backgroundSize: 'cover',
      width: '100vw',
      minHeight: '100vh'
      }}>
      <div className="resumaker__container">
        <div className="resumaker__item resumaker__formList">
          <h5 onClick={() => handleClickedForm("profile")}>Profile</h5>
          <h5 onClick={() => handleClickedForm("education")}>Education</h5>
          <h5 onClick={() => handleClickedForm("work")}>Experience</h5>
          <h5 onClick={() => handleClickedForm("skills")}>Skills</h5>
          <h5 onClick={() => handleClickedForm("projects")}>Projects</h5>
          <h5 onClick={() => handleClickedForm("awards")}>Awards</h5>
        </div>
        <div className="resumaker__item resumaker__item2 resumaker__form">
          {!resumeId && (
            <>
              <h2>Select or Create a New Resume</h2>
              <div className="resumaker__resumeItem">
                <div className="resumaker__createFlex">
                  <input
                    className="resumaker__createInput"
                    type="text"
                    onChange={(evt) => setResumeName(evt.target.value)}
                    placeholder="My Resume Name"
                    maxLength="64"
                  />
                  <button
                    className="resumaker__createButton"
                    onClick={generateNewResume}
                  >
                    Create New Resume
                  </button>
                </div>
                <div className="resumaker__checkContainer">
                  <label htmlFor="resumaker__checkbox">Make Default: </label>
                  <input
                    className="resumaker__createCheckbox"
                    type="checkbox"
                    onChange={(evt) => setIsDefault(evt.target.checked)}
                    defaultChecked={isDefault}
                    id="resumaker__checkbox"
                  />
                </div>
              </div>
              {userResumes.map((resume) => {
                return (
                  <div
                    className="resumaker__resumeItem"
                    key={resume?.resumeId}
                    onClick={() => setResumeId(resume?.resumeId)}
                  >
                    <p>Resume: {resume?.name}</p>
                    <p>
                      Date Created:{" "}
                      {getFormattedDate(resume?.creationTimeStamp)} | Date
                      Modified: {getFormattedDate(resume?.modifiedTimeStamp)}
                    </p>
                    <button className="resumaker_deletebtn" onClick={(e) => {e.stopPropagation(); deleteResume(resume?.resumeId)}}>Delete</button>
                  </div>
                );
              })}
            </>
          )}
          {resumeId && selectedForm === "skills" && (
            <SkillForm
              resumeId={resumeId}
              handlePagination={handlePagination}
              
            />
          )}
          {resumeId && selectedForm === "projects" && (
            <ProjectForm
              resumeId={resumeId}
              handlePagination={handlePagination}
             
            />
          )}
          {resumeId && selectedForm === "awards" && (
            <AwardForm
              resumeId={resumeId}
              handlePagination={handlePagination}
              
            />
          )}
          {resumeId && selectedForm === "education" && (
            <EducationForm
              resumeId={resumeId}
              handlePagination={handlePagination}
             
            />
          )}
          {resumeId && selectedForm === "work" && (
            <ExperienceForm
              resumeId={resumeId}
              handlePagination={handlePagination}
              
            />
          )}
          {resumeId && selectedForm === "profile" && (
            <ProfileForm
              resumeId={resumeId}
              handlePagination={handlePagination}
              
            />
          )}
        </div>
      </div>
      <div className="resumaker__progressContainer">
        <div
          className="resumaker__progress"
          style={{ width: `${(100 * formPageTracker) / 6}%` }}
        ></div>
        <p>{((100 * formPageTracker) / 6).toFixed(1)}%</p>
      </div>
    </div>
  );
}

export default Resumaker;
