import React, { useState, useEffect } from "react";
import "./style.css"
import { setIt, getIt} from "../../views/preSignupQuestion/localStorageFunction";

const Q3 = () => {
    const [selected, setSelected] = useState([]);
    const [c1,setC1] = useState(false);
    const [c2,setC2] = useState(false);
    const [c3,setC3] = useState(false);
    const [c4,setC4] = useState(false);

    const handleOnClick = (e) => {
        e.preventDefault();
        const x = selected;
        if(x.includes(e.target.name)){
            var ind = x.indexOf(e.target.name);
            x.splice(ind,1);
            if(e.target.name === "Fortune 500"){
                setC1(false);
            }
            if(e.target.name === "Midtier"){
                setC2(false);
            }
            if(e.target.name === "Start ups"){
                setC3(false);
            }
            if(e.target.name === "Get Me Anything!"){
                setC4(false);
            }    
        }
        else{
            x.push(e.target.name);
            if(e.target.name === "Fortune 500"){
          
              
                setC1(true);
                setC4(false);
             
            }
            if(e.target.name === "Midtier"){
                
                setC2(true);
                setC4(false);
                
            }
            if(e.target.name === "Start ups"){
               
                setC3(true);
                setC4(false);
              
            }
            if(e.target.name === "Get Me Anything!"){
               setC1(false);
                setC2(false);
                setC3(false);
                setC4(true);
            }

        }
        setSelected(x);
        setIt(x, "q3");
    }

    const getLocal = () => {
        var x = getIt("q3");
        x = x == null ? "" : x
        var x2 = x.split(",");
        setSelected(x2);
        setC1(false);
        setC2(false);
        setC3(false);
        setC4(false);
        if(x.includes("Fortune 500")){
            setC1(true);
        }
        if(x.includes("Midtier")){
            setC2(true);
        }
        if(x.includes("Start ups")){
            setC3(true);
        }
        if(x.includes("Get Me Anything!")){
            setC4(true);
        }
    };


    useEffect(() => {
        getLocal();
      }, []);

    return(<div className="CenterQ">
            <h5>Size of company you want to work in: </h5>
            <br></br>
            {c1 === false && <button name="Fortune 500" className="unselected" onClick={(e)=>handleOnClick(e)}>Fortune 500</button>}
            {c1 === true && <button name="Fortune 500" className="selected" onClick={(e)=>handleOnClick(e)}>Fortune 500</button>}
            {c2 === true && <button name="Midtier" className="selected" onClick={(e)=>handleOnClick(e)}>Midtier</button>}
            {c2 === false && <button name="Midtier" className="unselected" onClick={(e)=>handleOnClick(e)}>Midtier</button>}
            {c3 === true &&  <button name="Start ups" className="selected" onClick={(e)=>handleOnClick(e)}>Start ups</button>}
            {c3 === false && <button name="Start ups" className="unselected" onClick={(e)=>handleOnClick(e)}>Start ups</button>}
            {c4 === false && <button name="Get Me Anything!" className="unselected" onClick={(e)=>handleOnClick(e)}>Get Me Anything!</button>}
            {c4 === true && <button name="Get Me Anything!" className="selected" onClick={(e)=>handleOnClick(e)}>Get Me Anything!</button>}
            </div>
            );
};

export default Q3;