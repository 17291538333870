import React from "react";
import "./Forms.css";
import $ from "jquery";
import { getToken } from "../../authetication/loginState";
import { getFormDate } from "../../helper/helper";
import axios from "axios";
class ProfileForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      city: "",
      state: "",
      zip: "",
      name: "",
      phoneNumber: "",
      birthDate: "",
      image: "",
      Biography: "",
      disabilityStatus: -1,
      ethnicity: 0,
      fieldOfInterest: -1,
      gender: 0,
      veteranStatus: 0,
      maxEducation: -1,
      linkedIn: "",
      github: "",
      language: "",
      logged: false,
    };
    $.ajaxSetup({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });

    this.handleOnChange = this.handleOnChange.bind(this);
    this.getData = this.getData.bind(this);
  }
  componentDidMount() {
    this.getData();
  }

  getData = () =>
    $.get(process.env.REACT_APP_GET_USER, (data) => {
      this.setState({ name: data.name || "" });
      this.setState({ phoneNumber: data.phoneNumber || ""});
      this.setState({ birthDate: getFormDate(data.birthDate) });
      this.setState({ address: data.address || ""});
      this.setState({ city: data.city || ""});
      this.setState({ state: data.state || ""});
      this.setState({ veteranStatus: data.veteranStatus || 0});
      this.setState({ ethnicity: data.ethnicity || 0});
      this.setState({ fieldOfInterest: data.fieldOfInterest || -1});
      this.setState({ gender: data.gender || 0});
      this.setState({ maxEducation: data.maxEducation || -1});
      this.setState({ language: data.language || ""});
      this.setState({ github: data.github || ""});
      this.setState({ linkedIn: data.linkedIn || ""});
      this.setState({ disabilityStatus: data.disabilityStatus || -1});
      this.setState({ userId: data.userId });
      this.setState({ userLoginId: data.userLoginId });
      this.setState({ zip: data.zip || ""});
      this.setState({ Biography: data.Biography || ""});
      this.setState({ logged: true });
    });

  handleOnChange = (e) => {
    let temp = this.state;
    temp[e.target.name] = e.target.value;
    this.setState(temp);
  };

  validatePhone = (inputtxt) => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if(inputtxt.match(phoneno)) {
      return true;
    }
    else {
      return false;
    }
  }

  saveForms = (e) => {
    e.preventDefault();
    if (!this.validatePhone(this.state.phoneNumber) && this.state.phoneNumber != ""){
      alert("Invalid Phone Number")
      return
    }

    $.post(
      process.env.REACT_APP_UPDATE_USER,
      JSON.stringify(this.state),
      (data) => {
        this.props.handlePagination(1,"profile");
      }
    );

    var fileUpload = $("#image").get(0);
    var files = fileUpload.files;
    let imageData = new FormData();
    imageData.append("file", files[0]);

    let config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    axios.post(process.env.REACT_APP_IMAGE_ADD, imageData, config);
  };

  render() {
    return (
      <div className="projectForm">
        <h3>Your Profile</h3>
        <form>
          <div className="input">
            <h6>Name</h6>
            <input
              className="input__profileForm"
              value={this.state.name}
              name="name"
              onChange={(e) => this.handleOnChange(e)}
              type="text"
              placeholder="Jhon Does"
            />
            <br></br>
            <br></br>

            <h6>About Me</h6>
            <textarea
              className="input__profileForm"
              value={this.state.Biography}
              name="Biography"
              onChange={(e) => this.handleOnChange(e)}
              type="text"
              placeholder="1-2 lines about yourself and what internships you're looking for!"
            />
            <br></br>
            <br></br>

            <h6>Birth Date</h6>
            <input
              className="input__profileForm"
              value={this.state.birthDate}
              name="birthDate"
              onChange={(e) => this.handleOnChange(e)}
              type="date"
            />
            <br></br>
            <br></br>

            <h6>Phone Number</h6>
            <input
              className="input__profileForm"
              value={this.state.phoneNumber}
              name="phoneNumber"
              onChange={(e) => this.handleOnChange(e)}
              type="text"
              placeholder="939565737"
            />
            <br></br>
            <br></br>

            <h6>Image input</h6>
            <input
              className="input__profileForm"
              value={this.state.image}
              name="image"
              id="image"
              onChange={(e) => this.handleOnChange(e)}
              type="file"
              placeholder="input image"
              accept="image/gif, image/jpeg, image/png"
            />
            <br></br>
            <br></br>

            <h6>Address</h6>
            <input
              className="input__profileForm"
              value={this.state.address}
              name="address"
              onChange={(e) => this.handleOnChange(e)}
              type="text"
              placeholder="55 Cornell"
            />
            <br></br>
            <br></br>

            <h6>City</h6>
            <input
              className="input__profileForm"
              value={this.state.city}
              name="city"
              onChange={(e) => this.handleOnChange(e)}
              type="text"
              placeholder="Irvine"
            />
            <br></br>
            <br></br>

            <h6>State</h6>
            <input
              className="input__profileForm"
              value={this.state.state}
              name="state"
              onChange={(e) => this.handleOnChange(e)}
              type="text"
              placeholder="California"
            />
            <br></br>
            <br></br>

            <h6>Zip</h6>
            <input
              className="input__profileForm"
              value={this.state.zip}
              name="zip"
              onChange={(e) => this.handleOnChange(e)}
              type="text"
              placeholder="92615"
            />
            <br></br>
            <br></br>

            <h6>LinkedIn</h6>
            <input
              className="input__profileForm"
              value={this.state.linkedIn}
              name="linkedIn"
              onChange={(e) => this.handleOnChange(e)}
              type="text"
              placeholder=""
            />
            <br></br>
            <br></br>

            <h6>Github</h6>
            <input
              className="input__profileForm"
              value={this.state.github}
              name="github"
              onChange={(e) => this.handleOnChange(e)}
              type="text"
              placeholder=""
            />
            <br></br>
            <br></br>

            <h6>Language</h6>
            <input
              className="input__profileForm"
              value={this.state.language}
              name="language"
              onChange={(e) => this.handleOnChange(e)}
              type="text"
              placeholder="English"
            />
            <br></br>
            <br></br>

            <h6>Gender:</h6>
            <select
              className="input__selectForm"
              value={this.state.gender}
              name="gender"
              onChange={(e) =>
                this.setState({ gender: parseInt(e.target.value) })
              }
            >
              <option value="0" 
              disabled="gender">
                -- select one --
              </option>
              <option value="1">Male</option>
              <option value="2">Female </option>
              <option value="3">Other </option>
            </select>
            <br></br>
            <br></br>

            <h6>Disability Status:</h6>
            <select
              className="input__selectForm"
              value={this.state.disabilityStatus}
              name="disabilityStatus"
              onChange={(e) =>
                this.setState({ disabilityStatus: parseInt(e.target.value) })
              }
            >
              <option
                value="-1"
                disabled="Disability Status"
              >
                -- select one --
              </option>
              <option value="0">I have a disability</option>
              <option value="1">I do NOT have a disability</option>
              <option value="2">Prefer Not to Disclose</option>
            </select>
            <br></br>
            <br></br>

            <label>Veteran Status:</label>
            <br></br>
            <select
              className="input__selectForm"
              value={this.state.veteranStatus}
              name="veteranStatus"
              onChange={(e) =>
                this.setState({ veteranStatus: parseInt(e.target.value) })
              }
            >
              <option value="0" 
              disabled="Veteran Status">
                -- select one --
              </option>
              <option value="1">I am a protected veteran</option>
              <option value="2">I am NOT a protected veteran</option>
              <option value="3">Prefer Not to Disclose</option>
            </select>
            <br></br>
            <br></br>

            <label>Ethnicity:</label>
            <br></br>
            <select
              className="input__selectForm"
              value={this.state.ethnicity}
              name="ethnicity"
              onChange={(e) =>
                this.setState({ ethnicity: parseInt(e.target.value) })
              }
            >
              <option value="0" 
              disabled="ethnicity">
                -- select one --
              </option>
              <option value="1">
                American Indian/Alaskan (Not hispanic or Latino)
              </option>
              <option value="2">Asian </option>
              <option value="3">Black </option>
              <option value="4">Hawaiian/Pacific Islander </option>
              <option value="5">Hispanic or Latino</option>
              <option value="6">Prefer Not to Disclose</option>
              <option value="7">Two or More</option>
              <option value="8">White</option>
            </select>
            <br></br>
            <br></br>

            <h6>Max Education:</h6>
            <select
              className="input__selectForm"
              value={this.state.maxEducation}
              name="maxEducation"
              onChange={(e) =>
                this.setState({ maxEducation: parseInt(e.target.value) })
              }
            >
              <option value="-1" 
              disabled="Veteran Status">
                -- select one --
              </option>
              <option value="0">No Education</option>
              <option value="1">High School</option>
              <option value="2">Associate Degree</option>
              <option value="3">Bachelor Degree</option>
              <option value="4">Master Degree</option>
              <option value="5">PhD</option>
            </select>
            <br></br>
            <br></br>

            <h6>Field of Interest:</h6>
            <select
              className="input__selectForm"
              value={this.state.fieldOfInterest}
              name="fieldOfInterest"
              onChange={(e) =>
                this.setState({ fieldOfInterest: parseInt(e.target.value) })
              }
            >
              <option value="-1" 
              disabled="Veteran Status">
                -- select one --
              </option>
              <option value="0">STEM</option>
              <option value="1">Business</option>
              <option value="2">Arts/Humanities</option>
              <option value="3">Social Ecology</option>
              <option value="4">Undecided</option>
            </select>
            <br></br>
            <br></br>

            <button className="form__button" onClick={(e) => this.saveForms(e)}>
              Save & Next
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default ProfileForm;
