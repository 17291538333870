import React from 'react';
import "./style.css";
import { useLocation } from "react-router-dom";

function PortalNav() {
  const location = useLocation();

  return (
    !location.pathname.startsWith('/interview/') ? (
      <div className="portal-nav-container">
          <div className="portal-nav-text-container">
              <a >Applicants</a>
              <a href="https://recruiter.internguys.com/">Recruiters</a>
          </div>
      </div>
    ) : null
  )
}

export default PortalNav