import React, { useState, useEffect } from "react";
import $ from "jquery";
import { generateUID, getToday, ajaxSetup } from "../../helper/helper";
import Input from "./../Input/Input";
import "./Forms.css";

function SkillForm({ resumeId, handlePagination }) {
  const addSkillEndpoint = process.env.REACT_APP_ADD_SKILL;
  const getSkillEndpoint = process.env.REACT_APP_GET_SKILL;
  const editSkillEndpoint = process.env.REACT_APP_EDIT_SKILL;
  const deleteSkillEndpoint = process.env.REACT_APP_DELETE_SKILL;
  const addSkillToolEndpoint = process.env.REACT_APP_ADD_SKILL_TOOL;
  const editSkillToolEndpoint = process.env.REACT_APP_EDIT_SKILL_TOOL;
  const deleteSkillToolEndpoint = process.env.REACT_APP_DELETE_SKILL_TOOL;
  const [skillForms, setSkillForms] = useState([]);
  const [indx, setIndx] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [nextLoad, setNextLoad] = useState(false);
  const [added,setAdded] = useState(false);

  const [categoryError, setCategoryError] = useState("");
  const [skillError,setSkillError] = useState("");
  let validation = true;
  
  const singleTool = (skills, skillsFieldId) => {
    let placeholders = ["Java", "Python", "C", "JavaScript", "Ruby", "Bash", "Linux", "Go"];
    let result = [];
    let len = 0;
    skills &&
      skills
        .sort((x, y) => x.ordinal - y.ordinal)
        .forEach((skill) => {
          result.push(
            <Input
            key={`${skillsFieldId}-${len}`}
              type="text"
              placeholder={placeholders[len]}
              inputStyle="subForm"
              value={skill.name}
              parentIdName={skillsFieldId}
              idName={skill.skillsId}
              toolCreated={skill.creationTimeStamp}
              toolActive={skill.active}
              toolOrdinal={skill.ordinal}
            />
          );
          len += 1;
        });
    for (; len < 8; len++) {
      result.push(
        <Input
        key={`${skillsFieldId}-${len}`}
          type="text"
          placeholder={placeholders[len]}
          inputStyle="subForm"
          value={""}
          parentIdName={skillsFieldId}
        />
      );
    }
    return result;
  };

  const singleForm = (
    ordinal = -1,
    id = null, // skillSectionName
    uid = generateUID(),
    active = true,
    creationTimeStamp = getToday(),
    modifiedTimeStamp = getToday(),
    skillSectionName,
    skills = null
  ) => {
    return (
      <>
        <form className="form__mainWithTool" indx={indx} id={id}>
          <input
            className="form__noDisplay"
            name="ordinal"
            value={ordinal}
            tabIndex={-1}
            readOnly
          />
          <input
            className="form__noDisplay"
            name="uid"
            value={uid}
            tabIndex={-1}
            readOnly
          />
          <input
            className="form__noDisplay"
            name="creationTimeStamp"
            value={creationTimeStamp}
            tabIndex={-1}
            readOnly
          />
          <input
            className="form__noDisplay"
            name="modifiedTimeStamp"
            value={modifiedTimeStamp}
            tabIndex={-1}
            readOnly
          />
          <input
            className="form__noDisplay"
            name="active"
            value={active}
            tabIndex={-1}
            readOnly
          />
          <Input
            indx={indx}
            type="text"
            title="Category"
            placeholder="Programming Languages"
            inputStyle="form"
            name="skillSectionName"
            value={skillSectionName}
            headStyle
          />
        </form>
        <form className="form__tool">
          <h6>Skills</h6>
          {singleTool(skills, id || uid)}
        </form>
      </>
    );
  };
  const addForm = () => {
    setAdded(true);
    setSkillForms([...skillForms, singleForm(indx)]);
    setIndx(indx + 1);
  };
  const removeForm = () => {
    document.getElementById("failure").style.display = 'none';
    if(skillForms.length === 1)
    {
    setAdded(false);
    }
    let [formToPop, newFormList] = [skillForms.pop(), skillForms];
    setSkillForms([...newFormList]);
    formToPop && setIndx(indx - 1);
    if (formToPop?.props?.children[0]?.props?.id) {
      const skillFieldsId = formToPop?.props?.children[0]?.props?.id;
      $.ajax(`${deleteSkillEndpoint}?id=${skillFieldsId}`, { type: "DELETE" });
    }
  };

  const saveForms = () => {
    setCategoryError("");
    validation = true;

    let result = document.querySelectorAll(".form__mainWithTool");
    result.forEach((form) => {
      const userInputData = {
        resumeId,
        skillSectionName: form["skillSectionName"]?.value,
        ordinal: parseInt(form["ordinal"]?.value),
      };

      if (form.skillSectionName.value === "")
      {
        setCategoryError("Please fill in your Category");
        validation = false;
      }

      if (validation)
      {

      let order = 1;
      if (form.id) {
        // edit existing skill name
        userInputData.resumeId = resumeId;
        userInputData.skillsFieldId = form.id;
        userInputData.creationTimeStamp = form["creationTimeStamp"]?.value;
        userInputData.modifiedTimeStamp = getToday();
        userInputData.active = form["active"]?.value;
        $.post(editSkillEndpoint, JSON.stringify(userInputData), (data) => {});
        let skillList = Array.from(
          document.getElementsByClassName(`${form.id}`)
        );
        skillList.forEach((skill) => {
          const skillData = {
            skillsFieldId: form.id,
            name: skill.value,
            ordinal: order,
          };
          order += 1;
          if (skill.id) {
            // delete skill if skill.value empty
            if (skill.value) {
              // edit skill
              skillData.skillsId = skill.id;
              skillData.creationTimeStamp = skill.getAttribute("toolcreated");
              skillData.modifiedTimeStamp = getToday();
              skillData.ordinal = skill.getAttribute("toolordinal");
              skillData.active = true;
              $.post(
                editSkillToolEndpoint,
                JSON.stringify(skillData),
                (data) => {}
              );
            } else {
              // delete skill if skill.value empty
              $.ajax(`${deleteSkillToolEndpoint}?id=${skill.id}`, {
                type: "DELETE",
              });
            }
          } else {
            // add new skill
            if (skill.value) {
              $.post(
                addSkillToolEndpoint,
                JSON.stringify(skillData),
                (data) => {}
              );
            }
          }
        });
      } else {
        // add new skill name
        let id;
        $.post(addSkillEndpoint, JSON.stringify(userInputData), (data) => {
          const { skillsFieldIdReturn } = data;
          id = skillsFieldIdReturn;
          const formUID = form["uid"]?.value;
          let skillList = Array.from(document.getElementsByClassName(formUID));
          skillList.forEach((skill) => {
            if (skill?.value) {
              const localSkill = {
                skillsFieldId: id,
                name: skill?.value,
                ordinal: order,
              };
              order += 1;
              $.post(
                addSkillToolEndpoint,
                JSON.stringify(localSkill),
                (innerData) => {}
              );
            }
          });
        });
      }
      document.getElementById("failure").style.display = 'none';
    }
    else
    {
      document.getElementById("failure").style.display = 'block';
    }
    });

    if(validation)
    {
    setNextLoad(true);
    setTimeout(() => {
      handlePagination(1,"skills");
    }, 1000);
  }
  else
  {
    setNextLoad(false);
  }
  };

  const getSkills = () => {
    $.get(getSkillEndpoint, { resumeId }).then((data) => {
      setIndx(data.length + 1);
      setSkillForms(
        data
          .sort((x, y) => x.ordinal - y.ordinal)
          .map((dataset) => {
            const {
              active,
              creationTimeStamp,
              modifiedTimeStamp,
              ordinal,
              skillSectionName,
              skills,
              skillsFieldId,
            } = dataset;

            setAdded(true);
            return singleForm(
              ordinal,
              skillsFieldId,
              null,
              active,
              creationTimeStamp,
              modifiedTimeStamp,
              skillSectionName,
              skills
            );
          })
      );
      setLoaded(true);
    });
  };

  useEffect(() => {
    ajaxSetup();
    getSkills();
    

  }, []);
  return loaded ? (
    <>
      {nextLoad && (
        <div className="form__loader">
          <div className="form__spinner"></div>
        </div>
      )}
      <div className="skillForm">
        <h3>Your Skills</h3>

        <div id = "failure">
            <h3> {categoryError}</h3>
            <h3> {skillError}</h3> 
            
        </div>

        {skillForms.map((component, indx) => (
          <React.Fragment key={indx}>{component}</React.Fragment>
        ))}
        <div className="form__buttons">
          <button className="form__button" onClick={() => saveForms()}>
            Save & Next
          </button>
          <button className="form__button" onClick={() => addForm()}>
            Add Skill
          </button>
          <button 
          style = {added ? {display : "block"} : {display : "none"}}
          className="form__button" 
          onClick={() => removeForm()}>
            Remove Skill
          </button>
        </div>
      </div>
    </>
  ) : (
    <div className="form__loader">
      <div className="form__spinner"></div>
    </div>
  );
}

export default SkillForm;
