import React, { useState, useEffect } from "react";
import Input from "./../Input/Input";
import $ from "jquery";
import {
  getFormDate,
  generateUID,
  getToday,
  ajaxSetup,
} from "../../helper/helper";
import "./Forms.css";

function ExperienceForm({ resumeId, handlePagination }) {
  const addExperienceEndpoint = process.env.REACT_APP_ADD_EXPERIENCE;
  const getExperienceEndpoint = process.env.REACT_APP_GET_EXPERIENCE;
  const editExperienceEndpoint = process.env.REACT_APP_EDIT_EXPERIENCE;
  const deleteExperienceEndpoint = process.env.REACT_APP_DELETE_EXPERIENCE;
  const addExperienceToolEndpoint = process.env.REACT_APP_ADD_EXPERIENCE_TOOL;
  const editExperienceToolEndpoint = process.env.REACT_APP_EDIT_EXPERIENCE_TOOL;
  const deleteExperienceToolEndpoint =
    process.env.REACT_APP_DELETE_EXPERIENCE_TOOL;
  const [experienceForms, setExperienceForms] = useState([]);
  const [indx, setIndx] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [nextLoad, setNextLoad] = useState(false);
  const [added,setAdded] = useState(false);
  const [companyName,setCompanyName] = useState("");
  const [jobTitle,setJobTitle] = useState("");
  const [jobDesc,setJobDesc] = useState("");
  const [startDate,setStartDate] = useState("");
  let validation = true;

  const singleTool = (experienceDescriptions, experienceFieldId) => {
    let placeholders = [
      "Increased revenue by 96% by applying personalized ads to the user",
      "Improved the search algorithm which increased user engagement with the search feature",
      "Implemented machine learning to easily target desired products per user",
      "Lead a group in a project to increase efficiency of the system by 50%",
      "Organized daily standups to ensure progress was consistent within the group"
    ];
    let result = [];
    let len = 0;
    experienceDescriptions &&
      experienceDescriptions
        .sort((x, y) => x.ordinal - y.ordinal)
        .forEach((desc) => {
          result.push(
            <Input
            key={`${experienceFieldId}-${len}`}
              type="text"
              placeholder={placeholders[len]}
              inputStyle="form"
              value={desc.description}
              parentIdName={experienceFieldId}
              idName={desc.experienceDescriptionId}
              toolCreated={desc.creationTimeStamp}
              toolActive={desc.active}
              toolOrdinal={desc.ordinal}
            />
          );
          len += 1;
        });
    for (; len < 5; len++) {
      result.push(
        <Input
        key={`${experienceFieldId}-${len}`}
          type="text"
          placeholder={placeholders[len]}
          inputStyle="form"
          value={""}
          parentIdName={experienceFieldId}
        />
      );
    }
    return result;
  };

  const singleForm = (
    ordinal = -1,
    id = null,
    uid = generateUID(),
    active = true,
    creationTimeStamp = getToday(),
    modifiedTimeStamp = getToday(),
    companyName = "",
    jobTitle = "",
    jobLocation = "",
    startDate = "",
    endDate = "",
    experienceDescriptions = null
  ) => {
    return (
      <>
        <form className="form form__mainWithTool" indx={indx} id={id}>
          <input
            className="form__noDisplay"
            name="ordinal"
            value={ordinal}
            tabIndex={-1}
            readOnly
          />
          <input
            className="form__noDisplay"
            name="uid"
            value={uid}
            tabIndex={-1}
            readOnly
          />
          <input
            className="form__noDisplay"
            name="creationTimeStamp"
            value={creationTimeStamp}
            tabIndex={-1}
            readOnly
          />
          <input
            className="form__noDisplay"
            name="modifiedTimeStamp"
            value={modifiedTimeStamp}
            tabIndex={-1}
            readOnly
          />
          <input
            className="form__noDisplay"
            name="active"
            value={active}
            tabIndex={-1}
            readOnly
          />
          <Input
            type="text"
            title="Company Name"
            placeholder="Netflix"
            inputStyle="form"
            name="companyName"
            value={companyName}
            headStyle
          />

          <Input
            type="text"
            title="Job Title"
            placeholder="Programmer"
            inputStyle="form"
            name="jobTitle"
            value={jobTitle}
          />
          <br></br>
          <Input
            type="text"
            title="Job Location"
            placeholder="Irvine"
            inputStyle="form"
            name="jobLocation"
            value={jobLocation}
          />
          <br></br>
          <div className="form__dates">
            <Input
              type="date"
              title="Start Date"
              inputStyle="dateForm"
              name="startDate"
              value={startDate}
            />
            <Input
              type="date"
              title="End Date"
              inputStyle="dateForm"
              name="endDate"
              value={endDate}
            />
          </div>
        </form>
        <form className="form__tool" name ="jobDesc">
          <h6>Job Description</h6>
          {singleTool(experienceDescriptions, id || uid)}
        </form>
      </>
    );
  };
  const addForm = (evt) => {
    setAdded(true);
    evt.preventDefault();
    setExperienceForms([...experienceForms, singleForm(indx)]);
    setIndx(indx + 1);
  };
  const removeForm = (evt) => {

    document.getElementById('failure').style.display = 'none';

    if(experienceForms.length === 1)
    {
    setAdded(false);
    }
    evt.preventDefault();
    let [formToPop, newFormList] = [experienceForms.pop(), experienceForms];
    setExperienceForms([...newFormList]);
    formToPop && setIndx(indx - 1);
    if (formToPop?.props?.children[0]?.props?.id) {
      const experienceFieldId = formToPop?.props?.children[0]?.props?.id;
      $.ajax(`${deleteExperienceEndpoint}?id=${experienceFieldId}`, {
        type: "DELETE",
      });
    }
  };

  const saveForms = (evt) => {
    validation = true;
    setJobTitle(""); 
    setJobDesc("");
    setCompanyName(""); 
    setStartDate("");

    evt.preventDefault();
    let result = document.querySelectorAll(".form");
    let failedSave = false;

    result.forEach((form) => {
      const userInputData = {
        resumeId,
        companyName: form["companyName"]?.value,
        ordinal: parseInt(form["ordinal"]?.value),
        jobTitle: form["jobTitle"]?.value,
        jobLocation: form["jobLocation"]?.value,
        startDate: form["startDate"]?.value,
        endDate: form["endDate"]?.value,
      };

      if (failedSave) {
        return
      }

      if (form.jobTitle.value === "" ) {
        setJobTitle("Please fill your Job Title");
        validation = false;
      }

      if (form.startDate.value === "") {
        setStartDate("Please fill in your startDate");
        validation = false;
      }

      if (form.companyName.value === "") {
        setCompanyName("Please fill in your company name");
        validation = false;
      }

      if(validation)
      {

      if (form.id) {
        if (failedSave) {
          return
        }

        // edit existing experience
        userInputData.creationTimeStamp = form["creationTimeStamp"]?.value;
        userInputData.modifiedTimeStamp = getToday();
        userInputData.active = form["active"]?.value;
        userInputData.experienceFieldId = form.id;

        let toolList = Array.from(
          document.getElementsByClassName(`${form.id}`)
        );
        toolList.forEach((tool, index) => {
          if (failedSave) {
            return
          }

          if (!tool.value && index == 0){
            setJobDesc("Please fill in your job description");
            validation = false;
            failedSave = true;
            console.log("test")
            return
          }
        })

        $.post(
          editExperienceEndpoint,
          JSON.stringify(userInputData),
          (data) => {}
        );

        let order = 1;

        toolList.forEach((tool) => {
          if (failedSave){
            return
          }

          const toolData = {
            experienceFieldId: form.id,
            description: tool.value,
            ordinal: order,
          };

          order += 1;
          if (tool.id) {
            // delete tool if value is empty
            if (tool.value) {
              // edit description
              toolData.experienceDescriptionId = tool.id;
              toolData.creationTimeStamp = tool.getAttribute("toolcreated");
              toolData.modifiedTimeStamp = getToday();
              toolData.active = true;
              $.post(
                editExperienceToolEndpoint,
                JSON.stringify(toolData),
                (data) => {}
              );
            } else {
              // delete tool
              $.ajax(`${deleteExperienceToolEndpoint}?id=${tool.id}`, {
                type: "DELETE",
              });
            }
          } else {
            // add new tool
            if (tool.value) {
              $.post(
                addExperienceToolEndpoint,
                JSON.stringify(toolData),
                (data) => {}
              );
            }
          }
        });

      } else {
        // add new experience
        let id;
        const formUID = form["uid"]?.value;
        let toolList = Array.from(document.getElementsByClassName(formUID));

        toolList.forEach((tool, index) => {
          if (failedSave){
            return
          }

          if (!tool.value && index == 0) {
            setJobDesc("Please Enter a Job Description")
            validation = false;
            failedSave = true;
            console.log("test")
            return
          }
        })

        if (failedSave) {
          return
        }

        $.post(addExperienceEndpoint, JSON.stringify(userInputData), (data) => {
          id = data.experienceFieldIdReturn;
          
          let order = 1;
          toolList.forEach((tool) => {

            if (tool?.value) {
              const localTool = {
                experienceFieldId: id,
                description: tool?.value,
                ordinal: order,
              };

              order += 1;

              $.post(
                addExperienceToolEndpoint,
                JSON.stringify(localTool),
                (innerData) => {}
              );
            }
          });
        });
      }

      document.getElementById("failure").style.display = 'none';
    }

    else {
      document.getElementById("failure").style.display = 'block';
    } 
    });

    if(validation) {

    if (failedSave){
      return
    }
    setNextLoad(true);
    setTimeout(() => {
      handlePagination(1,"work");
    }, 1000);
  }
  else{
      setNextLoad(false);
  }
  };
  const getExperiences = () => {
    $.get(getExperienceEndpoint, { resumeId }).then((data) => {
      setIndx(data.length + 1);
      setExperienceForms(
        data
          .sort((x, y) => x.ordinal - y.ordinal)
          .map((dataset) => {
            const {
              active,
              companyName,
              creationTimeStamp,
              endDate,
              experienceDescriptions,
              experienceFieldId,
              jobLocation,
              jobTitle,
              modifiedTimeStamp,
              ordinal,
              startDate,
            } = dataset;

            setAdded(true);
            
            return singleForm(
              ordinal,
              experienceFieldId,
              null,
              active,
              creationTimeStamp,
              modifiedTimeStamp,
              companyName,
              jobTitle,
              jobLocation,
              getFormDate(startDate),
              getFormDate(endDate),
              experienceDescriptions
            );
          })
      );
      setLoaded(true);
    });
  };
  useEffect(() => {
    ajaxSetup();
    getExperiences();

  }, []);
  return loaded ? (
    <>
      {nextLoad && (
        <div className="form__loader">
          <div className="form__spinner"></div>
        </div>
      )}
      <div className="projectForm">
        <h3>Your Experiences</h3>

        <div id = "failure">
            <h3> {companyName}</h3>
            <h3> {jobTitle}</h3> 
            <h3> {jobDesc}</h3> 
            <h3>{startDate}</h3>
        </div>

        {experienceForms.map((component, indx) => (
          <React.Fragment key={indx}>{component}</React.Fragment>
        ))}
        <div className="form__buttons">
          <button className="form__button" onClick={(evt) => saveForms(evt)}>
            Save & Next
          </button>
          <button className="form__button" onClick={(evt) => addForm(evt)}>
            Add Experience
          </button>
          <button 
          style = {added ? {display : "block"} : {display : "none"}}
          className="form__button" 
          onClick={(evt) => removeForm(evt)}>
            Remove Experience
          </button>
        </div>
      </div>
    </>
  ) : (
    <div className="form__loader">
      <div className="form__spinner"></div>
    </div>
  );
}

export default ExperienceForm;
