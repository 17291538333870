import React, { useState, useEffect } from "react";
import "./style.css";
import Images from "../../Images";
import ReactModal from "react-modal";
import { getToken, logout } from "../../authetication/loginState";
import Select from "react-select";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import useAnalyticsEventTracker from "../../helper/reactTracker";

const IndustryType = [
  { value: 1, label: "Engineering" },
  { value: 2, label: "Computer Science/IT" },
  { value: 3, label: "Health-Care" },
  { value: 4, label: "Business" },
  { value: 5, label: "Marketing" },
  { value: 6, label: "Accounting" },
  { value: 7, label: "Financial" },
  { value: 8, label: "Retail" },
  { value: 9, label: "Legal" },
  { value: 10, label: "Human Resource" },
  { value: 11, label: "Public Relation" },
  { value: 12, label: "Fashion" },
  { value: 13, label: "Non Profit" },
  { value: 14, label: "STEM" },
  { value: 15, label: "Arts/Humanities" },
  { value: 16, label: "Business" },
  { value: 17, label: "Social Ecology" },
  { value: 18, label: "Undecided" },
];

const CompanyType = [
  { value: 1, label: "Fortune 500" },
  { value: 2, label: "Midtier" },
  { value: 3, label: "Start ups" },
];

function Search({ loginState }) {
  const [showModal, setShowModal] = useState(false);
  const [hideTopMatchesText, setHideTopMatchesText] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showApply, setShowApply] = useState(false);
  const [country, setCountry] = useState("");
  const [topHovered, setTopHovered] = useState(-1);
  const [similarHovered, setSimilarHovered] = useState(-1);
  const [topSeeMore, setTopSeeMore] = useState(false);
  const [similarSeeMore, setSimilarSeeMore] = useState(false);
  const [sorted, setSorted] = useState([]);
  const [defaultSorted, setDefaultSorted] = useState([]);
  const [topModal, setTopModal] = useState({});
  const [titleFieldId, setTitleFieldId] = useState(
    "BC290F9D-E490-44F6-B1CA-D873E27E9C1A"
  );

  const [paid, setPaid] = useState(false);
  const [fullttime, setFulltime] = useState(false);
  const [parttime, setParttime] = useState(false);
  const [contract, setContract] = useState(false);
  const [remote, setRemote] = useState(false);
  const [volunteer, setVolunteer] = useState(false);

  const [associate, setAssociate] = useState(false);
  const [bachelors, setBachelors] = useState(false);
  const [masters, setMasters] = useState(false);
  const [doctorate, setDoctorate] = useState(false);

  const [mostRecent, setMostRecent] = useState(false);

  const [anytime, setAnytime] = useState(false);
  const [dayBefore, setDayBefore] = useState(false);
  const [pastWeek, setPastWeek] = useState(false);
  const [pastMonth, setPastMonth] = useState(false);

  const [industryType, setIndustryType] = useState([]);
  const [companySize, setCompanySize] = useState([]);
  const [internshipTitle, setInternshipTitle] = useState([]);
  const [internshipOptions, setInternshipOptions] = useState(0);

  const [checkWork, setCheckWork] = useState({});

  const [similarMatches, setSimilarMatches] = useState([]);
  const [defaultSimilarMatches, setDefaultSimilarMatches] = useState([]);

  const [experiences, setExperiences] = useState([]);
  const [education, setEducations] = useState([]);
  const [awards, setAwards] = useState([]);
  const [skills, setSkills] = useState([]);
  const [projects, setProjects] = useState([]);
  const [user, setUser] = useState(0);
  let navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(getToken() ? true : false);
  const [saveInternshipFailed, setSaveInternshipFailed] = useState([false]);
  const [sortedSavedChecker, setSortedSaveChecker] = useState([]);
  const [similarMatchesSavedChecker, setSimilarMatchesSavedChecker] = useState(
    []
  );
  const gaEventTracker = useAnalyticsEventTracker("Application");

  const logoutUser = () => {
    logout();
    setLoggedIn(false);
    if (localStorage.getItem("userEmail") !== null) {
      navigate({
        pathname: "/login",
        state: "userSessionExpired",
      });
    } else {
      navigate("/login");
    }
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [secondIsLoading, secondSetIsLoading] = useState(false);

  //toggle application modal
  const toggleModal = () => {
    const body = document.querySelector("body");
    body.style.overflow = showModal ? "auto" : "hidden";
    setShowModal(showModal ? false : true);
  };

  const array = () => {
    var dict = checkWork;
    if (doctorate === true) dict["MinimumEducation"] = 4;
    else if (masters === true) dict["MinimumEducation"] = 3;
    else if (bachelors === true) dict["MinimumEducation"] = 2;
    else if (associate === true) dict["MinimumEducation"] = 1;
    else dict["MinimumEducation"] = 5;

    if (pastMonth === true) dict["time"] = "pastMonth";
    else if (pastWeek === true) dict["time"] = "pastWeek";
    else if (dayBefore === true) dict["time"] = "dayBefore";
    else if (anytime === true) dict["time"] = "anytime";
    else dict["time"] = "none";

    dict["currTime"] = Date().toString();

    if (fullttime === true) dict["workTime"] = "fulltime";
    else if (parttime === true) dict["workTime"] = "parttime";
    else dict["workTime"] = "none";

    dict["remote"] = remote ? "True" : "False";
    dict["paid"] = paid ? "True" : "False";
    dict["volunteer"] = volunteer ? "True" : "False";
    dict["contract"] = contract ? "True" : "False";

    dict["internshipTitle"] = internshipTitle;
    dict["industryType"] = industryType;
    dict["companysize"] = companySize;
    setCheckWork(dict);
  };

  const filterWork = () => {
    var checkedArray = checkWork;
    var arraytemp = [];

    var earliestDate;
    if (checkedArray["time"] === "dayBefore")
      earliestDate = new Date(new Date() - 1000 * 60 * 60 * 24);
    if (checkedArray["time"] === "pastWeek")
      earliestDate = new Date(new Date() - 1000 * 60 * 60 * 24 * 7);
    if (checkedArray["time"] === "pastMonth")
      earliestDate = new Date(new Date() - 1000 * 60 * 60 * 24 * 30);
    for (var i = 0; i < defaultSorted.length; i++) {
      var isIn = true;
      if (
        checkedArray["time"] !== "anytime" ||
        checkedArray["time"] !== "none"
      ) {
        if (new Date(defaultSorted[i]["CreationDate"]) < earliestDate)
          isIn = false;
      }
      if (checkedArray["MinimumEducation"] !== 5) {
        if (
          checkedArray["MinimumEducation"] <
          parseInt(defaultSorted[i]["MinimumEducation"])
        ) {
          isIn = false;
        }
      }
      if (checkedArray["paid"] === "True") {
        if (checkedArray["paid"] !== defaultSorted[i]["Paid"]) {
          isIn = false;
        }
      }

      if (checkedArray["contract"] === "True") {
        if (checkedArray["contract"] !== defaultSorted[i]["Contract"]) {
          isIn = false;
        }
      }

      if (checkedArray["remote"] === "True") {
        if (checkedArray["remote"] !== defaultSorted[i]["Remote"]) {
          isIn = false;
        }
      }

      if (checkedArray["volunteer"] === "True") {
        if (checkedArray["volunteer"] !== defaultSorted[i]["Volunteer"]) {
          isIn = false;
        }
      }

      if (checkedArray["workTime"] !== "none") {
        if (checkedArray["workTime"] === "fulltime") {
          if (defaultSorted[i]["Fulltime"] === "False") {
            isIn = false;
          }
        } else if (checkedArray["workTime"] === "parttime") {
          if (defaultSorted[i]["Parttime"] === "False") {
            isIn = false;
          }
        }
      }

      if (companySize.length > 0) {
        var companySizeMatch = false;
        for (var a = 0; a < companySize.length; a++) {
          if (
            companySize[a].value === parseInt(defaultSorted[i]["CompanySize"])
          ) {
            companySizeMatch = true;
          }
        }
        if (companySizeMatch === false) {
          isIn = false;
        }
      }

      if (industryType.length > 0) {
        var industryTypeMatch = false;
        for (var a = 0; a < industryType.length; a++) {
          if (
            industryType[a].value ===
            parseInt(defaultSorted[i]["InternshipType"])
          ) {
            industryTypeMatch = true;
          }
        }

        if (industryTypeMatch === false) {
          isIn = false;
        }
      }

      if (internshipTitle.length !== 0) {
        var internshipTitleMatch = false;
        for (var a = 0; a < internshipTitle.length; a++) {
          if (
            internshipTitle[a].value === defaultSorted[i]["InternshipTitle"]
          ) {
            internshipTitleMatch = true;
          }
        }
        if (internshipTitleMatch === false) {
          isIn = false;
        }
      }

      if (isIn === true) {
        arraytemp.push(defaultSorted[i]);
      }
    }

    if (mostRecent) {
      arraytemp = arraytemp.sort((a, b) => {
        return new Date(b.CreationDate) - new Date(a.CreationDate);
      });
    }
    setSorted(arraytemp);
  };

  //toggle filter modal
  const toggleFilter = () => {
    if (showFilter === true) {
      setSorted(defaultSorted);
      array();
      filterWork();
    }
    const body = document.querySelector("body");
    body.style.overflow = showFilter ? "auto" : "hidden";
    setShowFilter(showFilter ? false : true);
  };

  function handleSearch(str) {
    var match = [];
    for (var i = 0; i < defaultSorted.length; i++) {
      if (
        defaultSorted[i].InternshipTitle.toLowerCase().indexOf(
          str.toLowerCase()
        ) > -1
      ) {
        match.push(defaultSorted[i]);
        continue;
      }

      if (
        defaultSorted[i].CompanyName.toLowerCase().indexOf(str.toLowerCase()) >
        -1
      ) {
        match.push(defaultSorted[i]);
        continue;
      }

      if (
        defaultSorted[i].InternshipCity.toLowerCase().indexOf(
          str.toLowerCase()
        ) > -1
      ) {
        match.push(defaultSorted[i]);
        continue;
      }

      if (
        defaultSorted[i].InternshipState.toLowerCase().indexOf(
          str.toLowerCase()
        ) > -1
      ) {
        match.push(defaultSorted[i]);
        continue;
      } else {
        setHideTopMatchesText(true);
      }
    }
    setSorted(match);
    return;
  }

  //hover for similar matches
  function similarMatchesEnter(idx) {
    setSimilarHovered(idx);
  }
  function similarMatchesLeave() {
    setSimilarHovered(-1);
  }

  //hover for top matches
  function topMatchesEnter(idx) {
    setTopHovered(idx);
  }

  function topMatchesLeave() {
    setTopHovered(-1);
  }

  function handleIndustryType(val) {
    setIndustryType(val);
  }

  function handleCompany(val) {
    setCompanySize(val);
  }

  function handleInternshipTitle(val) {
    setInternshipTitle(val);
  }

  const applyInternship = (e) => {
    e.preventDefault();

    document.getElementById("errorBanner").style.display = "none";

    const applyInternshipData = {
      internshipId: topModal.InternshipId,
      userId: user.userId,
      firstName: $("#fname").val(),
      lastName: $("#lname").val(),
      city: $("#city").val(),
      country: $("#country").val(),
      phoneNumber: $("#phone").val(),
      email: $("#email").val(),
      resumeFileName: "",
    };
    secondSetIsLoading(true);

    $.post(
      `${process.env.REACT_APP_APPLY_INTERNSHIP}`,
      JSON.stringify(applyInternshipData),
      (data) => {
        const applicationId = data.studentAppliedIdReturn;

        var fd = new FormData();
        var files = $("#resume")[0].files;
        if (files.length > 0) {
          fd.append("file", files[0]);
          fetch(
            `${process.env.REACT_APP_ATTACH_RESUME}?student_applied_id=${applicationId}`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${getToken()}`,
              },
              body: fd,
            }
          ).then((res) => {
            if (res.status === 200) {
              gaEventTracker("application");
              secondSetIsLoading(false);
              alert("Application Successful");
              // Reloads for now have to change this later
              window.location.reload();
            } else {
              secondSetIsLoading(false);
              alert("Application Unsuccessfuly");
              window.location.reload();
            }
          });
        }
      }
    ).fail((data2) => {
      $(document).scrollTop(0);
      document.getElementById("errorBanner").style.display = "block";
    });
  };

  const saveInternship = (index) => {
    const internship = index;

    document.getElementById("errorBannerSave").style.display = "none";

    $.post(
      process.env.REACT_APP_SAVE_INTERNSHIP + `?id=${internship.InternshipId}`,
      (data) => {
        setSaveInternshipFailed(false);
      }
    ).fail((data2) => {
    });
  };

  const getData = () => {
    $.get(`${process.env.REACT_APP_TITLE_GET}`, { titleFieldId }, (data) => {
      var i;
      var options = [];
      var temp;
      for (i = 0; i < data.length; i++) {
        temp = {};
        temp["value"] = data[i].title1;
        temp["label"] = data[i].title1;
        options.push(temp);
      }
      setInternshipOptions(options);
    }).catch((error) => {
      console.log("user Session Expired");
      logoutUser();
    });
  };

  //for resizing
  const [size, setSize] = useState(window.innerWidth);
  const MAX_ITEMS = size < 1200 ? (size < 770 ? (size < 500 ? 1 : 2) : 3) : 4;

  useEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  });

  useEffect(() => {
    ajaxSetup();
    getData();
    userInfo();

    $.get(process.env.REACT_APP_MATCHING_GET, (data) => {
      var sortedPrefMatches = [];

      //sorting based on percentage
      sortedPrefMatches = data["finalList"].sort(
        (x, y) => y["Marks"] - x["Marks"]
      );

      setSorted(sortedPrefMatches);
      setDefaultSorted(sortedPrefMatches);

      const existingIds = sortedPrefMatches
        .map((x) => x.InternshipId)
        .join(",");
      $.get(
        process.env.REACT_APP_SIMILAR_GET +
          "?existingInternships=" +
          existingIds,
        (data) => {
          var sortedSimilarMatches = [];

          //sorting based on percentage
          sortedSimilarMatches = data["finalList"].sort(
            (x, y) => y["Marks"] - x["Marks"]
          );

          setSimilarMatches(sortedSimilarMatches);
          setDefaultSimilarMatches(sortedSimilarMatches);
          setIsLoading(false);
        }
      );
    }).catch((error) => {
      setIsLoading(false);
    });
  }, []);

  const userInfo = () => {
    $.get(process.env.REACT_APP_GET_USER, (data) => {
      setUser(data);
    }).catch((error) => {
      console.log("user Session Expired");
      logoutUser();
    });

    $.get(process.env.REACT_APP_GET_RESUMES, (data) => {
      let i = 0;
      let ResumeID;
      for (i = 0; i < data.length; i++) {
        if (data[i].pimaryResume === true) {
          ResumeID = data[i].resumeId;
        }
      }

      $.get(process.env.REACT_APP_GET_EXPERIENCE, { ResumeID }, (data) => {
        setExperiences(data);
      });

      $.get(process.env.REACT_APP_GET_EDUCATION, { ResumeID }, (data) => {
        setEducations(data);
      });

      $.get(process.env.REACT_APP_GET_SKILL, { ResumeID }, (data) => {
        setSkills(data);
      });

      $.get(process.env.REACT_APP_GET_PROJECT, { ResumeID }, (data) => {
        setProjects(data);
      });

      $.get(process.env.REACT_APP_GET_AWARD, { ResumeID }, (data) => {
        setAwards(data);
      });
    });
  };

  const ajaxSetup = () => {
    $.ajaxSetup({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });
  };

  //style for application modal
  const modalStyles =
    size > 720
      ? {
          overlay: { zIndex: 2 },
          content: {
            inset: "40px",
          },
        }
      : {
          overlay: { zIndex: 2 },
          content: {
            // width: "100%",
            // height: "100%",
            inset: "0",
          },
        };
  const applyStyles =
    size > 720
      ? {
          overlay: { zIndex: 5 },
          content: {
            inset: "40px",
            padding: "40px 70px",
          },
        }
      : {
          overlay: { zIndex: 5 },
          content: {
            // width: "100%",
            // height: "100%",
            inset: "0",
            // padding: "40px 70px"
          },
        };

  //style for filter modal
  const filterStyles = {
    content: {
      margin: "auto",
      maxWidth: "1200px",
      height: "60%",
      color: "black",
    },
    overlay: { zIndex: 2 },
  };

  return (
    <>
      {/* filter modal */}
      <ReactModal
        isOpen={showFilter}
        contentLabel="Filter Modal"
        style={filterStyles}
      >
        <button
          className="exit"
          onClick={() => {
            toggleFilter();
          }}
        >
          Done
        </button>
        <div className="filter-modal">
          <h2>Filter</h2>
          <div className="filter-grid">
            <div className="grid1">
              <section className="manual">
                <h4>Internship Title</h4>
                <Select
                  className="page1"
                  isMulti={true}
                  onChange={handleInternshipTitle}
                  value={internshipTitle}
                  options={internshipOptions}
                />
              </section>
              <section className="multiple-check">
                <h4>Internship Type</h4>
                <div>
                  <input
                    checked={paid}
                    type="checkbox"
                    id="paid"
                    name="paid"
                    onChange={() => setPaid(paid === true ? false : true)}
                  />
                  <label for="paid">Paid</label>
                </div>
                <div>
                  <input
                    checked={fullttime}
                    type="checkbox"
                    id="fulltime"
                    name="fulltime"
                    onChange={() =>
                      setFulltime(fullttime === true ? false : true)
                    }
                  />
                  <label for="fulltime">Full-Time</label>
                </div>
                <div>
                  <input
                    checked={parttime}
                    type="checkbox"
                    id="parttime"
                    name="parttime"
                    onChange={() =>
                      setParttime(parttime === true ? false : true)
                    }
                  />
                  <label for="parttime">Part-time</label>
                </div>
                <div>
                  <input
                    checked={remote}
                    type="checkbox"
                    id="remote"
                    name="remote"
                    onChange={() => setRemote(remote === true ? false : true)}
                  />
                  <label for="remote">Remote</label>
                </div>
                <div>
                  <input
                    checked={contract}
                    type="checkbox"
                    id="contract"
                    name="contract"
                    onChange={() =>
                      setContract(contract === true ? false : true)
                    }
                  />
                  <label for="contract">Contract</label>
                </div>
                <div>
                  <input
                    checked={volunteer}
                    type="checkbox"
                    id="volunteer"
                    name="volunteer"
                    onChange={() =>
                      setVolunteer(volunteer === true ? false : true)
                    }
                  />
                  <label for="volunteer">Volunteer</label>
                </div>
              </section>
              <section className="multiple-check">
                <h4>Education Level</h4>
                <div>
                  <input
                    checked={associate}
                    type="checkbox"
                    id="associate"
                    name="associate"
                    onChange={() =>
                      setAssociate(associate === true ? false : true)
                    }
                  />
                  <label for="associate">Associate</label>
                </div>
                <div>
                  <input
                    checked={bachelors}
                    type="checkbox"
                    id="bachelors"
                    name="bachelors"
                    onChange={() =>
                      setBachelors(bachelors === true ? false : true)
                    }
                  />
                  <label for="bachelors">Bachelor's</label>
                </div>
                <div>
                  <input
                    checked={masters}
                    type="checkbox"
                    id="masters"
                    name="masters"
                    onChange={() => setMasters(masters === true ? false : true)}
                  />
                  <label for="masters">Master's</label>
                </div>
                <div>
                  <input
                    checked={doctorate}
                    type="checkbox"
                    id="doctorate"
                    name="doctorate"
                    onChange={() =>
                      setDoctorate(doctorate === true ? false : true)
                    }
                  />
                  <label for="doctorate">Doctorate</label>
                </div>
              </section>
            </div>
            <div className="grid2">
              <section className="manual">
                <h4>Industry</h4>
                <Select
                  className="page1"
                  isMulti={true}
                  onChange={handleIndustryType}
                  value={industryType}
                  options={IndustryType}
                />
              </section>
              <section className="manual">
                <h4>Company Type</h4>
                <Select
                  className="page1"
                  isMulti={true}
                  onChange={handleCompany}
                  value={companySize}
                  options={CompanyType}
                />
                
              </section>
              <section className="multiple-check">
                <h4>Date Posted</h4>
                <div>
                  <input
                    checked={anytime}
                    type="checkbox"
                    id="anytime"
                    name="anytime"
                    onChange={() => setAnytime(anytime === true ? false : true)}
                  />
                  <label for="anytime">Anytime</label>
                </div>
                <div>
                  <input
                    checked={dayBefore}
                    type="checkbox"
                    id="past24"
                    name="past24"
                    onChange={() =>
                      setDayBefore(dayBefore === true ? false : true)
                    }
                  />
                  <label for="past24">Past 24 Hours</label>
                </div>
                <div>
                  <input
                    checked={pastWeek}
                    type="checkbox"
                    id="pastweek"
                    name="pastweek"
                    onChange={() =>
                      setPastWeek(pastWeek === true ? false : true)
                    }
                  />
                  <label for="pastweek">Past Week</label>
                </div>
                <div>
                  <input
                    checked={pastMonth}
                    type="checkbox"
                    id="pastmonth"
                    name="pastmonth"
                    onChange={() =>
                      setPastMonth(pastMonth === true ? false : true)
                    }
                  />
                  <label for="pastmonth">Past Month</label>
                </div>
              </section>
              <section className="multiple-check">
                <h4>Sort By</h4>
                <div>
                  <input
                    checked={mostRecent}
                    type="checkbox"
                    id="mostrecent"
                    name="mostrecent"
                    onChange={() =>
                      setMostRecent(mostRecent === true ? false : true)
                    }
                  />
                  <label for="mostrecent">Most Recent</label>
                </div>
              </section>
            </div>
          </div>
        </div>
      </ReactModal>

      {/* Application Modal */}
      <ReactModal
        isOpen={showModal}
        contentLabel="Application Modal"
        style={modalStyles}
      >
        <button
          className="exit"
          onClick={() => {
            setTopModal({});
            toggleModal();
          }}
        >
          X
        </button>

        <div className="modal-wrapper">
          <div className="modal-grid">
            <img
              className="modal-company-logo"
              width="110"
              height="110"
              src={Images.LINKEDIN_COLOR}
            />
            <section className="modal-section1">
              <h2>{topModal.InternshipTitle}</h2>
              <h4>{topModal.CompanyName}</h4>
              <h5>{`${topModal.CompanyHeadquarters}`}</h5>

              {/* unnecessary part */}
              {/* <p>{`You are a ${Math.round(topModal.Marks, 2)}% match`}</p> */}
            </section>

            <section className="modal-section2">
              <h5>Matched Qualifications:</h5>
              {topModal.MatchingQualifications &&
                topModal.MatchingQualifications.split(", ").map(
                  (qualification) =>
                    qualification !== "" && (
                      <div className="qualification-item">
                        <img width="15" height="15" src={Images.CHECK_BULLET} />
                        <p>{qualification}</p>
                      </div>
                    )
                )}
                {/* {topModal.Fulltime === true ? (
                  <div className="qualification-item">
                    <img width="15" height="15" src={Images.CHECK_BULLET} />
                    <p>Full time</p>
                  </div>
                ) : (
                  <div className="qualification-item">
                    <img width="15" height="15" src={Images.CHECK_BULLET} />
                    <p>Part time</p>
                  </div>
                )} */}
                {topModal.Paid === true ? (
                  <div className="qualification-item paid">
                    <img width="15" height="15" src={Images.CHECK_BULLET} />
                    <p>Paid</p>
                  </div>
                ) : (
                  <div className="qualification-item paid">
                    <img width="15" height="15" src={Images.CHECK_BULLET} />
                    <p>Unpaid</p>
                  </div>
                )}
            </section>
            
            <section className="Match">
              <p>{`You are a ${
                  Math.round(topModal.Marks, 2)
                }% match!`}</p>
            </section>
            
            {/* Repeated Code of section 2? */}
            {/* <section className="modal-section2">
              <h5>Matched Qualifications:</h5>
                {topModal.MatchingQualifications && 
                  topModal.MatchingQualifications.split(", ").map(
                    (qualification) =>
                      qualification !== "" && (
                        <div className="qualification-item">
                          <img width="15" height="15" src={Images.CHECK_BULLET} />
                          <p>{qualification}</p>
                        </div>
                      )
                  )}
            </section> */}

            <section className="modal-section3">

              <h5>Responsibilities:</h5>
              {topModal.InternshipDuties &&
              topModal.InternshipDuties.includes("|") ? (
                topModal.InternshipDuties.split("|").map(
                  (duty) => duty !== "" && <li>{duty}</li>
                )
              ) : (
                <p
                  dangerouslySetInnerHTML={{
                    __html: topModal.InternshipDuties,
                  }}
                />
              )}

            </section>

            <section className="modal-section4">
              <p className="modal-date">
                {topModal.CreationDate &&
                  `Posted: ${topModal.CreationDate.split(" ")[0]}`}
              </p>
            </section>
          </div>

          <section className="description">

            <h4>Description</h4>
             {topModal.InternshipDescription &&
              topModal.InternshipDescription.includes("|") ? (
                topModal.InternshipDescription.split("|").map(
                  (desc) => desc !== "" && <li>{desc}</li>
                )
              ) : (
                <p
                  dangerouslySetInnerHTML={{
                    __html: topModal.InternshipDescription,
                  }}
                />
              )}

          </section>

          <section className="about">
            <h4>About {topModal.CompanyName}:</h4>
          </section>  

          <section className="about">
            <img
              className="industry"
              width="30"
              height="30"
              src={Images.INDUSTRY_FOOTER}
            />
            <p>Industry</p>
            <img
              className="type"
              width="30"
              height="30"
              src={Images.TYPE_FOOTER}
            />
            <p>Startup</p>
            <img
              className="employee-number"
              width="30"
              height="30"
              src={Images.SIZE_FOOTER}
            />
             <p>{topModal.CompanySize} employees</p>
          </section>

          <section className="socials">
            <img
              className="website"
              width="30"
              height="30"
              src={Images.WEBSITE_FOOTER}
            />
            <img
              className="linkedin"
              width="30"
              height="30"
              src={Images.LINKEDIN_FOOTER}
            />
            <img
              className="instagram"
              width="30"
              height="30"
              src={Images.INSTAGRAM_FOOTER}
            />
            <img
              className="facebook"
              width="30"
              height="30"
              src={Images.FACEBOOK_FOOTER}
            />
            <img
              className="tiktok"
              width="30"
              height="30"
              src={Images.TIKTOK_FOOTER}
            />    
          </section>

          <section className="description">
            <h4>How To Increase Your Chances</h4>

            {topModal.ImproveChance &&
              topModal.ImproveChance.split(", ")
                .filter((x) => x !== "None")
                .map(
                  (improvement) =>
                    improvement !== "" && (
                      <div className="chance-item">
                        <img
                          width="15"
                          height="15"
                          src={Images.CHANCE_BULLET}
                        />
                        <p>{improvement}</p>
                      </div>
                    )
                )}
          </section>

          

          <div className="buttons">
            <button
              className="placard-savebtn"
              onClick={() => {
                saveInternship(topModal);
              }}
            >
              Save
            </button>
            <button
              className="placard-applybtn"
              onClick={() => setShowApply(true)}
            >
              Apply
            </button>
          </div>
        </div>
      </ReactModal>

      {/* flow 1  */}
      <ReactModal
        isOpen={showApply}
        contentLabel="Application Modal"
        style={applyStyles}
      >
        <button className="exit" onClick={() => setShowApply(false)}>
          X
        </button>

        {
          <div className="apply-wrapper">
            <div className="apply-header">
              <img
                className="modal-company-logo"
                width="50"
                height="50"
                src={Images.LINKEDIN_COLOR}
              />
              <span>
                <h2>{topModal.InternshipTitle}</h2>
                <h5>{`${topModal.CompanyName} - ${topModal.CompanyHeadquarters}`}</h5>
              </span>
            </div>

            <div className="apply-wrapper">
              <form onSubmit={(e) => applyInternship(e)}>
                <label for="fname">First name:</label>
                <br />
                <input type="text" id="fname" name="fname" required />
                <br />
                <label for="lname">Last name:</label>
                <br />
                <input type="text" id="lname" name="lname" required />
                <br />
                <label for="city">City:</label>
                <br />
                <input type="text" id="city" name="city" required />
                <br />
                <label for="country">Country:</label>
                <br />
                <input type="text" id="country" name="country" required onChange={handleCountryChange} />
                <br />
                <label for="phone">Phone Number:</label>
                <br />
                <input type="text" id="phone" name="phone" required />
                <br />
                <label for="email">Email:</label>
                <br />
                <input type="email" id="email" name="email" required />
                <br />
                <label for="resume">Resume</label>
                <br />
                <input type="file" id="resume" name="resume" required />
                <br /> <br />
                <button type="submit" className="placard-applybtn" disabled={country.toLowerCase() !== "united states" && country.toUpperCase() !== "US" && country.toUpperCase() !== "USA"}>
                  Apply
                </button>
              </form>
              {secondIsLoading && (
                <div className="loader3" style={{ width: "90%" }}>
                  <div className="loader2"></div>
                </div>
              )}
            </div>
          </div>
        }
      </ReactModal>

      {/* Background image */}
      <div
        className="bg-img"
        style={{
          backgroundImage: `url(${Images.BACKGROUND})`,
          backgroundRepeat: "repeat-y",
          backgroundSize: "cover",
          width: "100vw",
          minHeight: "100vh",
          height: "auto",
        }}
      >
        {/* Searchbar */}
        {
          <div className="searchbar-container">
            <input
              placeholder="Search"
              className="searchbar "
              onChange={(e) => handleSearch(e.target.value)}
              type="search"
            ></input>
            <img
              className="filter"
              onClick={toggleFilter}
              src={Images.FILTER}
            />
          </div>
        }

        {
          //This kind of assumes there will always be one internship to apply for
          isLoading && (
            <div className="loader3">
              <div className="loader2"></div>
            </div>
          )
        }

        <div id="errorBanner">
          <h3>Error Applying/Saving The Internship</h3>
          <h5>Please try again later</h5>
        </div>

        <div id="errorBannerSave">
          <h2>Internship is Already Saved</h2>
        </div>

        <div id="savedBanner">
          <h1>Internship Saved</h1>
        </div>

        {/* #1 Match */}
        <div className="highlight">
          <div
            className="grid-container"
            onClick={() => {
              setTopModal(sorted[0]);
              toggleModal();
            }}
          >
            <img
              className="company-logo"
              width={size > 770 ? 65 + (size - 770) / 25 : 50 + size / 15.4}
              height={size > 770 ? 65 + (size - 770) / 25 : 50 + size / 15.4}
              src={Images.LINKEDIN_COLOR}
            />
            <section className="section1">
              <h2>
                {sorted[0]
                  ? sorted[0].InternshipTitle
                  : "No internships found! Try a different search."}
              </h2>
              <h4>{sorted[0] && sorted[0].CompanyName}</h4>
              <h5>{sorted[0] && `${sorted[0].CompanyHeadquarters}`}</h5>

              <p>{sorted[0] && `You are a ${sorted[0].Marks}% match`}</p>
            </section>

            {size > 770 && (
              <section className="section2">
                <h5>Matched Qualifications:</h5>
                {sorted[0] &&
                  sorted[0].MatchingQualifications.split(", ").map(
                    (qualification) =>
                      qualification !== "" && (
                        <div className="qualification-item">
                          <img
                            width="12"
                            height="12"
                            src={Images.CHECK_BULLET}
                          />
                          <p>{qualification}</p>
                        </div>
                      )
                  )}
              </section>
            )}

            {size > 770 && (
              <section className="section3">
                <h5>Responsibilities:</h5>
                {sorted[0] && sorted[0].InternshipDuties.includes("|") ? (
                  sorted[0].InternshipDuties.split("|").map(
                    (duty) => duty !== "" && <li>{duty}</li>
                  )
                ) : (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: sorted[0] && sorted[0].InternshipDuties,
                    }}
                  />
                )}
              </section>
            )}
          </div>
          <div
            className="background"
            onClick={() => {
              setTopModal({});
              toggleModal();
            }}
          ></div>
        </div>

        {/* Top Matches  */}
        <div className="topmatches">
          <div className="grid">
            {(hideTopMatchesText === false || sorted.slice(1).length >= 1) && (
              <h4 className="header">Top Matches Based on Preferences</h4>
            )}
            <p
              className="seemore"
              onClick={() => setTopSeeMore(topSeeMore ? false : true)}
            >
              {sorted.slice(1).length > MAX_ITEMS
                ? topSeeMore
                  ? "See less"
                  : "See more"
                : ""}
            </p>
            {}
            {topSeeMore
              ? //When there is less than or equal to MAX items (or) when there is more than MAX items AND see more is pressed
                sorted.slice(1).map((match, index) => (
                  <div
                    className="placard"
                    onMouseEnter={() => topMatchesEnter(index)}
                    onMouseLeave={topMatchesLeave}
                    onClick={() => {
                      setTopModal(sorted[index + 1]);
                      toggleModal();
                    }}
                  >
                    {topHovered === index ? (
                      <>
                        <h5 className="placard-alt-header">
                          Matched Qualifications
                        </h5>
                        <div className="placard-alt-tag-container">
                          {match.MatchingQualifications.split(", ").map(
                            (qualification) =>
                              qualification !== "" && (
                                <div className="placard-tag">
                                  {qualification}
                                </div>
                              )
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <img
                          className="placard-logo"
                          width={
                            size < 1200
                              ? size < 770
                                ? size < 500
                                  ? 20 + size / 17.5
                                  : 30 + (size - 500) / 25
                                : 30 + (size - 770) / 25
                              : 40 + (size - 1200) / 25
                          }
                          height={
                            size < 1200
                              ? size < 770
                                ? size < 500
                                  ? 20 + size / 17.5
                                  : 30 + (size - 500) / 25
                                : 30 + (size - 770) / 25
                              : 40 + (size - 1200) / 25
                          }
                          src={Images.LINKEDIN_COLOR}
                        />
                        <h5 className="placard-position">
                          {match && match.InternshipTitle}
                        </h5>
                        <h5 className="placard-percentage">
                          {match && `${Math.round(match.Marks)}%`}
                        </h5>
                        <h6 className="placard-company">
                          {match && match.CompanyName}
                        </h6>
                        <p className="placard-location">
                          {match && `${match.CompanyHeadquarters}`}
                        </p>
                        <p className="placard-applicants">
                          {match && match.Applicants}
                        </p>
                        <p className="placard-date">{`Posted: ${
                          match && match.CreationDate.split(" ")[0]
                        }`}</p>
                      </>
                    )}
                    <button
                      className="placard-savebtn"
                      style={
                        sorted[index + 1].Saved === "True" ||
                        sortedSavedChecker.includes(index)
                          ? { backgroundColor: "#025EA9", color: "white" }
                          : {}
                      }
                      onClick={(event) => {
                        event.stopPropagation();
                        if (sorted[index].Saved !== "True") {
                          saveInternship(sorted[index + 1]);
                          sortedSavedChecker.push(index);
                          sorted[index].Saved = "True";
                        }
                      }}
                    >
                      {sorted[index + 1].Saved === "True" ||
                      sortedSavedChecker.includes(index)
                        ? "Saved"
                        : "Save"}
                    </button>

                    {/* Sets topModal state to index number, which is used by the application modal to get the correct corresponding data */}
                    <button
                      className="placard-applybtn"
                      onClick={() => {
                        if (sorted[index].Applied !== "True") {
                          setTopModal(sorted[index + 1]);
                          toggleModal();
                        }
                      }}
                    >
                      {sorted[index].Applied === "True" ? "Applied" : "Apply"}
                    </button>
                  </div>
                ))
              : // When there is more than MAX (default 4) items and see more isn't pressed
                sorted.slice(1, MAX_ITEMS + 1).map((match, index) => (
                  <div
                    className="placard"
                    onMouseEnter={() => topMatchesEnter(index)}
                    onMouseLeave={topMatchesLeave}
                    onClick={() => {
                      setTopModal(sorted[index + 1]);
                      toggleModal();
                    }}
                  >
                    {topHovered === index ? (
                      <>
                        <h5 className="placard-alt-header">
                          Matched Qualifications
                        </h5>
                        <div className="placard-alt-tag-container">
                          {match.MatchingQualifications.split(", ").map(
                            (qualification) =>
                              qualification !== "" && (
                                <div className="placard-tag">
                                  {qualification}
                                </div>
                              )
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <img
                          className="placard-logo"
                          width={
                            size < 1200
                              ? size < 770
                                ? size < 500
                                  ? 20 + size / 17.5
                                  : 30 + (size - 500) / 25
                                : 30 + (size - 770) / 25
                              : 40 + (size - 1200) / 25
                          }
                          height={
                            size < 1200
                              ? size < 770
                                ? size < 500
                                  ? 20 + size / 17.5
                                  : 30 + (size - 500) / 25
                                : 30 + (size - 770) / 25
                              : 40 + (size - 1200) / 25
                          }
                          src={Images.LINKEDIN_COLOR}
                        />
                        <h5 className="placard-position">
                          {match && match.InternshipTitle}
                        </h5>
                        <h5 className="placard-percentage">
                          {match && `${Math.round(match.Marks)}%`}
                        </h5>
                        <h6 className="placard-company">
                          {match && match.CompanyName}
                        </h6>
                        <p className="placard-location">
                          {match && `${match.CompanyHeadquarters}`}
                        </p>
                        <p className="placard-applicants">
                          {match && match.Applicants}
                        </p>
                        <p className="placard-date">
                          {match &&
                            `Posted: ${match.CreationDate.split(" ")[0]}`}
                        </p>
                      </>
                    )}
                    <button
                      className="placard-savebtn"
                      style={
                        sorted[index + 1].Saved === "True" ||
                        sortedSavedChecker.includes(index)
                          ? { backgroundColor: "#025EA9", color: "white" }
                          : {}
                      }
                      onClick={(event) => {
                        event.stopPropagation();
                        if (sorted[index].Saved !== "True") {
                          saveInternship(sorted[index + 1]);
                          sortedSavedChecker.push(index);
                          sorted[index].Saved = "True";
                        }
                      }}
                    >
                      {sorted[index + 1].Saved === "True" ||
                      sortedSavedChecker.includes(index)
                        ? "Saved"
                        : "Save"}
                    </button>

                    <button
                      className="placard-applybtn"
                      onClick={() => {
                        if (sorted[index].Applied !== "True") {
                          setTopModal(sorted[index + 1]);
                          toggleModal();
                        }
                      }}
                    >
                      {sorted[index].Applied === "True" ? "Applied" : "Apply"}
                    </button>
                  </div>
                ))}
          </div>
        </div>

        {/* Similar Matches */}
        <div className="similarmatches">
          <div className="grid">
            <h4 className="header">Similar Matches</h4>
            <p
              className="seemore"
              onClick={() => setSimilarSeeMore(similarSeeMore ? false : true)}
            >
              {similarMatches.length > 4
                ? similarSeeMore
                  ? "See less"
                  : "See more"
                : ""}
            </p>
            {similarSeeMore
              ? similarMatches.map((match, index) => (
                  <div
                    className="placard"
                    onMouseEnter={() => similarMatchesEnter(index)}
                    onMouseLeave={similarMatchesLeave}
                    onClick={() => {
                      setTopModal(similarMatches[index]);
                      toggleModal();
                    }}
                  >
                    {similarHovered === index ? (
                      <>
                        <h5 className="placard-alt-header">
                          Matched Qualifications
                        </h5>
                        <div className="placard-alt-tag-container">
                          {match.MatchingQualifications.split(", ").map(
                            (qualification) =>
                              qualification !== "" && (
                                <div className="placard-tag">
                                  {qualification}
                                </div>
                              )
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <img
                          className="placard-logo"
                          width={
                            size < 1200
                              ? size < 770
                                ? size < 500
                                  ? 20 + size / 17.5
                                  : 30 + (size - 500) / 25
                                : 30 + (size - 770) / 25
                              : 40 + (size - 1200) / 25
                          }
                          height={
                            size < 1200
                              ? size < 770
                                ? size < 500
                                  ? 20 + size / 17.5
                                  : 30 + (size - 500) / 25
                                : 30 + (size - 770) / 25
                              : 40 + (size - 1200) / 25
                          }
                          src={Images.LINKEDIN_COLOR}
                        />
                        <h5 className="placard-position">
                          {match && match.InternshipTitle}
                        </h5>
                        <h5 className="placard-percentage">
                          {match && `${Math.round(match.Marks)}%`}
                        </h5>
                        <h6 className="placard-company">
                          {match && match.CompanyName}
                        </h6>
                        <p className="placard-location">
                          {match && `${match.CompanyHeadquarters}`}
                        </p>
                        <p className="placard-applicants">
                          {match && match.Applicants}
                        </p>
                        <p className="placard-date">
                          {match &&
                            `Posted: ${match.CreationDate.split(" ")[0]}`}
                        </p>
                      </>
                    )}
                    <button
                      className="placard-savebtn"
                      style={
                        similarMatches[index].Saved === "True" ||
                        similarMatchesSavedChecker.includes(index)
                          ? { backgroundColor: "#025EA9", color: "white" }
                          : {}
                      }
                      onClick={(event) => {
                        event.stopPropagation();
                        if (similarMatches[index].Saved !== "True") {
                          saveInternship(similarMatches[index]);
                          similarMatchesSavedChecker.push(index);
                          similarMatches[index].Saved = "True";
                        }
                      }}
                    >
                      {similarMatches[index].Saved === "True" ||
                      similarMatchesSavedChecker.includes(index)
                        ? "Saved"
                        : "Save"}{" "}
                    </button>
                    <button
                      className="placard-applybtn"
                      onClick={() => {
                        setTopModal(similarMatches[index]);
                        toggleModal();
                      }}
                    >
                      {similarMatches[index].Applied === "True"
                        ? "Applied"
                        : "Apply"}
                    </button>
                  </div>
                ))
              : similarMatches.slice(0, MAX_ITEMS).map((match, index) => (
                  <div
                    className="placard"
                    onMouseEnter={() => similarMatchesEnter(index)}
                    onMouseLeave={similarMatchesLeave}
                    onClick={(event) => {
                      setTopModal(similarMatches[index]);
                      toggleModal();
                    }}
                  >
                    {similarHovered === index ? (
                      <>
                        <h5 className="placard-alt-header">
                          Matched Qualifications
                        </h5>
                        <div className="placard-alt-tag-container">
                          {match.MatchingQualifications.split(", ").map(
                            (qualification) =>
                              qualification !== "" && (
                                <div className="placard-tag">
                                  {qualification}
                                </div>
                              )
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <img
                          className="placard-logo"
                          width={
                            size < 1200
                              ? size < 770
                                ? size < 500
                                  ? 20 + size / 17.5
                                  : 30 + (size - 500) / 25
                                : 30 + (size - 770) / 25
                              : 40 + (size - 1200) / 25
                          }
                          height={
                            size < 1200
                              ? size < 770
                                ? size < 500
                                  ? 20 + size / 17.5
                                  : 30 + (size - 500) / 25
                                : 30 + (size - 770) / 25
                              : 40 + (size - 1200) / 25
                          }
                          src={Images.LINKEDIN_COLOR}
                        />
                        <h5 className="placard-position">
                          {match && match.InternshipTitle}
                        </h5>
                        <h5 className="placard-percentage">
                          {match && `${Math.round(match.Marks)}%`}
                        </h5>
                        <h6 className="placard-company">
                          {match && match.CompanyName}
                        </h6>
                        <p className="placard-location">
                          {match && `${match.CompanyHeadquarters}`}
                        </p>
                        <p className="placard-applicants">
                          {match && match.Applicants}
                        </p>
                        <p className="placard-date">
                          {match &&
                            `Posted: ${match.CreationDate.split(" ")[0]}`}
                        </p>
                      </>
                    )}
                    <button
                      className="placard-savebtn"
                      style={
                        similarMatches[index].Saved === "True" ||
                        similarMatchesSavedChecker.includes(index)
                          ? { backgroundColor: "#025EA9", color: "white" }
                          : {}
                      }
                      onClick={(event) => {
                        event.stopPropagation();
                        if (similarMatches[index].Saved !== "True") {
                          similarMatchesSavedChecker.push(index);
                          saveInternship(similarMatches[index]);
                          similarMatches[index].Saved = "True";
                        }
                        // one way is to check the id in sortedarray to get rid of error
                      }}
                    >
                      {similarMatches[index].Saved === "True" ||
                      similarMatchesSavedChecker.includes(index)
                        ? "Saved"
                        : "Save"}{" "}
                    </button>
                    <button
                      className="placard-applybtn"
                      onClick={() => {
                        setTopModal(similarMatches[index]);
                        toggleModal();
                      }}
                    >
                      {similarMatches[index].Applied === "True"
                        ? "Applied"
                        : "Apply"}
                    </button>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </>

  );  
};

export default Search;
