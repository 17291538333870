import React, { useState, useEffect } from "react";
import TextArea from "../Input/TextArea";
import Input from "./../Input/Input";
import "./Forms.css";
import $ from "jquery";
import {
  getFormDate,
  generateUID,
  getToday,
  ajaxSetup,
} from "../../helper/helper";
function AwardForm({ resumeId, handlePagination }) {
  const addAwardEndpoint = process.env.REACT_APP_ADD_AWARD;
  const getAwardEndpoint = process.env.REACT_APP_GET_AWARD;
  const editAwardEndpoint = process.env.REACT_APP_EDIT_AWARD;
  const deleteAwardEndpoint = process.env.REACT_APP_DELETE_AWARD;
  const [awardForms, setAwardForms] = useState([]);
  const [indx, setIndx] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [nextLoad, setNextLoad] = useState(false);
  const [added , setAdded] = useState(false);

  const [awardNameError,setAwardNameError] = useState("");
  const [awardDescError,setAwardDescError] = useState("");
  const [dateError,setDateError] = useState("");
  let validation = true;

  
  const addAward = (awardData, form, uid) => {
    // $.post(`${addAwardEndpoint}`, JSON.stringify(awardData));
    $.post(`${addAwardEndpoint}`, JSON.stringify(awardData), (data) => {});
  };
  const editAward = (awardData, awardFieldId) => {
    $.post(
      `${editAwardEndpoint}`,
      JSON.stringify({ ...awardData, awardFieldId }),
      (data) => {}
    );
  };

  const getAward = () => {
    $.ajax(getAwardEndpoint, {
      type: "GET",
      data: { resumeId },
    }).then((data) => {
      setIndx(data.length + 1);
      setAwardForms(
        data
          .sort((x, y) => x.ordinal - y.ordinal)
          .map((dataset) => {
            const {
              awardFieldId,
              awardDate,
              awarder,
              name,
              description,
              ordinal,
              creationTimeStamp,
              modifiedTimeStamp,
              active,
            } = dataset;
            const date = getFormDate(awardDate);
            const uid = generateUID();
            setAdded(true);

            return singleForm(
              ordinal,
              awardFieldId,
              name,
              awarder,
              date,
              description,
              uid,
              creationTimeStamp,
              modifiedTimeStamp,
              active
            );
          })
      );
      setLoaded(true);
    });
  };

  useEffect(() => {
    getAward();
    ajaxSetup();
  

  }, []);

  const singleForm = (
    ordinal = -1,
    id = null,
    name = "",
    awarder = "",
    awardDate = "",
    description = "",
    uid = null,
    creationTimeStamp = getToday(),
    modifiedTimeStamp = getToday(),
    active = true
  ) => {
    return (
      <form className="form" indx={indx} id={id}>
        <input
          className="form__noDisplay"
          name="ordinal"
          value={ordinal}
          tabIndex={-1}
          readOnly
        />
        <input
          className="form__noDisplay"
          name="uid"
          value={uid || generateUID()}
          tabIndex={-1}
          readOnly
        />
        <input
          className="form__noDisplay"
          name="creationTimeStamp"
          value={creationTimeStamp}
          tabIndex={-1}
          readOnly
        />
        <input
          className="form__noDisplay"
          name="modifiedTimeStamp"
          value={modifiedTimeStamp}
          tabIndex={-1}
          readOnly
        />
        <input
          className="form__noDisplay"
          name="active"
          value={active}
          tabIndex={-1}
          readOnly
        />
        <Input
          indx={indx}
          type="text"
          title="Award Name"
          placeholder="1st Place Hacker"
          inputStyle="form"
          headStyle
          name="name"
          value={name}
        />
        <div className="form__dates">
          <Input
            indx={indx}
            type="date"
            title="Date Received"
            inputStyle="dateForm"
            name="awardDate"
            value={awardDate}
          />
          <Input
            indx={indx}
            type="text"
            title="Awarded By"
            inputStyle="form"
            name="awarder"
            value={awarder}
          />
        </div>
        <TextArea
          indx={indx}
          type="textarea"
          title="Description"
          placeholder="description"
          name="description"
          value={description}
        />
      </form>
    );
  };
  const addForm = (evt) => {
    setAdded(true);
    evt.preventDefault();
    setAwardForms([...awardForms, singleForm(indx)]);
    setIndx(indx + 1);
  };

  const removeLastForm = (evt) => {
    document.getElementById("failure").style.display = 'none';
    setAdded(false);
    evt.preventDefault();
    let [formToPop, newFormList] = [awardForms.pop(), awardForms];
    setAwardForms([...newFormList]);
    formToPop && setIndx(indx - 1);
    if (formToPop?.props?.id) {
      const awardsFieldId = formToPop.props.id;
      // use delete method
      // ajaxSetup();
      $.ajax(`${deleteAwardEndpoint}?id=${awardsFieldId}`, {
        type: "DELETE",
      });
    }
  };

  const saveForms = (evt) => {

    setAwardDescError("");
    setDateError("");
    setAwardNameError("");
    validation = true;


    evt.preventDefault();
    document.querySelectorAll(".form").forEach((form) => {
      const {
        uid,
        creationTimeStamp,
        modifiedTimeStamp,
        active,
        name,
        awardDate,
        awarder,
        description,
        ordinal,
      } = form;
      const awardData = {
        resumeId: resumeId,
        name: name?.value,
        awardDate: awardDate?.value,
        awarder: awarder?.value,
        description: description?.value,
        ordinal: parseInt(ordinal?.value),
      };

      if(form.name.value === "" )
      {
        setAwardNameError("Please enter your award name");
        validation = false;
      }
      if(form.description.value === "")
      {
        setAwardDescError("Please enter description");
        validation = false;
      }
      if(form.awardDate.value === "")
      {
        setDateError("Please enter the date received");
        validation = false;
      }

      if(validation)
      {

      


      if (form.id) {
        awardData.active = active?.value;
        awardData.creationTimeStamp = creationTimeStamp?.value;
        awardData.modifiedTimeStamp = modifiedTimeStamp?.value;
        editAward(awardData, form.id);
      } else {
        addAward(awardData, form, uid?.value);
      }
      document.getElementById("failure").style.display = 'block';
    }
    else{
      document.getElementById("failure").style.display = 'block';
    }
    });
    if(validation)
    {
    setNextLoad(true);
    setTimeout(() => {
      handlePagination(1,"awards");
    }, 500);
  }
  else{
    setNextLoad(false);
  }
  };

  return loaded ? (
    <>
      {nextLoad && (
        <div className="form__loader">
          <div className="form__spinner"></div>
        </div>
      )}
      <div className="awardForm">
        <h3>Your Awards</h3>

        <div id = "failure">
            <h3> {awardNameError}</h3>
            <h3> {awardDescError}</h3> 
            <h3> {dateError }</h3>
            
        </div>

        {awardForms.map((component, indx) => (
          <React.Fragment key={indx}>{component}</React.Fragment>
        ))}

        <div className="form__buttons">
          <button className="form__button" onClick={(evt) => saveForms(evt)}>
            Save & Finish
          </button>
          <button className="form__button" onClick={(evt) => addForm(evt)}>
            Add Award
          </button>
          <button
            style = {added ? {display : "block"} : {display : "none"}}
            className="form__button"
            onClick={(evt) => removeLastForm(evt)}
          >
            Remove Award
          </button>
        </div>
      </div>
    </>
  ) : (
    <div className="form__loader">
      <div className="form__spinner"></div>
    </div>
  );
}

export default AwardForm;
