import $ from "jquery";
import { getToken } from "../../authetication/loginState";

$.ajaxSetup({
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
});

const UploadResume = () => 
{
  return (
    <h3>Upload your resume, we'll parse it for you!</h3>
  );
};
  
export default UploadResume;