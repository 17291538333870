import React, { useState, useEffect } from "react";
import $ from "jquery";
import { getToken } from "../../authetication/loginState";
import "./internshipPreferenceQuestions.css";
import InternshipPreferenceQuestions from "./internshipPreferenceQuestions";

const PreferenceMain = () => {
    const [option, setOption] = useState(0);
    const [titleFieldId, setTitleFieldId] = useState("BC290F9D-E490-44F6-B1CA-D873E27E9C1A");

    const ajaxSetup = () => {
        $.ajaxSetup({
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          });
    }

    const getData = () => {
        $.get(`${process.env.REACT_APP_TITLE_GET}`, {titleFieldId}, (data) => {
            var i;
            var options = [];
            var temp;
            for(i=0; i<data.length; i++){
                temp = {};
                temp['value'] = data[i].title1;
                temp['label'] = data[i].title1;
                options.push(temp);
            }
            setOption(options);
        });
    }

    useEffect(() => {
        ajaxSetup();
        getData();

      }, []);


    return(
        <div>
            {option !== 0 && <InternshipPreferenceQuestions data={option}></InternshipPreferenceQuestions>}
        </div>
    )
}

export default PreferenceMain;