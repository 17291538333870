import React from "react";
import "./NoraHome.css";
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

const NoraHome = ({login}) => {

    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <div className="noraHome">
            <div className="text">
                <h1 className={isMobile ? "headText-mobile" : "headText"}>Meet AI Mock Interviewer, Nora.</h1>
                <div className={isMobile ? "subText-mobile" : "subText"}>Big interview coming up? Hone your interview technical and soft skills with AI-curated practice sessions and customized feedback for any position, anytime.
                </div>
                {!login &&<button className={isMobile ? "noraBtn-mobile" : "noraBtn"} onClick={() => navigate('/login')}>Practice with Nora</button>}
            </div>
        </div>
    );
}

export default NoraHome