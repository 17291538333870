import React from "react";
import  "./style.css"

class InternshipSize extends React.Component{
    constructor(props){
        super(props);
    };

    handleOnClick = (e) => {
        var x = this.props.value;
        if (this.props.value[e.target.name] === 1) {
            x[e.target.name] = 0;
        }
        else{
            x[e.target.name] = 1;
        }
        this.props.change({internshipSize : x});
    }

    render(){
        return(
            <div className="CenterQ">
                <h5>What company size you are interested in: </h5>
                <br></br>
                {this.props.value["Fortune 500"] === 0 && <button name="Fortune 500" className="unselected" title = "A Fortune 500 company is an annual ranking of the top 500 largest companies in the United States based on their total revenue." onClick={(e)=>this.handleOnClick(e)}>Fortune 500</button>}
                {this.props.value["Fortune 500"] === 1 && <button name="Fortune 500" className="selected" onClick={(e)=>this.handleOnClick(e)}>Fortune 500</button>}
                {this.props.value["Midtier"] === 1 && <button name="Midtier" className="selected" onClick={(e)=>this.handleOnClick(e)}>Midtier</button>}
                {this.props.value["Midtier"] === 0 && <button name="Midtier" className="unselected" onClick={(e)=>this.handleOnClick(e)}>Midtier</button>}
                {this.props.value["Start ups"] === 1 &&  <button name="Start ups" className="selected" onClick={(e)=>this.handleOnClick(e)}>Start ups</button>}
                {this.props.value["Start ups"] === 0 && <button name="Start ups" className="unselected" onClick={(e)=>this.handleOnClick(e)}>Start ups</button>}
                {this.props.value["Get Me Anything!"] === 0 && <button name="Get Me Anything!" className="unselected" onClick={(e)=>this.handleOnClick(e)}>Get Me Anything!</button>}
                {this.props.value["Get Me Anything!"] === 1 && <button name="Get Me Anything!" className="selected" onClick={(e)=>this.handleOnClick(e)}>Get Me Anything!</button>}
            </div>
        );
    };
};

export default InternshipSize;