import React from "react";
import  "./style.css"

class InternshipPreference extends React.Component{
    constructor(props){
        super(props);
    };

    handleOnClick = (e) => {
        var x = this.props.value;
        if (this.props.value[e.target.name] === 1) {
            x[e.target.name] = 0;
        }
        else{
            x[e.target.name] = 1;
        }
        this.props.change({internshipPreference : x});
    }

    render(){
        return(
            <div className="CenterQ">
                <h5 className="centerSubtitle">Internship type:</h5>
                <br></br>
                {this.props.value["fullTime"] === 0 && <button name="fullTime" className="unselected" onClick={(e)=>this.handleOnClick(e)}>Full-time</button>}
                {this.props.value["fullTime"]  === 1 && <button name="fullTime" className="selected" onClick={(e)=>this.handleOnClick(e)}>Full-time</button>}
                {this.props.value["partTime"]  === 1 && <button name="partTime" className="selected" onClick={(e)=>this.handleOnClick(e)}>Part-time</button>}
                {this.props.value["partTime"]  === 0 && <button name="partTime" className="unselected" onClick={(e)=>this.handleOnClick(e)}>Part-time</button>}
                {this.props.value["contract"]  === 1 &&  <button name="contract" className="selected" onClick={(e)=>this.handleOnClick(e)}>Contract</button>}
                {this.props.value["contract"]  === 0 && <button name="contract" className="unselected" onClick={(e)=>this.handleOnClick(e)}>Contract</button>}
                {this.props.value["paid"]  === 0 && <button name="paid" className="unselected" onClick={(e)=>this.handleOnClick(e)}>Paid</button>}
                {this.props.value["paid"]  === 1 && <button name="paid" className="selected" onClick={(e)=>this.handleOnClick(e)}>Paid</button>}
                <br></br>
                {this.props.value["remote"]  === 1 && <button name="remote" className="selected" onClick={(e)=>this.handleOnClick(e)}>Remote</button>}
                {this.props.value["remote"]  === 0 && <button name="remote" className="unselected" onClick={(e)=>this.handleOnClick(e)}>Remote</button>}
                {this.props.value["temporary"]  === 1 &&  <button name="temporary" className="selected" onClick={(e)=>this.handleOnClick(e)}>Temporary</button>}
                {this.props.value["temporary"]  === 0 && <button name="temporary" className="unselected" onClick={(e)=>this.handleOnClick(e)}>Temporary</button>}
                {this.props.value["volunteer"]  === 0 && <button name="volunteer" className="unselected" onClick={(e)=>this.handleOnClick(e)} >Volunteer</button>}
                {this.props.value["volunteer"]  === 1 && <button name="volunteer" className="selected" onClick={(e)=>this.handleOnClick(e)}>Volunteer</button>}
            </div>
        );
    };
};

export default InternshipPreference;