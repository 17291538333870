import React, { useState, useEffect } from "react";
import $ from "jquery";
import { getToken } from "../../authetication/loginState";
import  "./style.css"
import Select from 'react-select';

const options = [
    { value: 1, label: 'Engineering' },
    { value: 2, label: 'Computer Science/IT' },
    { value: 3, label: 'Health-Care' },
    { value: 4, label: 'Business' },
    { value: 5, label: 'Marketing' },
    { value: 6, label: 'Accounting' },
    { value: 7, label: 'Financial' },
    { value: 8, label: 'Retail' },
    { value: 9, label: 'Legal' },
    { value: 10, label: 'Human Resource' },
    { value: 11, label: 'Public Relation' },
    { value: 12, label: 'Fashion' },
    { value: 13, label: 'Non Profit' },
    { value: 14, label: 'STEM' },
    { value: 15, label: 'Arts/Humanities' },
    { value: 16, label: 'Business' },
    { value: 17, label: 'Social Ecology' },
    { value: 18, label: 'Undecided' },
  ];

class CompaniesInterested extends React.Component{
    constructor(props){
        super(props);
    };

    handleOnChange = (values) => {
        this.props.change({companiesInterested: values});
    }
    
    render(){
        return(
            <div className="CenterQ">
                <h5>Which industry you would prefer companies to be in:</h5>
                <br></br>
                <div className="questionnaire-content">
                    <Select 
                    className = "page"
                    isMulti={true}
                    onChange = {this.handleOnChange}
                    value = {this.props.value}
                    options={options}/>
                </div>
            </div>
        );
    };
};

export default CompaniesInterested;