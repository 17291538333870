import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from "axios";
import './style.css';
import { ReactMic } from 'react-mic';
import PersonZoom2 from "../../images/person-zoom2.png";
import VideoTimer from "../../images/video-timer.png";
import { Oval } from 'react-loader-spinner'; 
import { AssemblyAI } from 'assemblyai';
import ErrorIcon from "../../images/error-icon.png";
import { useMediaQuery } from '@mui/material';
import CheckCircle from "../../images/CheckCircle.png";

function CandidateInterviewScreen() {
  const { candidateToInterviewId } = useParams(); // Extract candidateToInterviewId from the URL
  const [candidateInfo, setCandidateInfo] = useState(null);
  const [started, setStarted] = useState(false);
  const [finished, setFinished] = useState(false);
  const [history, setHistory] = useState([]);
  const [record, setRecord] = useState(false);
  const [isVideoOn, setIsVideoOn] = useState(false);
  const [time, setTime] = useState(0);
  const videoRef = useRef(null);
  const messageEndRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isNoraSpeaking, setIsNoraSpeaking] = useState(false);
  const [isInterviewDone, setInterviewDone] = useState(false);
  const [aiResponse, setAiResponse] = useState('');
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [feedbackLoading, setFeedbackLoading] = useState(false);

  const client = new AssemblyAI({
    apiKey: "d1ed33af12ac4d478785ac40bb4599a7"
  });

  console.log("HANNAN candidateToInterviewId: ", candidateToInterviewId);

  useEffect(() => {
    const fetchCandidateInfo = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_GET_INTERVIEW_STARTED_DATA, {
          params: { candidateToInterviewId },
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log("HANNAN fetchCandidateInfo: ", response.data); 
        setCandidateInfo(response.data);
      } catch (error) {
        console.error("Failed to fetch candidate information", error);
      } finally {
        setLoading(false); // Stop loading when data is fetched
      }
    };

    fetchCandidateInfo();
  }, [candidateToInterviewId]);

  console.log("HANNAN candidateInfo: ", candidateInfo);

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      window.history.pushState(null, null, window.location.href);
      alert("You cannot go back during the interview.");
    };
  
    window.history.pushState(null, null, window.location.href); // Add initial state
    window.addEventListener("popstate", handlePopState);
  
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "Are you sure you want to leave? Your interview progress will be lost.";
    };
  
    window.addEventListener("beforeunload", handleBeforeUnload);
  
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // Change body style for mockInterviewerZoom
    document.body.style.backgroundColor = "#CDE1D6";
    
    // Cleanup function to reset styles when component is unmounted or when navigating away
    return () => {
      document.body.style.backgroundColor = "#203546";
    };
  }, []);

  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [history]);

  useEffect(() => {
    console.log("aiResponse: ", aiResponse);
  }, [aiResponse]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(prevTime => prevTime + 1);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds) => {
    const m = Math.floor(seconds / 60);
    const s = seconds % 60;
    return `${m}m ${s < 10 ? '0' : ''}${s}sec`;
  };

  const startRecording = () => {
    setRecord(true);
  };

  const stopRecording = () => {
    setRecord(false);
  };

  //This will retry the API twice if it fails for some reason in the first time
  const retryRequest = async (fn, retries = 2, interval = 1000) => {
    for (let attempt = 0; attempt < retries; attempt++) {
      try {
        setHasError(false);
        return await fn();
      } catch (error) {
        if (attempt < retries - 1) {
          await new Promise(resolve => setTimeout(resolve, interval));
        } else {
          setHasError(true);
          throw error;
        }
      }
    }
  };

  const onStop = (recordedBlob) => {
    const reader = new FileReader();
    reader.readAsDataURL(recordedBlob.blob);
    setHasError(false);
    assemblySpeechToText(recordedBlob.blob); 
  };

  const assemblySpeechToText = async (audioBlob) => {
    setIsLoading(true);
    const transcript = await client.transcripts.transcribe({
      audio: audioBlob
    });

    if (transcript.status === 'error') {
      console.error("Error transcribing audio", transcript.error);
    }

    handleSendMessage(transcript.text);
  }

  const toggleVideo = async () => {
    if (isVideoOn) {
        if (videoRef.current && videoRef.current.srcObject) {
            let stream = videoRef.current.srcObject;
            let tracks = stream.getTracks();

            tracks.forEach((track) => track.stop());
            videoRef.current.srcObject = null;
        }
    } else {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
            }
        } catch (err) {
            console.error("Error accessing webcam: ", err);
        }
    }
    setIsVideoOn(!isVideoOn);
};

  const handleSendMessage = async (transcription) => {
    setHasError(false);
    const formData = new FormData();

    formData.append('position', candidateInfo?.companyInterview?.position);
    formData.append('jobDescription', candidateInfo?.companyInterview?.jobDescription);
    formData.append('UserMessage', transcription);
    formData.append('CompanyName', candidateInfo?.company?.companyName);

    setHistory(prevHistory => {
      const newHistory = [...prevHistory, { role: 'User', message: transcription }];    
      formData.append('History', JSON.stringify(newHistory));
      return newHistory;
    });

    try {
      const response = await retryRequest(() => axios.post(`${process.env.REACT_APP_API_ENDPOINT}/Chat/nora-candidate-interview-main`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }));
      const { response: aiResponse } = response.data;

      setAiResponse(aiResponse);

      const audioResponse = await retryRequest(() => axios.post(`${process.env.REACT_APP_API_ENDPOINT}/Chat/textToSpeech`, {
        text: aiResponse
      }, {
        responseType: 'blob'
      }));

      if (aiResponse.toLowerCase().includes("end of the interview")) {
        setInterviewDone(true);
      }

      if (audioResponse.status === 200) {
        const audioBlob = new Blob([audioResponse.data], { type: 'audio/wav' });
        const audioUrl = URL.createObjectURL(audioBlob);

        const audio = new Audio(audioUrl);
        setIsNoraSpeaking(true);
        audio.play();
        audio.onended = () => {
          setIsNoraSpeaking(false);
        };
      } else {
        setHasError(true);
        console.error('Failed to convert text to speech');
      }

      setHistory(prevHistory => [
        ...prevHistory,
        { role: 'Assistant', message: aiResponse },
      ]);
    } catch (error) {
      setHasError(true);
      console.error('Error sending message:', error);
    }
    setIsLoading(false);
  };  

const handleEndInterview = async () => {
    if(isVideoOn) {
      toggleVideo();
    }
  
    setFeedbackLoading(true);
    const formData = new FormData();
  
    setHistory(prevHistory => {
      const newHistory = [...prevHistory, { role: 'User', message: "last" }];    
      formData.append('History', JSON.stringify(newHistory));
      return newHistory;
    });
  
    const generateFeedbackLoop = async () => {
      try {

          const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/Chat/generateCandidateFeedback`, {
            Position: candidateInfo?.companyInterview?.position,
            JobDescription: candidateInfo?.companyInterview?.jobDescription,
            History: JSON.stringify(history),
            CompanyName: candidateInfo?.company?.companyName,
            CandidateToInterviewId: candidateToInterviewId,
          }, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Accept: 'text/plain',
            },
          });
  
          setFeedbackLoading(false);
          setFinished(true);
          setStarted(false);
  
          return;
      } catch (error) {
        console.error('Error sending message:', error);
        setFeedbackLoading(false);
      }
    };
  
    generateFeedbackLoop();
  };  

  const startInterview = () => {
    setStarted(true);
    setIsLoading(true);
     // Automatically turn on the video when starting the interview if it is off
    if (!isVideoOn) {
        toggleVideo();  // This will turn on the video and set isVideoOn to true
    }
    handleSendMessage("first");
  };

  return (
    <><div>
        {started && (
        <div className="interview-title">
            <div>{candidateInfo?.companyInterview?.position}</div>
            <div className="timer">
            <img src={VideoTimer} className="video-timer-styling" alt="Timer icon" />
            {formatTime(time)}
            </div>
        </div>
        )}
      </div>
      <div className="nora-form-2">
            {finished ? (
                <div className="completionScreen">
                    <div className="completion-content">
                        <img src={CheckCircle} alt="Completion Check" className="completion-icon" />
                        <h2>Your Interview with Nora is complete!</h2>
                        <p>You may now exit out of this browser.</p>
                    </div>
                </div>
            ) : (!started && !finished) ? (
                <div className="instructionsImage2">
                    <div className="instruction-container2">
                        <h1>Welcome to your {candidateInfo?.companyInterview?.position} Interview with Nora</h1>
                        <p>This is the <strong>actual interview</strong>, which consists of approximately <strong>10 questions</strong> and will last around <strong>20 minutes</strong>. Please <strong>do not exit</strong> this browser, as once you exit you will not be able to restart.</p>
                    </div>
                    <div className="instruction-checklist2">
                        <p><span className="checkmark">✔</span> Ensure you are in a quiet, noise-free environment.</p>
                        <p><span className="checkmark">✔</span> Click 'Start' to begin answering a question and 'Stop' to finish answering.</p>
                        <p><span className="checkmark">✔</span> Ensure your audio permissions are enabled once you start answering.</p>
                        <p><span className="checkmark">✔</span> Ensure your video permissions are enabled once you start answering.</p>
                    </div>
                    <button onClick={startInterview} className="start-button2">Start</button>
                </div>
            )
                : (
                    <>
                        {feedbackLoading && (
                            <div className="loading-overlay">
                                <Oval color="#00BFFF" height={80} width={80} />
                                <div className="loading-text">Processing your interview! Please don't close this page</div>
                            </div>
                        )}
                        <div className="controls-section-2">
                            <video ref={videoRef} autoPlay className={`video2-feed ${isVideoOn ? '' : 'hidden'}`}></video>

                            <div className="controls2">
                                {(!isLoading && !isNoraSpeaking && !isInterviewDone) && (
                                    <div>
                                        <ReactMic
                                            record={record}
                                            className="mic-styling2"
                                            onStop={onStop}
                                            visualSetting="frequencyBars" />
                                    </div>
                                )}
                                <div className="action-buttons">
                                    <button disabled={(!record && (isLoading || isNoraSpeaking || isInterviewDone))} onClick={record ? stopRecording : startRecording}
                                        type="button"
                                        className={`recordBtn round-btn ${record ? 'stop-recording' : ''} ${(!record && (isLoading || isNoraSpeaking || isInterviewDone)) ? 'disabled' : ''}`}>
                                        {record ? 'Stop Answering' : 'Start Answering'}
                                    </button>
                                    <div className="video-container-2">
                                        <div className="video-wrapper-2">
                                            {<img className="person-zoom" src={PersonZoom2} alt="Video of user" />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="transcript-container">
                            <h2 style={{ textAlign: 'center' }}>Live Transcript</h2>
                            <div className="message-history">
                                {history.map((msg, index) => (
                                    msg.message !== "first" && msg.message !== "last" && (
                                        <div key={index} className={`message ${msg.role}`}>
                                            {msg.message}
                                        </div>
                                    )
                                ))}
                                {hasError && (
                                    <div className="error-message">
                                        <img src={ErrorIcon} alt="Error Icon" className="error-icon" />
                                        <span>Oops, we couldn't capture your speech. Please try recording again.</span>
                                    </div>
                                )}
                                {isLoading && (
                                    <div className="">
                                        <Oval color="#00BFFF" height={20} width={20} />
                                        <span>Nora is thinking...</span>
                                    </div>
                                )}
                                <div ref={messageEndRef} />
                            </div>
                            <button onClick={handleEndInterview} type="button" className="endInterviewBtn2">End Interview</button>
                        </div>
                    </>
                )}
          </div>
        </>
  );
}

export default CandidateInterviewScreen;