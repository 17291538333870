import React, { useState, useEffect } from "react";
import "./style.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from '@mui/material';
import axios from 'axios';
import { getToken } from '../../authetication/loginState';
import Clock from "../../images/video-timer.png";
import NoraUploader from "../../components/NoraUploader";
import InterviewsSwiper from "../../components/InterviewsSwiper";
import Box from '@mui/material/Box';
import Modal from "@material-ui/core/Modal";
import Spinner from "../../components/SectionSpinner/spinner";

const MockInterviewerInput = () => {
    const location = useLocation();
    const isMobile = useMediaQuery('(max-width:600px)');
    const { candidatePosition, candidateJobDescription, candidateCompanyName, candidateResume, candidateResumeFileName, fromFeedback = false } = location.state || {};

    const [position, setPosition] = useState(candidatePosition || "");
    const [jobDescription, setJobDescription] = useState(candidateJobDescription || "");
    const [resume, setResume] = useState(candidateResume || null);
    const [fileName, setFileName] = useState(candidateResumeFileName || "");
    const [companyName, setCompanyName] = useState(candidateCompanyName || "");
    const [interviews, setInterviews] = useState([]);
    const [interviewsSummary, setInterviewsSummary] = useState(null);
    const storedInterviewsSummary = localStorage.getItem("interviewsSummary");
    const interviewsSummaryFinal = storedInterviewsSummary ? JSON.parse(storedInterviewsSummary) : interviewsSummary;

    const [interviewsNextStepCompany, setInterviewsNextStepCompany] = useState(null);
    const storedInterviewsNextStepCompany = localStorage.getItem("interviewsNextStepCompany");
    const interviewsNextStepCompanyFinal = storedInterviewsNextStepCompany ? JSON.parse(storedInterviewsNextStepCompany) : interviewsNextStepCompany;

    const [interviewsNextStepPosition, setInterviewsNextStepPosition] = useState(null);
    const storedInterviewsNextStepPosition = localStorage.getItem("interviewsNextStepPosition");
    const interviewsNextStepPositionFinal = storedInterviewsNextStepPosition ? JSON.parse(storedInterviewsNextStepPosition) : interviewsNextStepPosition;

    const [technicalLevel, setTechnicalLevel] = useState(5);
    const [open, setOpen] = useState(false);
    const [openJobDescription, setOpenJobDescription] = useState(false);
    const [openRedoInterview, setOpenRedoInterview] = useState(false);
    const [openCompanySuggestion, setOpenCompanySuggestion] = useState(false);
    const [openPositionSuggestion, setOpenPositionSuggestion] = useState(false);
    const [selectedInterview, setSelectedInterview] = useState(null);
    const [visibleRows, setVisibleRows] = useState(1); 
    const [fromFeedbackState, setFromFeedbackState] = useState(location.state?.fromFeedback || false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    let navigate = useNavigate();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: "70vw",
        height: "80vh",
        transform: 'translate(-50%, -50%)',
        bgcolor: '#EBF3EF',
        boxShadow: 24,
        textAlign: 'center',
        overflow: "scroll",
        p: 4,
    };

    useEffect(() => {
        if (!getToken()) {
            // No token found, redirect to login
            localStorage.clear(); // Clear any stored data
            navigate("/login");
        }

        const slider = document.querySelector('.custom-slider');
        if (slider) {
            slider.style.background = `linear-gradient(to right, #56C385 0%, #56C385 ${technicalLevel * 10}%, #fff ${technicalLevel * 10}%, #fff 100%)`;
        }
        const fetchInterviews = async () => {
            try {
              setIsLoading(true);
              const response = await axios.get(
                `${process.env.REACT_APP_API_ENDPOINT}/Chat/getInterviews`, 
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Accept: 'text/plain',
                    Authorization: `Bearer ${getToken()}`,
                  },
                }
              );
              setInterviews(response.data);
              setIsLoading(false);
            } catch (error) {
              setIsLoading(false);
              console.error('Error fetching interviews:', error);
            }
        };
      
        fetchInterviews();

        const fetchInterviewsSummary = async () => {
            try {
              const response = await axios.get(
                `${process.env.REACT_APP_API_ENDPOINT}/Chat/getInterviewsSummary`, 
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Accept: 'text/plain',
                    Authorization: `Bearer ${getToken()}`,
                  },
                }
              );
              setInterviewsSummary(response.data.overallSummary);
              localStorage.setItem("lastSummaryFetch", Date.now()); 
              localStorage.setItem("interviewsSummary", JSON.stringify(response.data.overallSummary)); 
            } catch (error) {
              console.error('Error fetching interviews:', error);
            }
        };

        const fetchInterviewsNextStepCompany = async () => {
            try {
              const response = await axios.get(
                `${process.env.REACT_APP_API_ENDPOINT}/Chat/getInterviewsNextStepsCompany`, 
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Accept: 'text/plain',
                    Authorization: `Bearer ${getToken()}`,
                  },
                }
              );
              setInterviewsNextStepCompany(response.data);
              localStorage.setItem("interviewsNextStepCompany", JSON.stringify(response.data)); 
            } catch (error) {
              console.error('Error fetching interviews:', error);
            }
        };

        const fetchInterviewsNextStepPosition = async () => {
            try {
              const response = await axios.get(
                `${process.env.REACT_APP_API_ENDPOINT}/Chat/getInterviewsNextStepsPosition`, 
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Accept: 'text/plain',
                    Authorization: `Bearer ${getToken()}`,
                  },
                }
              );
              setInterviewsNextStepPosition(response.data);
              localStorage.setItem("interviewsNextStepPosition", JSON.stringify(response.data)); 
            } catch (error) {
              console.error('Error fetching interviews:', error);
            }
        };
      
        const lastFetchTime = parseInt(localStorage.getItem("lastSummaryFetch")) || 0;
        const currentTime = Date.now();
        const twoHours = 7200000; // 2 hours in milliseconds
        
        if (firstLoad || currentTime - lastFetchTime >= twoHours) {
            fetchInterviewsSummary();
            fetchInterviewsNextStepCompany();
            fetchInterviewsNextStepPosition();
            setFirstLoad(false); // Set firstLoad to false after the initial fetch
        }

        const interval = setInterval(fetchInterviewsSummary, twoHours);

        return () => clearInterval(interval);
    }, [firstLoad]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        setResume(e.target.value);

        reader.onload = function(event) {
            localStorage.setItem("resume", event.target.result);
            setResume(event.target.result);  // Set the resume state to the file's content
        };

        reader.readAsDataURL(file);
        setFileName(file ? file.name : "");  // Update the fileName state
        localStorage.setItem("resumeFileName", file.name);

    };

    const handleTechnicalLevelChange = (e) => {
        var value = e.target.value
        setTechnicalLevel(value);
        e.target.style.background = `linear-gradient(to right, #56C385 0%, #56C385 ${value * 10}%, #fff ${value * 10}%, #fff 100%)`;
    };

    const handleGetStarted = () => {
        localStorage.setItem("companyName", companyName);
        localStorage.setItem("position", position);
        localStorage.setItem("jobDescription", jobDescription);
        localStorage.setItem("technicalLevel", technicalLevel);
        navigate("/mockInterviewerZoom");
    };

    const triggerFileInput = () => {
        document.getElementById('fileInput').click();
    };

    const formatTime = (dateStr) => {
        const date = new Date(dateStr);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        return formattedDate;
    };

    const handleViewFeedback = (interview) => {
        navigate("/mockInterviewerFeedback", { state: { aiResponse: interview.feedback, 
        history: interview.history, timerFeedback: interview.interviewTime, fromInputScreen: true } });
    };

    const handleInterviewRedoCompany = (companyName, jobDescription, position) => {
        setOpen(true);
        setCompanyName(companyName);
        setPosition(position);
        setJobDescription(jobDescription);
        return (
            <Modal onClose={() => setOpen(false)} open={open || fromFeedbackState}>
                    <Box sx={style}>
                        <button className="close-button" onClick={() => { setOpen(false); setFromFeedbackState(false); }}>X</button>
                            <NoraUploader
                                position={position}
                                setPosition={setPosition}
                                companyName={companyName}
                                setCompanyName={setCompanyName}
                                jobDescription={jobDescription}
                                setJobDescription={setJobDescription}
                                technicalLevel={technicalLevel}
                                handleFileChange={handleFileChange}
                                triggerFileInput={triggerFileInput}
                                handleTechnicalLevelChange={handleTechnicalLevelChange}
                                fileName={fileName}
                                handleGetStarted={handleGetStarted}
                                isFormComplete={isFormComplete}
                                isModal={true}
                            />
                    </Box>
            </Modal>
        )
    };

    const handleInterviewRedoPosition = (companyName, jobDescription, position) => {
        setOpen(true);
        setCompanyName(companyName);
        setPosition(position);
        setJobDescription(jobDescription);
        return (
            <Modal onClose={() => setOpen(false)} open={open || fromFeedbackState}>
                    <Box sx={style}>
                        <button className="close-button" onClick={() => { setOpen(false); setFromFeedbackState(false); }}>X</button>
                            <NoraUploader
                                position={position}
                                setPosition={setPosition}
                                companyName={companyName}
                                setCompanyName={setCompanyName}
                                jobDescription={jobDescription}
                                setJobDescription={setJobDescription}
                                technicalLevel={technicalLevel}
                                handleFileChange={handleFileChange}
                                triggerFileInput={triggerFileInput}
                                handleTechnicalLevelChange={handleTechnicalLevelChange}
                                fileName={fileName}
                                handleGetStarted={handleGetStarted}
                                isFormComplete={isFormComplete}
                                isModal={true}
                            />
                    </Box>
            </Modal>
        )
    };

    const handleInterviewRedo = (companyName, jobDescription, position) => {
        setOpen(true);
        setCompanyName(companyName);
        setPosition(position);
        setJobDescription(jobDescription);
        return (
            <Modal onClose={() => setOpen(false)} open={open || fromFeedbackState}>
                    <Box sx={style}>
                        <button className="close-button" onClick={() => { setOpen(false); setFromFeedbackState(false); }}>X</button>
                            <NoraUploader
                                position={position}
                                setPosition={setPosition}
                                companyName={companyName}
                                setCompanyName={setCompanyName}
                                jobDescription={jobDescription}
                                setJobDescription={setJobDescription}
                                technicalLevel={technicalLevel}
                                handleFileChange={handleFileChange}
                                triggerFileInput={triggerFileInput}
                                handleTechnicalLevelChange={handleTechnicalLevelChange}
                                fileName={fileName}
                                handleGetStarted={handleGetStarted}
                                isFormComplete={isFormComplete}
                                isModal={true}
                            />
                    </Box>
            </Modal>
        )

    };

    const loadMore = () => {
        setVisibleRows(prevRows => prevRows + 1);
    }

    const getVisibleInterviews = (rowIndex) => {
        const start = rowIndex * 3;
        return interviews
          .sort((a, b) => new Date(b.creationTimeStamp) - new Date(a.creationTimeStamp))
          .slice(start, start + 3);
      };

    const isFormComplete = position && resume && companyName;

    return (
        <div className="mockInterviewerInput-page">
            {isLoading ? (
                <div className="w-full h-screen px-40 mobile-padding">
                    <Spinner />
                </div>
            ) : (
            interviews && interviews.length > 0 ? (
                <>
                    <div className="main-container">
                        { !isMobile && (
                        <div className="side-menu-nora menu-add">
                            <ul>
                                <li onClick={() => document.getElementById("start").scrollIntoView({ behavior: 'smooth' })}>Start Interview</li>
                                <li onClick={() => document.getElementById("interviews").scrollIntoView({ behavior: 'smooth' })}>Your Interviews</li>
                                <li onClick={() => document.getElementById("progress").scrollIntoView({ behavior: 'smooth' })}>Your Progress</li>
                                <li onClick={() => document.getElementById("nextsteps").scrollIntoView({ behavior: 'smooth' })}>Next Steps</li>
                                <li onClick={() => document.getElementById("subscription").scrollIntoView({ behavior: 'smooth' })}>Manage Subscription</li>
                            </ul>
                        </div> )}
                        
                        <div className="main-content">
                        <div className="section-title-input">Welcome Back, {JSON.parse(interviews[0].feedback)["candidate"]} </div>

                        <div className="section-subtitle-input" id="start">Start New Interview </div>

                        <div className="interview-screen">
                            <h1 className="fontWeight-600">Big interview coming up? </h1>
                            <h1 className="fontWeight-600"> Need to get some practice in?</h1>
                            <br /> <br />
                            <button className={isMobile? "inputButton-1 button-mobile": "inputButton-1"} onClick={() => { setOpen(true) }}>Start New Interview</button>
                            <Modal onClose={() => setOpen(false)} open={open || fromFeedbackState}>
                                <Box sx={style}>
                                <button className="close-button" onClick={() => { setOpen(false); setFromFeedbackState(false); setCompanyName(""); setPosition(""); setJobDescription("")}}>X</button>
                                    <NoraUploader
                                        position={position}
                                        setPosition={setPosition}
                                        companyName={companyName}
                                        setCompanyName={setCompanyName}
                                        jobDescription={jobDescription}
                                        setJobDescription={setJobDescription}
                                        technicalLevel={technicalLevel}
                                        handleFileChange={handleFileChange}
                                        triggerFileInput={triggerFileInput}
                                        handleTechnicalLevelChange={handleTechnicalLevelChange}
                                        fileName={fileName}
                                        handleGetStarted={handleGetStarted}
                                        isFormComplete={isFormComplete}
                                        isModal={true}
                                    />
                                </Box>
                            </Modal>
                        </div>
                        
                        <div className="section-subtitle-input margin-top-sections" id="interviews">Your Completed Interviews</div>
                        { !isMobile ? ( <div>{Array.from({ length: visibleRows }).map((_, rowIndex) => (
                        <div className="completed-interviews">
                            {getVisibleInterviews(rowIndex)
                                .map((interview, index) => (
                                    <div className="interview-card">
                                        <div key={index} className="interview-card-info"> {interview.position} </div> 
                                        <div className="interview-card-info">{interview.companyName}</div> <br/>
                                        <div className="interview-clock-styling">{formatTime(interview.creationTimeStamp)}</div>
                                        {interview.interviewTime && (
                                            <div>
                                                <img src={Clock} className="interview-clock-text-styling" /> 
                                                {interview.interviewTime}
                                            </div>
                                        )}                                        
                                        <br /> <br/>
                                        <button className="inputButton-1" onClick={() => handleViewFeedback(interview)}> View Feedback Report </button>
                                        <br /> <br />
                                        <button className="inputButton-2" 
                                            onClick={() => {
                                                setSelectedInterview(interview);
                                                setOpenJobDescription(true);
                                            }}>
                                            View Job Description
                                        </button>

                                        <Modal onClose={() => setOpenJobDescription(false)} open={openJobDescription}>
                                            <Box sx={style}>
                                                <button className="close-button" onClick={() => setOpenJobDescription(false)}>X</button>
                                                {selectedInterview && (
                                                <>
                                                    <h4 className="interview-modal-position">{selectedInterview.position} @ {selectedInterview.companyName}</h4>
                                                    <h4 className="interview-modal-jd">Job Description: </h4>
                                                    <div className="interview-modal-jd-text">
                                                        {selectedInterview.jobDescription.split('\n').map((line, index) => (
                                                            <h4 key={index}>{line}</h4>
                                                        ))}
                                                    </div>                                                
                                                </>
                                                )}
                                            </Box>
                                        </Modal>
                                    </div> 
                                ))} 
                                
                        </div>))}
                        {visibleRows * 3 < interviews.length && (<div className="see-more" onClick={loadMore}><u>See More</u></div> )}    
                        {visibleRows > 1 && (<div className="see-more" onClick={()=>setVisibleRows(1)}><u>See Less</u></div>)}
                           </div>  ) : (<InterviewsSwiper interviews={interviews} 
                           formatTime={formatTime} handleViewFeedback={handleViewFeedback}
                           setSelectedInterview={setSelectedInterview} setOpenJobDescription={setOpenJobDescription}
                           selectedInterview={selectedInterview} openJobDescription={openJobDescription}/>)}
                        <div className="section-subtitle-input  margin-top-sections" id="progress">Your Interview Progress</div>
                        {interviewsSummaryFinal && (
                            <div className="interview-summary">
                                <h4 className="summary-heading">Overall Summary</h4>
                                <p className="summary-caption">{interviewsSummaryFinal?.overall_summary}</p>

                                <h4 className="summary-heading-2">Technical Skill Improvement</h4>
                                <p className="summary-caption">{interviewsSummaryFinal?.overall_technical_skills}</p>

                                <h4 className="summary-heading-2">Soft Skill Improvement</h4>
                                <p className="summary-caption">{interviewsSummaryFinal?.overall_soft_skills}</p>
                            </div>
                        )}

                        <br /> <br />
                        <div className="section-subtitle-input" id="nextsteps">Next Steps For You</div>
                            <div className="next-steps">
                            <div className="interview-card-info">Repeat one of your last interviews: </div>
                            {interviews
                                .sort((a, b) => new Date(b.creationTimeStamp) - new Date(a.creationTimeStamp))
                                .map((interview, index) => (
                                <>
                                    <li key={index} className="bullet-points-left-margin">
                                        <button className="link-button" onClick={() => {
                                            setSelectedInterview(interview);
                                            setOpenRedoInterview(true);
                                        }}>
                                            {interview?.position} @ {interview?.companyName}
                                        </button>

                                        <Modal onClose={() => setOpenRedoInterview(false)} open={openRedoInterview}>
                                            <Box sx={style}>
                                                <button className="close-button" onClick={() => setOpenRedoInterview(false)}>X</button>
                                                {selectedInterview && (
                                                <>
                                                    <h4 className="interview-modal-position">{selectedInterview.position} @ {selectedInterview.companyName}</h4>
                                                    <h4 className="interview-modal-jd">Job Description: </h4>
                                                    <div className="interview-modal-jd-text">
                                                        {selectedInterview.jobDescription.split('\n').map((line, index) => (
                                                            <h4 key={index}>{line}</h4>
                                                        ))}
                                                    </div>

                                                    <button className="inputButton-1" onClick={() => handleInterviewRedo(selectedInterview.companyName, 
                                                        selectedInterview?.jobDescription,
                                                        selectedInterview?.position)}>
                                                        Redo Interview
                                                    </button>
                                                </>
                                                )}
                                            </Box>
                                        </Modal>
                                    </li>
                                </>
                            ))} 

                            {interviewsNextStepCompanyFinal && (
                                <><div className="section-suggestion">Here is a company interview suggestion for you: </div>
                                <li className="bullet-points-left-margin">
                                <button className="link-button" onClick={() => {setOpenCompanySuggestion(true)}}>
                                {interviewsNextStepCompanyFinal?.nextStepCompany?.position} @
                                        {interviewsNextStepCompanyFinal?.nextStepCompany?.company}
                                    </button>

                                    <Modal onClose={() => setOpenCompanySuggestion(false)} open={openCompanySuggestion}>
                                        <Box sx={style}>
                                            <button className="close-button" onClick={() => setOpenCompanySuggestion(false)}>X</button>
                                            <h4 className="interview-modal-position">{interviewsNextStepCompanyFinal?.nextStepCompany?.position}
                                                @ {interviewsNextStepCompanyFinal?.nextStepCompany?.company}</h4>
                                            <h4 className="interview-modal-jd">Job Description: </h4>
                                            <div className="interview-modal-jd-text">
                                                    {interviewsNextStepCompanyFinal?.nextStepCompany?.job_description.split('\n').map((line, index) => (
                                                        <h4 key={index}>{line}</h4>
                                                    ))}
                                            </div>

                                            <button className="inputButton-1" onClick={() => handleInterviewRedoCompany(interviewsNextStepCompanyFinal?.nextStepCompany?.company,
                                                interviewsNextStepCompanyFinal?.nextStepCompany?.job_description,
                                                interviewsNextStepCompanyFinal?.nextStepCompany?.position)}>
                                                Start Interview
                                            </button>
                                        </Box>
                                    </Modal>
                                </li></>
                            )}

                            {interviewsNextStepPositionFinal && interviewsNextStepPositionFinal?.nextStepPosition?.position && (
                                <><div className="section-suggestion">Check out this position interview suggestion: </div>
                                <li className="bullet-points-left-margin">
                                    <button className="link-button" onClick={() => {setOpenPositionSuggestion(true)}}>
                                    {interviewsNextStepPositionFinal?.nextStepPosition?.position} @
                                        {interviewsNextStepPositionFinal?.nextStepPosition?.company}
                                    </button>

                                    <Modal onClose={() => setOpenPositionSuggestion(false)} open={openPositionSuggestion}>
                                        <Box sx={style}>
                                            <button className="close-button" onClick={() => setOpenPositionSuggestion(false)}>X</button>
                                            <h4 className="interview-modal-position">{interviewsNextStepPositionFinal?.nextStepPosition?.position}
                                                @ {interviewsNextStepPositionFinal?.nextStepPosition?.company}</h4>
                                            <h4 className="interview-modal-jd">Job Description: </h4>
                                            <div className="interview-modal-jd-text">
                                                    {interviewsNextStepPositionFinal?.nextStepPosition?.job_description.split('\n').map((line, index) => (
                                                        <h4 key={index}>{line}</h4>
                                                    ))}
                                            </div>

                                            <button className="inputButton-1" onClick={() => handleInterviewRedoPosition(interviewsNextStepPositionFinal?.nextStepPosition?.company,
                                                interviewsNextStepPositionFinal?.nextStepPosition?.job_description,
                                                interviewsNextStepPositionFinal?.nextStepPosition?.position)}>
                                                Start Interview
                                            </button>
                                        </Box>
                                    </Modal>
                                </li></>
                            )}
                            </div>

                            <div className="section-subtitle-input  margin-top-sections" id="subscription">Manage your subscription</div>
                                <div className="subscription-summary">
                                    <p className="subscription-caption">We've partnered with Stripe to manage your subscription. 
                                        Click the button to update your payment methods, billing details, or personal information. 
                                        Reach us at contact@internguys for queries</p>

                                    <button className={isMobile? "inputButton-1 button-mobile": "inputButton-1"} 
                                        onClick={() => { window.open("https://billing.stripe.com/p/login/4gw5mvbyE9eB9nW4gg", "_blank") }}>
                                    Manage Subscription</button>
                                </div>
                            </div>
                    </div>
                </>
            ) : (
                <NoraUploader
                    position={position}
                    setPosition={setPosition}
                    companyName={companyName}
                    setCompanyName={setCompanyName}
                    jobDescription={jobDescription}
                    setJobDescription={setJobDescription}
                    technicalLevel={technicalLevel}
                    handleFileChange={handleFileChange}
                    triggerFileInput={triggerFileInput}
                    handleTechnicalLevelChange={handleTechnicalLevelChange}
                    fileName={fileName}
                    handleGetStarted={handleGetStarted}
                    isFormComplete={isFormComplete}
                    isModal={false}
                />
            )
        )}    
        </div>
    );
};

export default MockInterviewerInput;
