import Blogcard from '../../components/Blogcard';
import SearchLogo from '../../images/blog-search.svg';
import Pagination from '../../components/Pagination/Pagination';
import './style.css';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer/index';
import Spinner from '../../components/SectionSpinner/spinner';

const Blog = ({ blogs = [], featured = [] }) => {
  const [searchTerm, setSearchTerm] = useState('');
  let navigate = useNavigate();
  const [featuredName, setFeaturedName] = useState('');
  const [featuredDate, setFeaturedDate] = useState('');
  const [featuredDescription, setFeaturedDescription] = useState('');
  const [featuredImage, setFeaturedImage] = useState('');
  const [featuredBlog, setFeaturedBlog] = useState(null);
  const [featuredAuthor, setFeaturedAuthor] = useState('');
  const [pageData, setPagaData] = useState([]);
  const [data, setData] = useState([]);
  const maxLength = 200;
  const PageSize = 9;
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [resultsNum, setResultsNum] = useState(0);

  useEffect(() => {
    if (!blogs?.length && !featured?.length) {
      fetchBlogs();
    } else {
      setIsLoading(false);
      setData(sortBlogsByDateDesc(blogs));
      setCurrentPage(1);
      const featuredBlog = blogs.filter((blog) => blog.featured === true);
      if (featuredBlog.length > 0) {
        const blog = featuredBlog[0]; // Assuming only one featured blog
        setFeaturedName(blog.title);
        setFeaturedDate(formatDate(blog.creationTimeStamp));
        setFeaturedDescription(
          truncateDescription(blog.description, maxLength)
        );
        setFeaturedImage(blog.image);
        setFeaturedBlog(blog);
        setFeaturedAuthor(blog.author);
      }
    }
  }, []);

  useEffect(() => {
    let filteredData = data.filter(
      (blog) =>
        blog.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        blog.description.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setResultsNum(filteredData.length);
    if (!searchTerm) {
      filteredData = data.filter((blog) => !blog.featured);
    }
    const startIndex = (currentPage - 1) * PageSize;
    const paginatedData = filteredData.slice(startIndex, startIndex + PageSize);
    setPagaData(paginatedData);
  }, [data, searchTerm, currentPage]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };
  const sortBlogsByDateDesc = (blogs) => {
    return blogs.sort(
      (a, b) => new Date(b.creationTimeStamp) - new Date(a.creationTimeStamp)
    );
  };
  const truncateDescription = (description, maxLength) => {
    if (description.length <= maxLength) return description;
    return description.substring(0, maxLength - 3) + '...';
  };
  const redirectToBlog = (blog) => {
    const { blogId, title } = blog;
    const encodedTitle = encodeURIComponent(title);
    navigate(`/blog-details/${encodedTitle}-${blogId}`);
  };

  const fetchBlogs = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(process.env.REACT_APP_GET_BLOGS);
      if (response.ok) {
        let data = await response.json();
        setData(
          sortBlogsByDateDesc(data.filter((blog) => !blog.featured)) || []
        );
        setCurrentPage(1);
        const featuredBlog = data.filter((blog) => blog.featured === true);
        if (featuredBlog.length > 0) {
          const blog = featuredBlog[0]; // Assuming only one featured blog
          setFeaturedName(blog.title);
          setFeaturedDate(formatDate(blog.creationTimeStamp));
          setFeaturedDescription(
            truncateDescription(blog.description, maxLength)
          );
          setFeaturedImage(blog.image);
          setFeaturedBlog(blog);
          setFeaturedAuthor(blog.author);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  return (
    <div className=" background-1 w-full flex-col">
      {isLoading ? (
        <div className="w-full h-screen px-40 mobile-padding">
          <Spinner />
        </div>
      ) : (
        <div className="p-screen mobile-p flex-col h-full">
          <div className="flex justify-center items-center w-full flex-col mb-10">
            <h2 className="text-center w-full font-bold mb-4">
              {' '}
              Look for the right article
            </h2>
            {/* <div className="flex flex-row mb-4 justify-evenly w-full">
              <span className="">CAREERS </span>
              <span className="">INTERVIEWS</span>
              <span className="">RESUME & COVER LETTERS</span>
              <span className=" mobile-hidden">INTERNSHIPS </span>
              <span className="4 mobile-hidden">JOBS</span>
            </div> */}
            <div className="mb-4 mx-4 w-400 flex-row flex ">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="w-full outline-none rounded-lg border-grey"
                style={{
                  backgroundImage: `url(${SearchLogo})`,
                  backgroundPosition: '8px center',
                  backgroundRepeat: 'no-repeat',
                  padding: '12px',
                  paddingLeft: '40px',
                }}
              />
            </div>
          </div>
          {searchTerm && pageData.length > 0 && (
            <h1 className="w-full text-center mobile-header mb-5">
              {' '}
              {resultsNum} search results found for "{searchTerm}"
            </h1>
          )}
          {!searchTerm && featuredBlog !== null ? (
            <div className="flex-row mobile-flex bg-white rounded-2xl mb-10 mobile-card text">
              <div className="w-full p-60 mobile-post ">
                <img
                  src={featuredImage}
                  alt="featured-img"
                  className="w-full rounded-xl h-full"
                />
              </div>
              <div className="w-full flex-col justify-center p-60 section-p mobile-desc">
                <h1 className="font-bold mobile-header">{featuredName}</h1>
                <h4 className="text-blue font-bold" style={{paddingTop: '10px', paddingBottom: '10px'}}>
                  Written by Team Intern Guys
                </h4>
                <p className="font-bold"> Published {featuredDate}</p>
                <p
                  className="featured-desc"
                  dangerouslySetInnerHTML={{ __html: featuredDescription }}
                />
                <button
                  className="read-more-button mobile-button"
                  onClick={() => redirectToBlog(featuredBlog)}
                >
                  {' '}
                  READ MORE
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="flex-row justify-between w-full flex-wrap">
            {pageData.length > 0 ? (
              pageData.map((blog, index) => (
                <Blogcard
                  key={index}
                  author={'Team Intern Guys'}
                  title={blog.title}
                  date={formatDate(blog.creationTimeStamp)}
                  description={truncateDescription(blog.description, maxLength)}
                  image={blog.image}
                  onClick={() => redirectToBlog(blog)}
                />
              ))
            ) : searchTerm.length > 0 ? (
              <h1 className="w-full text-center mobile-header mb-10">
                No search results found for "{searchTerm}"
              </h1>
            ) : (
              <p className="w-full text-center text-2xl font-bold">
                No posts available.
              </p>
            )}
          </div>
          {data.length > PageSize && pageData.length > 0 && (
            <Pagination
              items={data.filter(
                (blog) =>
                  blog.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  blog.description
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
              )}
              pageLimit={PageSize}
              setPageItems={setPagaData}
            />
          )}
        </div>
      )}
      <Footer />
    </div>
  );
};
export default Blog;
