import React from "react";
import Clock from "../../images/video-timer.png";
import Box from '@mui/material/Box';
import Modal from "@material-ui/core/Modal";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import 'swiper/swiper-bundle.css';
import 'swiper/css/pagination';
import SwiperCore from 'swiper';
SwiperCore.use([Pagination]);

const InterviewsSwiper = ({ interviews, formatTime, handleViewFeedback, setSelectedInterview, setOpenJobDescription, selectedInterview, openJobDescription }) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: "70vw",
        height: "80vh",
        transform: 'translate(-50%, -50%)',
        bgcolor: '#EBF3EF',
        boxShadow: 24,
        textAlign: 'center',
        overflow: "scroll",
        p: 4,
    };
    return (
        <Swiper
            spaceBetween={10}
            slidesPerView={1}
            modules={[Pagination]}
            pagination={{ clickable: true }}
            style={{ maxWidth: window.innerWidth *0.8 , margin: 'auto', paddingBottom: '70px' }}
        >
            {interviews.map((interview, index) => (
                <SwiperSlide style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <div className="interview-card-mobile">
                        <div key={index} className="interview-card-info"> {interview.position} </div>
                        <div className="interview-card-info">{interview.companyName}</div> <br />
                        <div className="interview-clock-styling">{formatTime(interview.creationTimeStamp)}</div>
                        <div><img src={Clock} className="interview-clock-text-styling" /> {interview.interviewTime}</div>
                        <br /> <br />
                        <button className="inputButton-1 button-mobile" onClick={() => handleViewFeedback(interview)}> View Feedback Report </button>
                        <br /> <br />
                        <button className="inputButton-2 button-mobile"
                            onClick={() => {
                                setSelectedInterview(interview);
                                setOpenJobDescription(true);
                            }}>
                            View Job Description
                        </button>

                        <Modal onClose={() => setOpenJobDescription(false)} open={openJobDescription}>
                            <Box sx={style}>
                                <button className="close-button" onClick={() => setOpenJobDescription(false)}>X</button>
                                {selectedInterview && (
                                    <>
                                        <h4 className="interview-modal-position">{selectedInterview.position} @ {selectedInterview.companyName}</h4>
                                        <h4 className="interview-modal-jd">Job Description: </h4>
                                        <h4 className="interview-modal-jd-text">{selectedInterview.jobDescription}</h4>
                                    </>
                                )}
                            </Box>
                        </Modal>
                    </div>

                </SwiperSlide>)
            )}
        </Swiper>
    )
}

export default InterviewsSwiper; 