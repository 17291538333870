import React, { useEffect } from 'react';
import "./styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";

const ResetPasswordComplete = () => {
    useEffect(() => {
        const currentPathname = window.location.pathname;
        window.history.pushState({ path: currentPathname }, '', currentPathname);
      }, [])

  return (
    <div className="password-reset-complete-div">
        <FontAwesomeIcon icon={faCheckCircle} color='green' size='4x' className="password-reset-complete-checkmark" />
        <h1>Password reset!</h1>
        <p>Your password has been reset successfully.</p>
        <div
            className="d-flex return-login-btn-div">
            <button className="forgot-pass_reset-btn">
                <Link to="/login" className="forgot-pass_reset-btn-text">Return to Login</Link>
            </button>
        </div>
    </div>
  )
}

export default ResetPasswordComplete