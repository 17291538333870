import $ from "jquery";
import { getToken } from "../authetication/loginState";

const getFormattedDate = (date) => {
  const result = new Date(date);
  const month = result.getMonth() + 1;
  const numDate = result.getDate();
  const fullYear = result.getFullYear();
  return `${month}/${numDate}/${fullYear}`;
};

const getFormDate = (date) => {
  if (date == null){
    return "";
  }

  const result = new Date(date);
  const month = result.getMonth() + 1;
  const numDate = result.getDate();
  const fullYear = result.getFullYear();
  return `${fullYear}-${String(month).padStart(2, "0")}-${String(
    numDate
  ).padStart(2, "0")}`;
};

const getToday = () => new Date().toISOString();

const generateUID = () => {
  let dt = new Date().getTime();
  let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    let r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

const ajaxSetup = () => {
  $.ajaxSetup({
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  });
};

export { getFormattedDate, getFormDate, generateUID, getToday, ajaxSetup };
