import React, { useState, useEffect } from "react";
import TextArea from "../Input/TextArea";
import Input from "./../Input/Input";
import $ from "jquery";
import {
  generateUID,
  getToday,
  ajaxSetup,
} from "../../helper/helper";
import "./Forms.css";

function ProjectForm({ resumeId, handlePagination }) {
  const addProjectEndpoint = process.env.REACT_APP_ADD_PROJECT;
  const getProjectEndpoint = process.env.REACT_APP_GET_PROJECT;
  const editProjectEndpoint = process.env.REACT_APP_EDIT_PROJECT;
  const deleteProjectEndpoint = process.env.REACT_APP_DELETE_PROJECT;
  const addProjectToolEndpoint = process.env.REACT_APP_ADD_PROJECT_TOOL;
  const editProjectToolEndpoint = process.env.REACT_APP_EDIT_PROJECT_TOOL;
  const deleteProjectToolEndpoint = process.env.REACT_APP_DELETE_PROJECT_TOOL;
  const [projectForms, setProjectForms] = useState([]);
  const [indx, setIndx] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [nextLoad, setNextLoad] = useState(false);
  const [added,setAdded] = useState(false);

  const [projectNameError,setProjectNameError] = useState("");
  const [projectDescError,setProjectDescError] = useState("");
  let validation = true;

  const singleTool = (projectTools, projectsFieldId) => {
    let placeholders = ["Java", "Python", "C", "JavaScript", "Ruby"];
    let result = [];
    let len = 0;
    projectTools &&
      projectTools
        .sort((x, y) => x.ordinal - y.ordinal)
        .forEach((tool) => {
          result.push(
            <Input
            key={`${projectsFieldId}-${len}`}
              type="text"
              placeholder={placeholders[len]}
              inputStyle="subForm"
              value={tool.toolName}
              parentIdName={projectsFieldId}
              idName={tool.projectToolId}
              toolCreated={tool.creationTimeStamp}
              toolActive={tool.active}
              toolOrdinal={tool.ordinal}
            />
          );
          len += 1;
        });
    for (; len < 5; len++) {
      result.push(
        <Input
        key={`${projectsFieldId}-${len}`}
          type="text"
          placeholder={placeholders[len]}
          inputStyle="subForm"
          value={""}
          parentIdName={projectsFieldId}
        />
      );
    }
    return result;
  };

  const singleForm = (
    ordinal = -1,
    id = null,
    uid = generateUID(),
    active = true,
    creationTimeStamp = getToday(),
    modifiedTimeStamp = getToday(),
    projectName = "",
    link = "",
    projectTools = null,
    description = ""
  ) => {
    return (
      <>
        <form className="form form__mainWithTool" indx={indx} id={id}>
          <input
            className="form__noDisplay"
            name="ordinal"
            value={ordinal}
            tabIndex={-1}
            readOnly
          />
          <input
            className="form__noDisplay"
            name="uid"
            value={uid}
            tabIndex={-1}
            readOnly
          />
          <input
            className="form__noDisplay"
            name="creationTimeStamp"
            value={creationTimeStamp}
            tabIndex={-1}
            readOnly
          />
          <input
            className="form__noDisplay"
            name="modifiedTimeStamp"
            value={modifiedTimeStamp}
            tabIndex={-1}
            readOnly
          />
          <input
            className="form__noDisplay"
            name="active"
            value={active}
            tabIndex={-1}
            readOnly
          />
          <Input
            type="text"
            title="Project Name"
            placeholder="Fabflix"
            inputStyle="form"
            name="projectName"
            value={projectName}
            headStyle
          />
          <Input
            type="text"
            title="Link"
            placeholder=""
            inputStyle="form"
            name="link"
            value={link}
          />
          <br />
          <TextArea
            type="textarea"
            title="Description"
            placeholder="description"
            value={description}
            name="projectDescription"
          />
        </form>
        <form className="form__tool">
          <h6>Tools Used</h6>
          {singleTool(projectTools, id || uid)}
        </form>
      </>
    );
  };
  const addForm = () => {
    setAdded(true);
    setProjectForms([...projectForms, singleForm(indx)]);
    setIndx(indx + 1);
  };
  const removeForm = () => {

    document.getElementById("failure").style.display = 'none';

    if(projectForms.length === 1)
    {
      setAdded(false);
    }
    let [formToPop, newFormList] = [projectForms.pop(), projectForms];
    setProjectForms([...newFormList]);
    formToPop && setIndx(indx - 1);

    if (formToPop?.props?.children[0]?.props?.id) {
      const projectFieldId = formToPop?.props?.children[0]?.props?.id;
      $.ajax(`${deleteProjectEndpoint}?id=${projectFieldId}`, {
        type: "DELETE",
      });
    }
  };

  const saveForms = () => {
    validation = true;
    setProjectDescError("");
    setProjectNameError("");

    
    let result = document.querySelectorAll(".form");
    result.forEach((form) => {
      const userInputData = {
        resumeId,
        projectName: form["projectName"]?.value,
        ordinal: parseInt(form["ordinal"]?.value),
        link: form["link"]?.value,
        projectDescription: form["projectDescription"]?.value,
      };
      
      if(form.projectName.value === "")
      {
        setProjectNameError("Please fill in the project's name");
        validation = false;
      }
      if(form.projectDescription.value === "")
      {
        setProjectDescError("Please enter a description");
        validation = false;
      }

      if(validation)
      {

      let order = 1;
      if (form.id) {
        // edit existing project
        userInputData.creationTimeStamp = form["creationTimeStamp"]?.value;
        userInputData.modifiedTimeStamp = getToday();
        userInputData.active = form["active"]?.value;
        userInputData.projectFieldId = form.id;
        $.post(
          editProjectEndpoint,
          JSON.stringify(userInputData),
          (data) => {}
        );
        let toolList = Array.from(
          document.getElementsByClassName(`${form.id}`)
        );
        toolList.forEach((tool) => {
          const toolData = {
            projectFieldId: form.id,
            toolName: tool.value,
            ordinal: order,
          };
          order += 1;
          if (tool.id) {
            //delete tool if tool.value empty
            if (tool.value) {
              //edit tool
              toolData.projectToolId = tool.id;
              toolData.creationTimeStamp = tool.getAttribute("toolcreated");
              toolData.modifiedTimeStamp = getToday();
              toolData.active = true;
              $.post(
                editProjectToolEndpoint,
                JSON.stringify(toolData),
                (data) => {}
              );
            } else {
              // delete tool
              $.ajax(`${deleteProjectToolEndpoint}?id=${tool.id}`, {
                type: "DELETE",
              });
            }
          } else {
            // add new tool
            if (tool.value) {
              $.post(
                addProjectToolEndpoint,
                JSON.stringify(toolData),
                (data) => {}
              );
            }
          }
        });
      } else {
        // add new project
        let id;
        $.post(addProjectEndpoint, JSON.stringify(userInputData), (data) => {
          id = data.projectFieldIdReturn;
          const formUID = form["uid"]?.value;
          let toolList = Array.from(document.getElementsByClassName(formUID));
          toolList.forEach((tool) => {
            if (tool?.value) {
              const localTool = {
                projectFieldId: id,
                toolName: tool?.value,
                ordinal: order,
              };
              order += 1;
              $.post(
                addProjectToolEndpoint,
                JSON.stringify(localTool),
                (innerData) => {}
              );
            }
          });
        });
      }
      document.getElementById("failure").style.display = "none";
    }
    else{
      document.getElementById("failure").style.display = 'block';
    }
    });
    if(validation)
    {
    setNextLoad(true);
    setTimeout(() => {
      handlePagination(1,"projects");
    }, 1000);
  }
  else{
    setNextLoad(false);
  }
  };

  const getProjects = () => {
    $.get(getProjectEndpoint, { resumeId }).then((data) => {
      setIndx(data.length + 1);
      setProjectForms(
        data
          .sort((x, y) => x.ordinal - y.ordinal)
          .map((dataset) => {
            const {
              active,
              creationTimeStamp,
              modifiedTimeStamp,
              link,
              ordinal,
              projectDescription,
              projectName,
              projectFieldId,
              projectTools,
            } = dataset;
            setAdded(true);
            return singleForm(
              ordinal,
              projectFieldId,
              null,
              active,
              creationTimeStamp,
              modifiedTimeStamp,
              projectName,
              link,
              projectTools,
              projectDescription
            );
          })
      );
      setLoaded(true);
    });
  };

  useEffect(() => {
    ajaxSetup();
    getProjects();
    

  }, []);
  return loaded ? (
    <>
      {nextLoad && (
        <div className="form__loader">
          <div className="form__spinner"></div>
        </div>
      )}
      <div className="projectForm">
        <h3>Your Projects</h3>

        <div id = "failure">
            <h3> {projectNameError}</h3>
            <h3> {projectDescError}</h3> 
        </div>

        {projectForms.map((component, indx) => (
          <React.Fragment key={indx}>{component}</React.Fragment>
        ))}
        <div className="form__buttons">
          <button className="form__button" onClick={() => saveForms()}>
            Save & Next
          </button>
          <button className="form__button" onClick={() => addForm()}>
            Add Project
          </button>
          <button 
          style = {added ? {display : "block"} : {display : "none"}}
          className="form__button" 
          onClick={() => removeForm()}>
            Remove Project
          </button>
        </div>
      </div>
    </>
  ) : (
    <div className="form__loader">
      <div className="form__spinner"></div>
    </div>
  );
}

export default ProjectForm;
