import React, { useState, useEffect } from "react";
import $ from "jquery";
import { getToken } from "../../authetication/loginState";
import moreProjects from "./templatePictures/MoreProjects1.jpeg";
import OG from "./templatePictures/OG-2.jpeg";
import moreSkills from "./templatePictures/MoreSkills4.jpeg";
import aintGotMuch from "./templatePictures/AintGotMuch5.jpeg";
import creative from "./templatePictures/Creative3.jpeg";
import "./viewTemplates.css";
import { useParams } from "react-router-dom";
import Images from "../../Images";

const templateText = [
  {
    id: 1,
    line1: "Want a resume that flexes your projects and experiences?",
    line2: "Then this one's for you!"
  },
  {
    id: 2,
    line1: "Want something a bit more traditional?",
    line2: "A resume that is simple yet elegant, our OG template is just for you!"
  },
  {
    id: 3,
    line1: "Creative, colorful, artistic?",
    line2: "This resume is meant just for you!"
  },
  {
    id: 4,
    line1: "Got a lot of skills? Want something that highlights them?",
    line2: "Pick this resume template!"
  },
  {
    id: 5,
    line1: "Hard to decide which template? Want something that weighs all your inputs equally while at the same time looks cool?",
    line2: "This template is just for you!"
  }
]

const chosenTemplate = {
  line1:"After intensive calculations in our labs...",
  line2:"Based on your input, we highly recommend you to go for this resume template!"
}

function ViewTemplates() {
  const [prediction, setPrediction] = useState(2);
  const [loaded, setLoaded] = useState(false);
  const param = useParams();

  const comm = () => {
    $.ajaxSetup({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    $.get(
      process.env.REACT_APP_RESUME_SUGGESTION + "?resumeId=" + param.resumeId,
      (data) => {
        setPrediction(data["prediction"]);
      }
    );
  };

  useEffect(() => {
    comm();
    setTimeout(() => setLoaded(true), 1000);
  }, []);

  const handleOnClick = (e) => {
    switch (e.target.name) {
      case "1":
        MOREPROJECT_TEMPLATE();
        break;
      case "2":
        OG_template();
        break;
      case "3":
        CREATIVE_TEMPLATE();
        break;
      case "4":
        MORESKILLS_TEMPLATE();
        break;
      case "5":
        AINT_TEMPLATE();
        break;
      default:
        OG_template();
        return;
    }
  };

  const OG_template = () => {
    var req = new XMLHttpRequest();
    req.open(
      "GET",
      process.env.REACT_APP_OG_TEMPLATE + "?resumeId=" + param.resumeId,
      true
    );
    req.responseType = "blob";
    req.setRequestHeader("Content-Type", "application/json");
    req.setRequestHeader("Accept", "application/json");
    req.setRequestHeader("Authorization", `Bearer ${getToken()}`);

    req.onload = function (event) {
      var blob = req.response;
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Resume.pdf";
      link.click();
    };
    req.send();
  };

  const CREATIVE_TEMPLATE = () => {
    var req = new XMLHttpRequest();
    req.open(
      "GET",
      process.env.REACT_APP_CREATIVE_TEMPLATE + "?resumeId=" + param.resumeId,
      true
    );
    req.responseType = "blob";
    req.setRequestHeader("Content-Type", "application/json");
    req.setRequestHeader("Accept", "application/json");
    req.setRequestHeader("Authorization", `Bearer ${getToken()}`);

    req.onload = function (event) {
      var blob = req.response;
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Resume.pdf";
      link.click();
    };
    req.send();
  };

  const AINT_TEMPLATE = () => {
    var req = new XMLHttpRequest();
    req.open(
      "GET",
      process.env.REACT_APP_AINT_TEMPLATE + "?resumeId=" + param.resumeId,
      true
    );
    req.responseType = "blob";
    req.setRequestHeader("Content-Type", "application/json");
    req.setRequestHeader("Accept", "application/json");
    req.setRequestHeader("Authorization", `Bearer ${getToken()}`);

    req.onload = function (event) {
      var blob = req.response;
      console.log(blob.size);
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Resume.pdf";
      link.click();
    };
    req.send();
  };

  const MOREPROJECT_TEMPLATE = () => {
    var req = new XMLHttpRequest();
    req.open(
      "GET",
      process.env.REACT_APP_MOREPROJECT_TEMPLATE +
        "?resumeId=" +
        param.resumeId,
      true
    );
    req.responseType = "blob";
    req.setRequestHeader("Content-Type", "application/json");
    req.setRequestHeader("Accept", "application/json");
    req.setRequestHeader("Authorization", `Bearer ${getToken()}`);

    req.onload = function (event) {
      var blob = req.response;
      console.log(blob.size);
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Resume.pdf";
      link.click();
    };
    req.send();
  };

  const MORESKILLS_TEMPLATE = () => {
    var req = new XMLHttpRequest();
    req.open(
      "GET",
      process.env.REACT_APP_MORESKILLS_TEMPLATE + "?resumeId=" + param.resumeId,
      true
    );
    req.responseType = "blob";
    req.setRequestHeader("Content-Type", "application/json");
    req.setRequestHeader("Accept", "application/json");
    req.setRequestHeader("Authorization", `Bearer ${getToken()}`);

    req.onload = function (event) {
      var blob = req.response;
      console.log(blob.size);
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Resume.pdf";
      link.click();
    };
    req.send();
  };

  let templateArray = [moreProjects, OG, creative, moreSkills, aintGotMuch];
  let templateNames = ["More Skills", "The OG", "The Creative", "More Experience", "A Bit of Everything"]
  let names = [1, 2, 3, 4, 5];
  let emptyArray = [];

  for (let i = 0; i < templateArray.length; i++) {
    if (prediction === i + 1) {
      emptyArray.push(
        <div className="viewTemplates__template" key={i+1}>
          <h3>{templateNames[i]}</h3>
          <img
            alt="template"
            className="each bright"
            name={names[i]}
            src={templateArray[i]}
          />
          <button name={names[i]} onClick={(e) => handleOnClick(e)}>
            Download Resume
          </button>
          <div className="viewTemplates__text viewTemplates__chosen">
            <p>{chosenTemplate.line1}</p>
            <p>{chosenTemplate.line2}</p>
          </div>
        </div>
      );
    }
  }

  for (let i = 0; i < templateArray.length; i++) {
    if (prediction !== i + 1) {
      emptyArray.push(
        <div className="viewTemplates__template" key={i+1}>
          <h3>{templateNames[i]}</h3>
          <img
            alt="template"
            className="each dull"
            name={names[i]}
            src={templateArray[i]}
          />
          <button name={names[i]} onClick={(e) => handleOnClick(e)}>
            Download Resume
          </button>
          <div className="viewTemplates__text">
            <p>{templateText[i].line1}</p>
            <p>{templateText[i].line2}</p>
          </div>
        </div>
      );
    }
  }

  return (
    loaded ? 
    <div
    className="landing"
    style={{
      backgroundImage: `url(${Images.BACKGROUND})`,
      backgroundRepeat: "repeat-y",
      backgroundSize: "cover",
      minHeight: "88vh",
    }}
  >
    <div className="viewTemplates__main">
      <h1>Resume Templates </h1>
      <br></br>
      <div className="viewTemplates__templates">{emptyArray}</div>
    </div> 
    </div>
    
    :
    <div className="__loader">
      <div className="__spinner"></div>
      <p className="__loaderText">Generating Results...</p>
    </div>

    
  );
}

export default ViewTemplates;
