import React, { useState, useEffect } from "react";
import $ from "jquery";
import { getToken } from "../../authetication/loginState";
import { useNavigate } from 'react-router-dom';
import "./internshipPreferenceQuestions.css";

const HandleBackend = (val) => {
    const [preferenceId, setPreferenceId] = useState(0);
    const [userId, setUserId] = useState(0);
    const [titleFieldId, setTitleFieldId] = useState("BC290F9D-E490-44F6-B1CA-D873E27E9C1A");
    let navigate = useNavigate();

    const onClickSubmit = (e) => {
        e.preventDefault();
        var sending = val.val.internshipPreference;
        sending["userId"] = userId;
        sending["internshipPreferenceId"] = preferenceId;
        console.log(sending);
        $.post(`${process.env.REACT_APP_INTERNSHIP_PREFERENCE_EDIT}`, JSON.stringify(sending), (data) => {});
         
        var i;
        var names = ["Fortune 500", "Midtier", "Start ups", "Get Me Anything!"];
        var option = [1,2,3,4];
        var sending;
        for(i = 0; i < 4; i++){
          sending = {};
          sending["internshipPreferenceId"] = preferenceId;
          if(val.val.internshipSize[names[i]] === 1){
            sending["internshipSize1"] = option[i];
            $.post(`${process.env.REACT_APP_INTERNSHIP_SIZE_ADD}`, JSON.stringify(sending), (data) => {})
          }
        }

        var i;
        for(i = 0; i < val.val.companiesInterested.length; i++){
          sending = {};
          sending["internshipPreferenceId"] = preferenceId;
          sending["industryPicked"] = val.val.companiesInterested[i].value;
          $.post(`${process.env.REACT_APP_INTERNSHIP_INDUSTRY_ADD}`, JSON.stringify(sending), (data) => {})
        }

        var i;
        var all = [];
        for(i=0;i<val.val.titleOption.length;i++){
          all.push(val.val.titleOption[i].value);
        }

        var i;
        var notThere = [];
        for(i=0; i<val.val.internshipTitle.length; i++){
          if(all.includes(val.val.internshipTitle[i].value) === false){
            sending = {};
            sending["titleFieldId"] =  titleFieldId;
            sending["title1"] = val.val.internshipTitle[i].value;
            $.post(`${process.env.REACT_APP_TITLE_ADD}`, JSON.stringify(sending), (data) => {});
            notThere.push(val.val.internshipTitle[i].value);
          }
        }

        var i;
        for(i=0;i<val.val.internshipTitle.length;i++){
          sending = {};
          sending["internshipPreferenceId"] =  preferenceId;
          sending["titlePicked"] = val.val.internshipTitle[i].value;
          $.post(`${process.env.REACT_APP_INTERNSHIP_TITLE_ADD}`, JSON.stringify(sending), (data) => {})
        }

        navigate("/resumaker");
    }

    const ajaxSetup = () => {
      $.ajaxSetup({
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
    }

    const getuserId = () =>{
      $.get(`${process.env.REACT_APP_GET_USER}`, (data) => {setUserId(data.userId)});
    }

    const getpreferenceId = () =>{
      $.get(`${process.env.REACT_APP_INTERNSHIP_PREFERENCE_GET}`, (data) => {setPreferenceId(data[0].internshipPreferenceId);});
    }

    useEffect(() => {
      ajaxSetup();
      getuserId();
      getpreferenceId();
    }, []);

    return(
        <span>
            {(preferenceId === 0 || userId === 0) && <button className = "common_button_unselected" onClick={(e)=>{onClickSubmit(e)}}>Make your resume Now!</button>}
            {preferenceId !== 0  && userId !== 0 && <button className = "common_button_selected" onClick={(e)=>{onClickSubmit(e)}}>Make your resume Now!</button>}
          </span>
    
    )
}

export default HandleBackend;