import React, { useState, useEffect } from "react";
import "./style.css"
import { setIt, getIt} from "../../views/preSignupQuestion/localStorageFunction";

const Q4 = () => {
    const [selected, setSelected] = useState([]);
    const [c1,setC1] = useState(false);
    const [c2,setC2] = useState(false);
    const [c3,setC3] = useState(false);
    const [c4,setC4] = useState(false);
    const [c5,setC5] = useState(false);
    const [c6,setC6] = useState(false);
    const [c7,setC7] = useState(false);

    const handleOnClick = (e) => {
        e.preventDefault();
        var x = selected;
        if(x.includes(e.target.name)){
            var ind = x.indexOf(e.target.name);
            x.splice(ind,1);
            if(e.target.name === "fullTime"){
                setC1(false);
            }
            if(e.target.name === "partTime"){
                setC2(false);
            }
            if(e.target.name === "contract"){
                setC3(false);
            }
            if(e.target.name === "paid"){
                setC4(false);
            }   
            if(e.target.name === "remote"){
                setC5(false);
            }
            if(e.target.name === "temporary"){
                setC6(false);
            }
            if(e.target.name === "volunteer"){
                setC7(false);
            }    
        }
        else {
            x.push(e.target.name);
            if(e.target.name === "fullTime"){
                setC1(true);
            }
            if(e.target.name === "partTime"){
                setC2(true);
            }
            if(e.target.name === "contract"){
                setC3(true);
            }
            if(e.target.name === "paid"){
                setC4(true);
            }
            if(e.target.name === "remote"){
                setC5(true);
            }
            if(e.target.name === "temporary"){
                setC6(true);
            }
            if(e.target.name === "volunteer"){
                setC7(true);
            }

        }
        setSelected(x);
        setIt(x, "q4");

    };

    const getLocal = () => {
        var x = getIt("q4");
        x = x == null ? "" : x
        var x2 = x.split(",");
        setSelected(x2);
        setC1(false);
        setC2(false);
        setC3(false);
        setC4(false);
        setC5(false);
        setC6(false);
        setC7(false);
        if(x.includes("fullTime")){
            setC1(true);
        }
        if(x.includes("partTime")){
            setC2(true);
        }
        if(x.includes("contract")){
            setC3(true);
        }
        if(x.includes("paid")){
            setC4(true);
        }
        if(x.includes("remote")){
            setC5(true);
        }
        if(x.includes("temporary")){
            setC6(true);
        }
        if(x.includes("volunteer")){
            setC7(true);
        }
    };
    
    useEffect(() => {
        getLocal();
    }, []);
    
    return(<div>
            <div className="CenterQ">
                <h5>Industry you want to work in:</h5>
                <br></br>
                {c1 === false && <button name="fullTime" className="unselected" onClick={(e)=>handleOnClick(e)}>Full-time</button>}
                {c1 === true && <button name="fullTime" className="selected" onClick={(e)=>handleOnClick(e)}>Full-time</button>}
                {c2 === true && <button name="partTime" className="selected" onClick={(e)=>handleOnClick(e)}>Part-time</button>}
                {c2 === false && <button name="partTime" className="unselected" onClick={(e)=>handleOnClick(e)}>Part-time</button>}
                {c3 === true &&  <button name="contract" className="selected" onClick={(e)=>handleOnClick(e)}>Contract</button>}
                {c3 === false && <button name="contract" className="unselected" onClick={(e)=>handleOnClick(e)}>Contract</button>}
                {c4 === false && <button name="paid" className="unselected" onClick={(e)=>handleOnClick(e)}>Paid</button>}
                {c4 === true && <button name="paid" className="selected" onClick={(e)=>handleOnClick(e)}>Paid</button>}
                <br></br>
                {c5 === true && <button name="remote" className="selected" onClick={(e)=>handleOnClick(e)}>Remote</button>}
                {c5 === false && <button name="remote" className="unselected" onClick={(e)=>handleOnClick(e)}>Remote</button>}
                {c6 === true &&  <button name="temporary" className="selected" onClick={(e)=>handleOnClick(e)}>Temporary</button>}
                {c6 === false && <button name="temporary" className="unselected" onClick={(e)=>handleOnClick(e)}>Temporary</button>}
                {c7 === false && <button name="volunteer" className="unselected" onClick={(e)=>handleOnClick(e)}>Volunteer</button>}
                {c7 === true && <button name="volunteer" className="selected" onClick={(e)=>handleOnClick(e)}>Volunteer</button>}  
        
            </div>
            </div>
            );
};

export default Q4;