import React, { useEffect } from "react";
import usePagination from "./usePagination.js";
import "./Pagination.css";

const Pagination = (props) => {
  const { pageNumber, changePage, pageData, nextPage, previousPage } =
    usePagination(props.items, props.pageLimit);
  useEffect(() => {
    props.setPageItems(pageData);
  }, [pageNumber, props.items.length]);
  const renderPageNumbers = () => {
    const totalPages = Math.ceil(props.items.length / props.pageLimit);
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <span
        className="pagination-item"
          key={i}
          onClick={() => changePage(i)}
          style={{
            cursor: "pointer",
            fontWeight: i === pageNumber ? "bold" : "normal",
          }}
        >
          {i}
        </span>
      );
    }
    return pages;
  };
  return (
    <div className="pagination-container">
      <span onClick={previousPage}>Prev</span>
      {renderPageNumbers()}
      <span onClick={nextPage}>Next</span>
    </div>
  );
};
export default Pagination;
