function setIt(val, question) {
    window.localStorage.setItem(question, val);
  }

  function removeIt(question) {
    window.localStorage.removeItem(question);
  }
  
  function getIt(question) {
    return window.localStorage.getItem(question);
  }
  
  export { setIt, removeIt, getIt};