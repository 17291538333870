import React from "react";
import NoraForm from "../../components/NoraForm";
import "./style.css";
import { useLocation } from "react-router-dom";


const MockInterviewerZoom = () => {
  return (
    <div className="mock-interviewer-zoom">
        <NoraForm/>
    </div>
  );
};

export default MockInterviewerZoom;
