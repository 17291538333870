import $ from "jquery";
import { getToken } from "../../authetication/loginState";
import React, { useState, StyleSheet } from "react";

const TempCompany = () => {
    const [companyId, setCompanyId] = useState("11dc4e5f-e891-42ca-8884-41eb63fe38d6");
    const [internshipId, setInternshipId] = useState(0);
    const [requirementId, setRequirementId] = useState(0);
    const [skillId, setSkillId] = useState(0);
    const [RequirmentLanguageId, setRequirementLanguageId] = useState(0);
    const [fulltime, setFulltime] = useState(0);
    const [parttime, setParttime] = useState(0);
    const [contract, setContract] = useState(0);
    const [paid, setPaid] = useState(0);
    const [remote, setRemote] = useState(0);
    const [temporary, setTemporary] = useState(0);
    const [volunteer, setVolunteer] = useState(0);
    const [internshipTitle, setInternshiptTitle] = useState("");
    const [companySize, setCompanySize] = useState("");
    const [internshipType, setInternshipType] =useState("");
    const [internshipCity, setInternshipCity] =useState("");
    const [internshipState, setInternshipState] =useState("");
    const [language, setLanguage] =useState("");
    const [gender, setGender] =useState(0);
    const [disabilityStatus, setDisabilityStatus] =useState(0);
    const [veteranStatus, setVeteranStatus] =useState(0);
    const [ethnicity, setEthnicity] =useState(0);
    const [minEducation, setMinEducation] =useState(0);
    const [yoe, setYoe] =useState("");
    const [skills, setSkills] =useState("");
    const [gpa, setGpa] =useState(0);
    const [usa, setUsa] =useState("");
    const [dl, setDl] =useState(0);

    const handleFulltime = () => {if(fulltime === 0){setFulltime(1)} else{setFulltime(0)}};
    const handlePartime = () => {if(parttime === 0){setParttime(1)} else{setParttime(0)}};
    const handleContract = () => {if(contract === 0){setContract(1)} else{setContract(0)}};
    const handlePaid = () => {if(paid === 0){setPaid(1)} else{setPaid(0)}};
    const handleRemote = () => {if(remote === 0){setRemote(1)} else{setRemote(0)}};
    const handleTemporary = () => {if(temporary === 0){setTemporary(1)} else{setTemporary(0)}};
    const handleVolunteer = () => {if(volunteer === 0){setVolunteer(1)} else{setVolunteer(0)}};
    const handleIntershipTitle = (x) => {setInternshiptTitle(x)};
    const handleCompanySize = (x) => {setCompanySize(x)};
    const handleIntershipType = (x) => {setInternshipType(x)};
    const handleIntershipCity = (x) => {setInternshipCity(x)};
    const handleInternshipState = (x) => {setInternshipState(x)};
    const handleLanguage = (x) => {setLanguage(x)};
    const handleGender = (x) =>{setGender(parseInt(x))};
    const handleDisabilityStatus = (x) =>{setDisabilityStatus(parseInt(x))};
    const handleEthinicity = (x) =>{setEthnicity(parseInt(x))};
    const handleVeteranStatus = (x) =>{setVeteranStatus(parseInt(x))};
    const handleMinEducation = (x) =>{setMinEducation(parseInt(x))};
    const handleUsa = () => {if(usa === 0){setUsa(1)} else{setUsa(0)}};
    const handleDrivingLicence = () => {if(dl === 0){setDl(1)} else{setDl(0)}};
    const handleYoe = (x) =>{setYoe(parseInt(x))};
    const handleGpa = (x) =>{setGpa(x)};
    const handleSkills = (x) => {setSkills(x)};

    const handleSending1 = (e) => {
        e.preventDefault();
        $.ajaxSetup({
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        var sending = {};
        sending["CompanyId"] = companyId;
        sending["InternshipTitle"] = internshipTitle;
        sending["InternshipType"] = internshipType;
        sending["InternshipDescription"] = "Hello";
        sending["InternshipDuties"] = "Hello1";
        sending["Paid"] = paid;
        sending["Remote"] = remote;
        sending["Volunteer"] = volunteer;
        sending["Temporary"] = temporary;
        sending["Contract"] = contract;
        sending["PartTime"] = parttime;
        sending["FullTime"] = fulltime;
        sending["InternshipCity"] = internshipCity;
        sending["InternshipState"] = internshipState;

        $.post(`${process.env.REACT_APP_INTERNSHIP_ADD}`, JSON.stringify(sending), (data) => {setInternshipId(data.internshipIdReturn)})
    }

    const handleSending2 = (e) => {
      e.preventDefault();
      $.ajaxSetup({
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      var sending = {};
      sending["CompanyId"] = companyId;
      sending["Resume"] = 0;
      sending["InternshipId"] = internshipId;
      sending["VeteranStatus"] = veteranStatus;
      sending["YearsOfExperience"] = yoe;
      sending["Ethnicity"] = ethnicity;
      sending["DisabilityStatus"] = disabilityStatus;
      sending["USWorkAuthorization"] = usa;
      sending["MinimumEducation"] = minEducation;
      sending["Gender"] = gender;
      sending["InternshipType"] = internshipType;
      sending["GPA"] = gpa;
      sending["City"] = internshipCity;
      sending["State"] = internshipState;
      sending["DrivingLicense"] = dl;
      sending["UrgentHire"] = 0;   

      $.post(`${process.env.REACT_APP_REQUIREMENTS_ADD}`, JSON.stringify(sending), (data) => {setRequirementId(data.requirementsIdReturn)})
  }
    
  const handleSending3 = (e) => {
    e.preventDefault();
    $.ajaxSetup({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    var sending = {};
    sending["RequirementsId"] = requirementId;
    sending["Language"] = language;
      
    $.post(`${process.env.REACT_APP_REQUIREMENTSLANGAUGES_ADD}`, JSON.stringify(sending), (data) => {setRequirementLanguageId(data.requirementsLanguageIdReturn)})
}

const handleSending4 = (e) => {
  e.preventDefault();
  $.ajaxSetup({
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  });
  var sending = {};
  sending["RequirementsId"] = requirementId;
  sending["Skill"] = skills;  

  $.post(`${process.env.REACT_APP_REQUIREMENTSSKILLS_ADD}`, JSON.stringify(sending), (data) => {setSkillId(data.requirementsSkillsIdReturn)})
}

    return(
        <div>
            <button onClick={()=>handleFulltime()}>Select Fulltime</button><p>{fulltime}</p>
            <button onClick={()=>handlePartime()}>Select Partime</button><p>{parttime}</p>
            <button onClick={()=>handleContract()}>Select Contract</button><p>{contract}</p>
            <button onClick={()=>handlePaid()}>Select Paid</button><p>{paid}</p>
            <button onClick={()=>handleRemote()}>Select Remote</button><p>{remote}</p>
            <button onClick={()=>handleTemporary()}>Select Temporary</button><p>{temporary}</p>
            <button onClick={()=>handleVolunteer()}>Select Volunteer</button><p>{volunteer}</p>
            <input placeholder="internshipTitle" value={internshipTitle} onChange={(e)=>handleIntershipTitle(e.target.value)}></input>
            <br></br>
            <br></br>
            <select placeholder="companySize" onChange={(e)=>handleCompanySize(e.target.value)} id="CompanySize" form="CompanySize">
                <option value="Fortune 500">Fortune 500</option>
                <option value="Midtier">Midtier</option>
                <option value="Start ups">Start Ups</option>
            </select>
            <p>{companySize}</p>
            <input placeholder="internshipType integer" value={internshipType} onChange={(e)=>handleIntershipType(parseInt(e.target.value))}></input>
            <p>{internshipType}</p>
            <br></br>
            <input placeholder="internshipCity" value={internshipCity} onChange={(e)=>handleIntershipCity(e.target.value)}></input>
            <p>{internshipCity}</p>
            <br></br>
            <input placeholder="internshipState" value={internshipState} onChange={(e)=>handleInternshipState(e.target.value)}></input>
            <p>{internshipState}</p>
            <br></br>
            <input placeholder="Language" value={language} onChange={(e)=>handleLanguage(e.target.value)}></input>
            <p>{language}</p>
            <br></br>
          
            <h6>Gender:</h6>
            <select
              value={gender}
              name="gender"
              onChange={(e) =>
                handleGender(e.target.value)
              }
            >
              <option value="0" 
              disabled="gender">
                -- select one --
              </option>
              <option value="1">Male</option>
              <option value="2">Female </option>
              <option value="3">Other </option>
            </select>
            <br></br>
            <br></br>
            <p>{gender}</p>

            <h6>Disability Status:</h6>
            <select
              value={disabilityStatus}
              name="disabilityStatus"
              onChange={(e) =>
                    handleDisabilityStatus(e.target.value)
              }
            >
              <option
                value="-1"
                disabled="Disability Status"
              >
                -- select one --
              </option>
              <option value="0">I have a disability</option>
              <option value="1">I do NOT have a disability</option>
              <option value="2">Prefer Not to Disclose</option>
            </select>
            <br></br>
            <br></br>
            <p>{disabilityStatus}</p>

            <label>Veteran Status:</label>
            <br></br>
            <select
              value={veteranStatus}
              name="veteranStatus"
              onChange={(e) =>
               handleVeteranStatus(e.target.value)
              }
            >
              <option value="0" 
              disabled="Veteran Status">
                -- select one --
              </option>
              <option value="1">I am a protected veteran</option>
              <option value="2">I am NOT a protected veteran</option>
              <option value="3">Prefer Not to Disclose</option>
            </select>
            <br></br>
            <br></br>
            <p>{veteranStatus}</p>

            <label>Ethnicity:</label>
            <br></br>
            <select
              value={ethnicity}
              name="ethnicity"
              onChange={(e) =>
                handleEthinicity(e.target.value)
              }
            >
              <option value="0" 
              disabled="ethnicity">
                -- select one --
              </option>
              <option value="1">
                American Indian/Alaskan (Not hispanic or Latino)
              </option>
              <option value="2">Asian </option>
              <option value="3">Black </option>
              <option value="4">Hawaiian/Pacific Islander </option>
              <option value="5">Hispanic or Latino</option>
              <option value="6">Prefer Not to Disclose</option>
              <option value="7">Two or More</option>
              <option value="8">White</option>
            </select>
            <br></br>
            <br></br>
            <p>{ethnicity}</p>

            <h6>Min Education:</h6>
            <select
              value={minEducation}
              name="maxEducation"
              onChange={(e) =>
                handleMinEducation(e.target.value)
              }
            >
              <option value="-1" 
              disabled="Veteran Status">
                -- select one --
              </option>
              <option value="0">No Education</option>
              <option value="1">High School</option>
              <option value="2">Associate Degree</option>
              <option value="3">Bachelor Degree</option>
              <option value="4">Master Degree</option>
              <option value="5">PhD</option>
            </select>
            <br></br>
            <br></br>
            <p>{minEducation}</p>

            <button onClick={()=>handleUsa()}>Select US Work Authorization</button><p>{usa}</p>

            <button onClick={()=>handleDrivingLicence()}>Select Driving Liscence</button><p>{dl}</p>

            <input value={yoe} onChange={(e)=>handleYoe(e.target.value)} placeholder="years of expeirence"></input><p>{yoe}</p>

            <input  onChange={(e)=>handleGpa(e.target.value)} placeholder="gpa"></input><p>{gpa}</p>

            <input value={skills} onChange={(e)=>handleSkills(e.target.value)} placeholder="skills"></input><p>{skills}</p>

            <br></br>
            <button onClick={(e)=>handleSending1(e)}>submit 1 Internship</button>
            <button onClick={(e)=>handleSending2(e)}>submit 2 Requirements</button>
            <button onClick={(e)=>handleSending3(e)}>submit 3 RequirmentLanguage</button>
            <button onClick={(e)=>handleSending4(e)}>submit 4 Requirement Skill</button>
            <br></br>
          
            <p>{companyId}</p>
            <br></br>
            <p>{internshipId}</p>
            <br></br>
            <p>{requirementId}</p>
            <br></br>
            <p>{skillId}</p>
            <br></br>
            <p>{RequirmentLanguageId}</p>
        </div>
    );
}

export default TempCompany;