import React from "react";
import "./style.css"

function Spinner()
{
    return (
        <div className="loader-relative">
            <div className="loader2-relative"></div>
        </div>
    );
}

export default Spinner;