import $ from "jquery";
import { getToken } from "../../authetication/loginState";
import React from "react";
import "./ResumeAsking.css"
import Images from "../../Images";

$.ajaxSetup({
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  });

const ResumeAsking = () => 
{
    return (
        <div>
          <br></br>
            <h4 className="mainText">Let's Get your Resume! Select one of the below.</h4>
            <br></br>
            <div className = "left">
              <div className="verticleCenter">
                <img className="image" src={Images.UPLOAD} onClick={event =>  window.location.href='/UploadResume'}/>
                <h5 className="text2" onClick={event =>  window.location.href='/UploadResume'}>Upload a Resume</h5>
              </div>
            </div>
            <br></br>
            <div className = "right">
                <div className="verticleCenter">
                  <img className="image2" src={Images.CREATE} onClick={event =>  window.location.href='/resumaker'}/> 
                  <h5 className="text" onClick={event =>  window.location.href='/UploadResume'}>Create a new Resume</h5>
                </div>
            </div>
        </div>
      );
};

export default ResumeAsking;