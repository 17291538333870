import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./NavItem.css";

function NavItem({ name, link, DropdownIcon, noDisplaySmall }) {
  const { hash, pathname } = useLocation();
  let navigate = useNavigate();

  const scrollIntoView = async (elem) => {
    if (!elem) {
      await navigate("/");;
      elem.scrollIntoView({ block: "start", behavior: "smooth" });
    } else {
      elem.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (hash) {
      const el = document.querySelector(hash);
      scrollIntoView(el);
    }
  }, [hash]);

  const getNavItemClass = () => {
    return pathname === '/' ? 'navItem home' : 'navItem other';
  };

  return (
    <Link
      className={`${getNavItemClass()} ${noDisplaySmall ? "navItem__noDisplaySmall" : ""} ${
        name === "Contact" || name === "User" || name === "Login"
          ? "border__bottom"
          : ""
      }`}
      to={link}
    >
      {name}
      <span >{DropdownIcon}</span>
    </Link>
  );
}

export default NavItem;
