import React, { useState } from "react";

function TextArea({ title, name, value }) {
  const [userInput, setUserInput] = useState(value);
  return (
    <>
      {title && <p>{title}</p>}
      <textarea
        name={name}
        className="textArea"
        value={userInput || ""}
        onChange={(e) => setUserInput(e.target.value)}
      ></textarea>
    </>
  );
}

export default TextArea;
