const Images = {
  FACEBOOK: '/images/logo/facebook-white.png',
  TWITTER: '/images/logo/twitter-white.png',
  IG_LOGO: '/images/logo/ig-logo.png',
  IG_LOGO2: '/images/logo/ig-logo2.png',
  LINKEDIN: '/images/logo/linkedInLogo.png',
  LINKEDIN_COLOR: '/images/logo/linkedin-color.png',
  INSTAGRAM: '/images/logo/instagramLogo.png',
  DASHBOARD_WEB_STAT: '/images/top-image.png',
  STEP_1: '/images/step1.png',
  STEP_2: '/images/step2.png',
  STEP_3: '/images/step3.png',
  STEP_4: '/images/step4.png',
  EYE_OPEN:'/hideShowIcon/eyeOpen.png',
  EYE_CLOSED:'/hideShowIcon/eyeClosed.png',
  EYE_OPEN_WHITE:'/hideShowIcon/eyeOpen_White.png',
  EYE_CLOSED_WHITE:'/hideShowIcon/eyeClosed_White.png',
  CHEVRON_DOWN: '/images/chevron-down.svg',
  CREATE: '/images/ResumeAsking/CreateDocument.png',
  UPLOAD: '/images/ResumeAsking/upload.png',
  CHECK_BULLET: '/images/search/check.svg',
  CHANCE_BULLET: '/images/search/chance.svg',
  FILTER: '/images/search/filter.svg',
  BACKGROUND: '/images/background.jpg',
  FACEBOOK_FOOTER: '/images/footer/facebook.svg',
  LINKEDIN_FOOTER: '/images/footer/linkedin.svg',
  INSTAGRAM_FOOTER: '/images/footer/instagram.svg',
  TIKTOK_FOOTER: '/images/footer/tiktok.svg',
  WEBSITE_FOOTER: '/images/footer/website.svg',
  INDUSTRY_FOOTER: '/images/footer/industry.svg',
  SIZE_FOOTER: '/images/footer/employee-number.svg',
  TYPE_FOOTER: '/images/footer/company-type.svg',
};

export default Images;
