import TESLA from '../../images/homepage/tesla.png';
import AMAZON from '../../images/homepage/amazon.png';
import APPLE from '../../images/homepage/apple.png';
import CNBC from '../../images/homepage/cnbc.png';
import GOLDMANSACHS from '../../images/homepage/goldmansachs.png';
import GOOGLE from '../../images/homepage/google.png';
import NVIDIA from '../../images/homepage/nvidia.png';
import STRIPE from '../../images/homepage/stripe.png';
import TIKTOK from '../../images/homepage/tiktok.png';
import './style.css';

const CompanyCards = () => {
    const companies = [
      { logo: NVIDIA, position: 'Data Scientist Intern', company: 'Nvidia' },
      { logo: AMAZON, position: 'Product Designer', company: 'Amazon' },
      { logo: GOLDMANSACHS, position: 'Investment Banker', company: 'Goldman Sachs' },
      { logo: CNBC, position: 'Marketing Associate', company: 'CNBC' },
      { logo: TESLA, position: 'Mechanical Engineer', company: 'Tesla' },
      { logo: TIKTOK, position: 'Product Manager, New Grad', company: 'TikTok' },
      { logo: STRIPE, position: 'Sales Dev', company: 'Stripe' },
      { logo: APPLE, position: 'ML Intern', company: 'Apple' },
      { logo: GOOGLE, position: 'Software Engineer, Internship', company: 'Google' },
    ];
  
    return (
      <div className="company-card-grid">
        {companies.map((company, index) => (
          <div className="company-card" key={index}>
            <div className="card-top">
              <img src={company.logo} alt={`${company.company} Logo`} className="company-logo" />
            </div>
            <div className="card-content">
              <h3>{company.position}</h3>
              <a href="/login">
                <button className="card-button">Start Interview</button>
              </a>
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  export default CompanyCards;