import React, { useState } from "react";
import "./Input.css";
function Input({
  type,
  title,
  placeholder,
  inputStyle,
  headStyle,
  tool,
  name,
  value,
  dataset,
  idName,
  parentIdName,
  toolOrdinal,
  toolCreated,
}) {

  const getTools = (dataset) => {
    let result = [];
    dataset &&
      dataset.forEach((data) => {
        console.log(data);
        result.push(
          <input
            type={type}
            placeholder={placeholder}
            className={`input__${inputStyle} input__singleTool`}
            value={data?.name}
            onChange={(e) => e.target.value}
          />
        );
      });
    return result;
  };
  const singleTool = (parentIdName, idName) => {
    let toolValue = "";
    return (
      <input
        type={type}
        placeholder={placeholder}
        className={`input__${inputStyle} input__singleTool`}
        toolordinal={toolOrdinal}
        toolcreated={toolCreated}
        value={toolValue}
        onChange={(e) => {
          toolValue += e.target.value;
          console.log(e.target.value, toolValue);
        }}
      />
    );
  };
  const [userInput, setUserInput] = useState(value);
  const [toolList, setToolList] = useState([getTools(dataset)]);
  const [toolCount, setToolCount] = useState(toolList.length);
  const addTool = (evt) => {
    evt.preventDefault();
    setToolList([...toolList, singleTool()]);
    setToolCount(toolCount + 1);
  };
  const removeTool = (evt) => {
    evt.preventDefault();
    let [formToPop, newToolList] = [toolList.pop(), toolList];
    setToolList([...newToolList]);
    formToPop && setToolCount(toolCount - 1);
  };
  return (
    <div className={headStyle && "input"}>
      {title && <p>{title}</p>}
      {!tool ? (
        <input
          type={type}
          placeholder={placeholder}
          className={`input__${inputStyle} ${parentIdName}`}
          id={idName}
          name={name}
          value={userInput || ""}
          onChange={(e) => setUserInput(e.target.value)}
          toolordinal={toolOrdinal}
          toolcreated={toolCreated}
        />
      ) : (
        <div className="input__tool">
          {toolList.map((component, indx) => (
            <React.Fragment key={indx}>{component}</React.Fragment>
          ))}
          <div className="input__toolButtons">
            <button onClick={(evt) => addTool(evt)}>+</button>
            <button
              onClick={(evt) => removeTool(evt)}
              disabled={toolList.length <= 1}
            >
              -
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Input;
