import React from "react";
import "./internshipPreferenceQuestions.css";
import InternshipPreference from "../../components/internshipSearchQuestions/internshipPreference";
import InternshipSize from "../../components/internshipSearchQuestions/internshipSize";
import InternshipTitle from "../../components/internshipSearchQuestions/internshipTitle";
import CompaniesInterested from "../../components/internshipSearchQuestions/companiesInterested";
import HandleBackend from "./handleBackend";
import Images from "../../Images";
class InternshipPreferenceQuestions extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            internshipPreference : {"fullTime" : 0, "partTime" : 0, "contract" : 0, 
                                    "paid" : 0, "remote" : 0, "temporary" : 0, "volunteer" : 0 },
            internshipTitle : [],
            titleOption : this.props.data,
            internshipSize : {"Fortune 500" : 0, "Midtier" : 0, "Start ups" : 0, 
                            "Get Me Anything!" : 0},
            companiesInterested : [],
            pageSelected : "internshipPreference",   
            activeTitle: false, 
            activeSize: false, 
            activecompaniesInterested: false
        };
    };

    selectPageContinue = () => {
        if(this.state.pageSelected === "internshipPreference"){
            this.setState({pageSelected : "internshipTitle", activeTitle: true})
        }
        if(this.state.pageSelected === "internshipTitle"){
            this.setState({pageSelected : "internshipSize", activeSize: true})
        }
        if(this.state.pageSelected === "internshipSize"){
            this.setState({pageSelected : "companiesInterested", activecompaniesInterested: true})
        }
        if(this.state.pageSelected === "companiesInterested"){
            this.setState({pageSelected : "ResumeBuilder"})
        }
    }

    selectPageBack = () => {
        if(this.state.pageSelected === "internshipTitle"){
            this.setState({pageSelected : "internshipPreference", activeTitle: false})
        }
        if(this.state.pageSelected === "internshipSize"){
            this.setState({pageSelected : "internshipTitle", activeSize: false})
        }
        if(this.state.pageSelected === "companiesInterested"){
            this.setState({pageSelected : "internshipSize", activecompaniesInterested: false})
        }
        if(this.state.pageSelected === "ResumeBuilder"){
            this.setState({pageSelected : "companiesInterested"})
        }
    }

    changeState = (val) => {
        this.setState(val);
    }

    render(){
        console.log(this.state.pageSelected);
        console.log(this.state.activeTitle);
        return(<div className = "common" style={{ 
            backgroundImage: `url(${Images.BACKGROUND})`,
            backgroundRepeat: 'repeat-y',
            backgroundSize: 'cover',
            width: '100vw',
            minHeight: '100vh',
            color: '#213546'}}>
                <div className="questionnaire-box">
                    <div>
                    <ul className="step-up-profile-by-step">
                        <li className="active">1</li>
                        <li className={this.state.activeTitle === true ? "active" : ""} >2</li>
                        <li className={this.state.activeSize === true  ? "active" : ""}>3</li>
                        <li className={this.state.activecompaniesInterested === true ? "active" : ""}>4</li>
                    </ul>
                    <div className="set-up-profile">Setting Up Your Profile</div>
                    </div>
                    {this.state.pageSelected === "internshipPreference" && <InternshipPreference value={this.state.internshipPreference} change={this.changeState}></InternshipPreference>}
                    {this.state.pageSelected === "internshipSize" && <InternshipSize value={this.state.internshipSize} change={this.changeState}></InternshipSize>}
                    {this.state.pageSelected === "internshipTitle" && <InternshipTitle option={this.state.titleOption} value={this.state.internshipTitle} change={this.changeState}></InternshipTitle>}
                    {this.state.pageSelected === "companiesInterested" && <CompaniesInterested value={this.state.companiesInterested} change={this.changeState}></CompaniesInterested>}
                    <div className="buttons_back_continue">
                        {this.state.internshipPreference["fullTime"] === 0 && this.state.internshipPreference["partTime"] === 0 && this.state.internshipPreference["contract"] === 0 &&
                        this.state.internshipPreference["paid"] === 0 && this.state.internshipPreference["remote"] === 0 && this.state.internshipPreference["temporary"] === 0 &&
                        this.state.internshipPreference["volunteer"] === 0 && this.state.pageSelected === "internshipPreference" && <button className = "common_button_unselected" >Continue</button>}
                        {(this.state.internshipPreference["fullTime"] === 1 || this.state.internshipPreference["partTime"] === 1 || this.state.internshipPreference["contract"] === 1 ||
                        this.state.internshipPreference["paid"] === 1 || this.state.internshipPreference["remote"] === 1 || this.state.internshipPreference["temporary"] === 1 ||
                        this.state.internshipPreference["volunteer"] === 1) && this.state.pageSelected === "internshipPreference" && <button className = "common_button_selected" onClick={()=>this.selectPageContinue()}>Continue</button>}

                        {this.state.pageSelected === "internshipTitle" && <button className = "back_buttom_unselected" onClick={()=>this.selectPageBack()}>Back</button>}
                        {this.state.internshipTitle.length === 0 && this.state.pageSelected === "internshipTitle" && <button className = "common_button_unselected">Continue</button>}
                        {this.state.internshipTitle.length > 0 && this.state.pageSelected === "internshipTitle" && <button className = "common_button_selected" onClick={()=>this.selectPageContinue()}>Continue</button>}

                        {this.state.pageSelected === "internshipSize" && <button className = "back_buttom_unselected" onClick={()=>this.selectPageBack()}>Back</button>}
                        {this.state.internshipSize["Fortune 500"] === 0 && this.state.internshipSize["Midtier"] === 0 && this.state.internshipSize["Start ups"] === 0 &&
                        this.state.internshipSize["Get Me Anything!"] === 0 && this.state.pageSelected === "internshipSize" && <button className = "common_button_unselected" >Continue</button>}
                        {(this.state.internshipSize["Fortune 500"] === 1 || this.state.internshipSize["Midtier"] === 1 || this.state.internshipSize["Start ups"] === 1 ||
                        this.state.internshipSize["Get Me Anything!"] === 1) && this.state.pageSelected === "internshipSize" && <button className = "common_button_selected" onClick={()=>this.selectPageContinue()}>Continue</button>}

                        {this.state.pageSelected === "companiesInterested" && <button className = "back_buttom_unselected" onClick={()=>this.selectPageBack()}>Back</button>}
                        {this.state.companiesInterested.length === 0 && this.state.pageSelected === "companiesInterested" && <button className = "common_button_unselected">Make your resume Now!</button>}
                        {this.state.companiesInterested.length > 0 &&  this.state.pageSelected === "companiesInterested" && 
                        <HandleBackend val={this.state}></HandleBackend>}
                    </div>
                    </div>
            </div>
        );
    };
};

export default InternshipPreferenceQuestions;

