import React from "react";
import  "./style.css";
import CreatableSelect from 'react-select/creatable';
class InternshipTitle extends React.Component{
    constructor(props){
        super(props);
    };

    handleOnChange = (newValue) => {
        this.props.change({internshipTitle : newValue});
    }
    
    render(){
        return(
            <div className="CenterQ">
                <h5>What are the internship titles you're looking for:</h5>
                <br></br>
                <div className="questionnaire-content">
                    <CreatableSelect 
                    className = "page"
                    isMulti={true}
                    onChange = {this.handleOnChange}
                    value = {this.props.value}
                    options={this.props.option}/>
                </div>
            </div>
        )
    }
};

export default InternshipTitle;