import React, { useState } from "react";
import $ from "jquery";
import "./style.css";

const ContactForm = () => {
  const [formContents, setFormContents] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const sending = () => {
    $.post(
      `${process.env.REACT_APP_SEND_CONTACT_ENDPOINT}`,
      JSON.stringify(formContents),
      (data) => {}
    );
  };

  function handleOnChange(e) {
    console.log(e.target.name);
    if (e.target.name === "name") {
      setFormContents({ ...formContents, name: e.target.value });
    }
    if (e.target.name === "email") {
      setFormContents({ ...formContents, email: e.target.value });
    }
    if (e.target.name === "subject") {
      console.log(e.target.value);
      setFormContents({ ...formContents, subject: e.target.value });
    }
    if (e.target.name === "message") {
      setFormContents({ ...formContents, message: e.target.value });
    }
  }

  function dropdownValidate() {
    const dropdown = document.querySelector("#contact-form__dropdown");
    const content = dropdown.innerHTML.trim();
    if (content === "Subject") {
      dropdown.classList.add("is-invalid");
      dropdown.classList.remove("is-valid");
      return false;
    } else {
      dropdown.classList.remove("is-invalid");
      dropdown.classList.add("is-valid");
      return true;
    }
  }

  function formValidate(event) {
    "use strict";
    const form = document.querySelector(".needs-validation");
    const dropdownValidated = dropdownValidate();
    if (!form.checkValidity() || !dropdownValidated) {
      event.preventDefault();
      event.stopPropagation();
      form.classList.add("was-validated");
      return false;
    }
    return true;
  }

  function onFormSubmit(e) {
    const validated = formValidate(e);
    if (!validated) {
      e.preventDefault();
    } else {
      e.preventDefault();
      const contactBtn = document.querySelector("#contact-form__btn");
      const doneMessage = document.querySelector(
        "#contact-form__submitted-message"
      );
      contactBtn.innerHTML = "Submitted!";
      contactBtn.style.backgroundColor = "#213546";
      contactBtn.disabled = true;
      doneMessage.style.display = "flex";

      sending();
    }
  }

  return (
    <div>
      <div className="contact-form">
        <form onSubmit={onFormSubmit} className="needs-validation" noValidate>
          <div className="row">
            <div className="col-12 col-sm-4 ps-0">
              <div className="input-group input-group-lg mb-4">
                <input
                  type="text"
                  name="name"
                  onChange={(e) => handleOnChange(e)}
                  className="form-control contact-form__input"
                  placeholder="Name"
                  aria-label="Name"
                  required
                />
                <div className="invalid-feedback">Name cannot be blank</div>
              </div>
            </div>
            <div className="col-12 col-sm-8 ps-0">
              <div className="input-group input-group-lg mb-4">
                <input
                  type="text"
                  name="email"
                  onChange={(e) => handleOnChange(e)}
                  className="form-control contact-form__input"
                  placeholder="Email"
                  aria-label="Email"
                  required
                />
                <div className="invalid-feedback">Email cannot be blank</div>
              </div>
            </div>
            <div className="col-12 ps-0">
              <div className="input-group btn-group d-grid mb-4">
                <button
                  className="btn contact-form__dropdown-btn btn-lg dropdown-toggle d-flex justify-content-between align-items-center"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  id="contact-form__dropdown"
                  preventDefault
                  required
                >
                  {formContents.subject.length === 0
                    ? "Subject"
                    : formContents.subject}
                </button>
                <div className="invalid-feedback">Subject cannot be blank</div>
                <ul className="dropdown-menu  w-100">
                  <li>
                    <button
                      className="dropdown-item"
                      type="button"
                      href="#"
                      name="subject"
                      value="General Inquiry"
                      preventDefault
                      onClick={(e) => handleOnChange(e)}
                    >
                      General Inquiry
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      type="button"
                      href="#"
                      name="subject"
                      value="Recruiters"
                      preventDefault
                      onClick={(e) => handleOnChange(e)}
                    >
                      Recruiters
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      type="button"
                      href="#"
                      name="subject"
                      value="Students"
                      preventDefault
                      onClick={(e) => handleOnChange(e)}
                    >
                      Students
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 ps-0">
              <div className="form-group">
                <textarea
                  className="form-control contact-form__text-area"
                  name="message"
                  onChange={(e) => handleOnChange(e)}
                  id="exampleFormControlTextarea1"
                  rows="7"
                  placeholder="Type your message here..."
                  required
                ></textarea>
                <div className="invalid-feedback">Message cannot be blank</div>
              </div>
            </div>
          </div>
          <div className="col-12 d-flex contact-form__btn-div">
            <button id="contact-form__btn" type="submit" className="mt-4">
              Submit
            </button>
          </div>
        </form>
      </div>
      <p id="contact-form__submitted-message" className="text-center">
        Thank you for submitting a question! We will get back to you at our
        earliest convenience!
      </p>
    </div>
  );
};

export default ContactForm;
