import React, { useState, useEffect } from "react";
import $ from "jquery";
import {
  getFormDate,
  generateUID,
  getToday,
  ajaxSetup,
} from "../../helper/helper";
import Input from "./../Input/Input";
import "./Forms.css";

function EducationForm({ resumeId, handlePagination }) {
  const addEducationEndpoint = process.env.REACT_APP_ADD_EDUCATION;
  const getEducationEndpoint = process.env.REACT_APP_GET_EDUCATION;
  const editEducationEndpoint = process.env.REACT_APP_EDIT_EDUCATION;
  const deleteEducationEndpoint = process.env.REACT_APP_DELETE_EDUCATION;
  const [educationForms, setEducationForms] = useState([]);
  const [indx, setIndx] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [nextLoad, setNextLoad] = useState(false);
  const [added , setAdded] = useState(false);

  const [gpaError,setGpaError] = useState("");
  const [institutionEmpty,setInstitutionEmpty] = useState("");
  const [locationEmpty,setLocationEmpty] = useState("");
  const [majorEmpty,setMajorEmpty] = useState("");
  const [startDateEmpty,setStartDateEmpty] = useState("");
  let validation = true;

  const addForm = () => {
    setAdded(true);
    setEducationForms([...educationForms, singleForm(indx)]);
    setIndx(indx + 1);
  };

  const removeForm = (evt) => {
    document.getElementById('failure').style.display = 'none';

    if (educationForms.length === 1) {
      setAdded(false);
    }

    evt.preventDefault();

    let [formToPop, newFormList] = [educationForms.pop(), educationForms];
    setEducationForms([...newFormList]);
    formToPop && setIndx(indx - 1);
    if (formToPop?.props?.id) {
      const educationFieldId = formToPop.props.id;
      // use delete method
      $.ajax(`${deleteEducationEndpoint}?id=${educationFieldId}`, {
        type: "DELETE",
      });
    }
  };

  const addEducation = (educationData) => {
    return $.post(
      `${addEducationEndpoint}`,
      JSON.stringify(educationData),
      (data) => {}
    );
  };

  const editEducation = (educationData, educationFieldId) => {
    return $.post(
      `${editEducationEndpoint}`,
      JSON.stringify({ ...educationData, educationFieldId }),
      (data) => {}
    );
  };

  const saveForms = (evt) => {
    validation = true;
    setGpaError(""); 
    setInstitutionEmpty("");
    setLocationEmpty(""); 
    setMajorEmpty(""); 
    setStartDateEmpty("");

    evt.preventDefault();
    setNextLoad(true);

    const promises = []
    
    document.querySelectorAll(".form").forEach((form) => {

      const {
        uid,
        creationTimeStamp,
        modifiedTimeStamp,
        ordinal,
        location,
        major,
        startDate,
        endDate,
        active,
      } = form;
      
      const educationData = {
        resumeId: resumeId,
        school: form.school.value,
        location: location?.value,
        major: major?.value,
        gpa: form.gpa.value,
        startDate: startDate?.value,
        ordinal: parseInt(ordinal?.value),
      };

      //--------------VALIDATION

      if (Number.isInteger(form.gpa.value)) {
        alert("here");
        setGpaError("gpa should be a decimal");
        validation = false;
      }

      if (form.school.value === "" ) {
        setInstitutionEmpty("Please fill your institution's name");
        validation = false;
      }

      if (form.location.value === "") {
        setLocationEmpty("Please fill your institution's location");
        validation = false;
      }

      if (form.major.value === "") {
        setMajorEmpty("Please fill in your major");
        validation = false;
      }

      if (form.startDate.value === "") {
        setStartDateEmpty("Please fill in your start date")
        validation = false;
      }
      
      if (endDate?.value !== "") {
        educationData.endDate = endDate?.value
      }

      if (validation)
      {
      if (form.id) {
        educationData.active = active?.value;
        educationData.creationTimeStamp = creationTimeStamp?.value;
        educationData.modifiedTimeStamp = modifiedTimeStamp?.value;
        promises.push(editEducation(educationData, form.id));
      } else {
        promises.push(addEducation(educationData, form, uid?.value));
      }
      document.getElementById("failure").style.display = 'none';
    }
    else {
      document.getElementById("failure").style.display = 'block';
    }
    });

    if(validation)
    {

    Promise.all(promises).then(() => {
        setTimeout(() => {
          handlePagination(1,"education");
        }, 500);
    }).catch((err) => {
      console.log(err)
      alert("Please fill out all fields within your education including the start date")
      setNextLoad(false)
    })

  }
  else{setNextLoad(false)}
  };

  const  getEducation = () => {
   $.ajax(getEducationEndpoint, {
      type: "GET",
      data: { resumeId },
    }).then((data) => {
      setIndx(data.length + 1);
      setEducationForms(
        data
          .sort((x, y) => x.ordinal - y.ordinal)
          .map((dataset) => {
            const {
              educationFieldId,
              school,
              location,
              major,
              gpa,
              startDate,
              endDate,
              ordinal,
              active,
              creationTimeStamp,
              modifiedTimeStamp,
            } = dataset;
            // const date = getFormDate();
            const uid = generateUID();
            
            setAdded(true);

            return singleForm(
              ordinal,
              educationFieldId,
              uid,
              school,
              location,
              major,
              gpa,
              getFormDate(startDate),
              getFormDate(endDate),
              active,
              getFormDate(creationTimeStamp),
              getFormDate(modifiedTimeStamp)
            );
          })
      );
      setLoaded(true);
    });
  };

  useEffect(() => {
    ajaxSetup();    
    getEducation();
      
  }, []);
  const singleForm = (
    ordinal = -1,
    id = null,
    uid = null,
    school = "",
    location = "",
    major = "",
    gpa = "",
    startDate = "",
    endDate = "",
    active = true,
    creationTimeStamp = getToday(),
    modifiedTimeStamp = getToday()
  ) => {
    return (
      <form className="form" indx={indx} id={id}>
        <input
          className="form__noDisplay"
          name="ordinal"
          value={ordinal}
          tabIndex={-1}
          readOnly
        />
        <input
          className="form__noDisplay"
          name="uid"
          value={uid || generateUID()}
          tabIndex={-1}
          readOnly
        />
        <input
          className="form__noDisplay"
          name="creationTimeStamp"
          value={creationTimeStamp}
          tabIndex={-1}
          readOnly
        />
        <input
          className="form__noDisplay"
          name="modifiedTimeStamp"
          value={modifiedTimeStamp}
          tabIndex={-1}
          readOnly
        />
        <input
          className="form__noDisplay"
          name="active"
          value={active}
          tabIndex={-1}
          readOnly
        />
        <Input
          indx={indx}
          type="text"
          title="Institution"
          placeholder="University of California, Irvine"
          inputStyle="form"
          value={school}
          headStyle
          name="school"
        />
        <Input
          indx={indx}
          type="text"
          title="Location"
          placeholder="Irvine"
          inputStyle="form"
          name="location"
          value={location}
        />
        <br></br>
        <Input
          indx={indx}
          type="text"
          title="Major"
          placeholder="Computer"
          inputStyle="form"
          name="major"
          value={major}
        />
        <br></br>
        <Input
          indx={indx}
          type="text"
          title="GPA"
          placeholder="3.5"
          inputStyle="form"
          name="gpa"
          value={gpa}
        />
        <br></br>
        <div className="form__dates">
          <Input
            indx={indx}
            type="date"
            title="Start Date"
            inputStyle="dateForm"
            name="startDate"
            value={startDate}
          />
          <Input
            indx={indx}
            type="date"
            title="End Date"
            inputStyle="dateForm"
            name="endDate"
            value={endDate}
          />
        </div>
      </form>
    );
  };
  return loaded ? (
    <>
      {nextLoad && (
        <div className="form__loader">
          <div className="form__spinner"></div>
        </div>
      )}
      <div className="projectForm">
        <h3>Your Education</h3>

        <div id = "failure">
            <h3> {institutionEmpty}</h3>
            <h3> {locationEmpty}</h3> 
            <h3> {majorEmpty}</h3> 
            <h3> {gpaError}</h3>  
            <h3> {startDateEmpty}</h3> 
        </div>

        {educationForms.map((component, indx) => (
          <React.Fragment key={indx}>{component}</React.Fragment>
        ))}
        <div className="form__buttons">
          <button className="form__button" onClick={(evt) => saveForms(evt)}>
            Save & Next
          </button>
          <button className="form__button" onClick={(evt) => addForm(evt)}>
            Add Education
          </button>
          <button 
          style = {added ? {display : "block"} : {display : "none"}}
          className="form__button" 
          onClick={(evt) => removeForm(evt)}>
            Remove Education
          </button>
        </div>
      </div>
    </>
  ) : (
    <div className="form__loader">
      <div className="form__spinner"></div>
    </div>
  );
}

export default EducationForm;
