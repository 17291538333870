import React from "react";
import ContactForm from "../../components/ContactForm";
import SectionTitle from "../../components/SectionTitle";
import Footer from "../../components/Footer";
import "./style.css";
import Images from "../../Images";
import downArrow from "../../assets/icons/downArrow.svg";

const contact = () => {
  return (
    <div
      className="contact-page"
      style={{
        backgroundImage: `url(${Images.BACKGROUND})`,
        backgroundRepeat: "repeat-y",
        backgroundSize: "cover",
        width: "100vw",
        minHeight: "100vh",
        color: "#213546",
      }}
    >
      <SectionTitle
        text="Contact"
        subtitle="For more info fill out the form or email us at contact@internguys.com"
      />
      <div className="contact-section mb-auto">
        <ContactForm />
      </div>
      <div
        className="accordion accordion-flush mx-auto contact__accordian-div"
        id="contact__accordion"
      >
        <h2 className="mb-4">Frequently Asked Questions</h2>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingOne">
            <button
              className="accordion-button collapsed contact__accordian-btn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#contact__q1"
              aria-expanded="false"
              aria-controls="flush-contact__q1"
            >
              <div className="d-flex justify-content-between">
                <span className="text-left">
                  Am interested in hiring interns, how do I go about this?
                </span>
                <img src={downArrow} className="ml-2" />
              </div>
            </button>
          </h2>
          <div
            id="contact__q1"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#contact__accordion"
          >
            <div className="accordion-body contact__accordian-body">
              If you're a company/startup interested in hiring interns feel free to contact as 
              contact@internguys.com or book a sales 
              call with our founder: https://calendly.com/internguys/intern-guys-demo-questionnaire
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingTwo">
            <button
              className="accordion-button collapsed contact__accordian-btn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#contact__q2"
              aria-expanded="false"
              aria-controls="flush-collapseTwo"
            >
              <div className="d-flex justify-content-between">
                <span className="text-left">
                  Am struggling to find an internship/make a resume/getting started/no idea what am interested in and need help!
                </span>
                <img src={downArrow} className="ml-2" />
              </div>
            </button>
          </h2>
          <div
            id="contact__q2"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingTwo"
            data-bs-parent="#contact__accordian"
          >
            <div className="accordion-body  contact__accordian-body">
              No issues - we are the intern guys! Feel free to book a call with our founder and 
              he'll help you out for no cost (Please add notes on why your booking the call):
              https://calendly.com/internguys/meeting-with-hannan-hussain-intern-guys
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingThree">
            <button
              class="accordion-button collapsed contact__accordian-btn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#contact__q3"
              aria-expanded="false"
              aria-controls="flush-collapseThree"
            >
              <div className="d-flex justify-content-between">
                <span className="text-left">
                  Are there any features you hope we add? Bugs on the site? Anything that can help us make the internship hunt easier? 
                </span>
                <img src={downArrow} className="ml-2" />
              </div>
            </button>
          </h2>
          <div
            id="contact__q3"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingThree"
            data-bs-parent="#contact__accordian"
          >
            <div class="accordion-body contact__accordian-body">
              Shoot us an email or book a call with our founder (Please add notes on why your booking the call):
              https://calendly.com/internguys/meeting-with-hannan-hussain-intern-guys
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingFour">
            <button
              class="accordion-button collapsed contact__accordian-btn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#contact__q4"
              aria-expanded="false"
              aria-controls="flush-collapseFour"
            >
              <div className="d-flex justify-content-between">
                <span className="text-left">
                  Interested in doing a partnership? Want to collaborate on our social media or others? Press?
                </span>
                <img src={downArrow} className="ml-2" />
              </div>
            </button>
          </h2>
          <div
            id="contact__q4"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingFour"
            data-bs-parent="#contact__accordian"
          >
            <div class="accordion-body contact__accordian-body">
              Shoot us an email or book a call with our founder (Please add notes on why your booking the call):
              https://calendly.com/internguys/meeting-with-hannan-hussain-intern-guys
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingFive">
            <button
              class="accordion-button collapsed contact__accordian-btn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#contact__q5"
              aria-expanded="false"
              aria-controls="flush-collapseFive"
            >
              <div className="d-flex justify-content-between">
                <span className="text-left">
                  I have something really urgent to talk/discuss about!
                </span>
                <img className="ml-2" src={downArrow} />
              </div>
            </button>
          </h2>
          <div
            id="contact__q5"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingFive"
            data-bs-parent="#contact__accordian"
          >
            <div class="accordion-body contact__accordian-body">
              Shoot our CEO an email: hannan@internguys.com
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default contact;
