import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { setIt, getIt} from "../../views/preSignupQuestion/localStorageFunction";
import "./style.css"

const options = [
    { value: 1, label: 'Engineering' },
    { value: 2, label: 'Computer Science/IT' },
    { value: 3, label: 'Health-Care' },
    { value: 4, label: 'Business' },
    { value: 5, label: 'Marketing' },
    { value: 6, label: 'Accounting' },
    { value: 7, label: 'Financial' },
    { value: 8, label: 'Retail' },
    { value: 9, label: 'Legal' },
    { value: 10, label: 'Human Resource' },
    { value: 11, label: 'Public Relation' },
    { value: 12, label: 'Fashion' },
    { value: 13, label: 'Non Profit' },
    { value: 14, label: 'STEM' },
    { value: 15, label: 'Arts/Humanities' },
    { value: 16, label: 'Business' },
    { value: 17, label: 'Social Ecology' },
    { value: 18, label: 'Undecided' },
  ];

const Q1 = () => {

    const [selected, setSelected] = useState([]);

    const handleOnChange = (values) => {
        setSelected(values);
        setIt(JSON.stringify(values), "q1");
    }

    const getLocal = () => {
        var x = JSON.parse(getIt("q1"));
        setSelected(x);
    };

    useEffect(() => {
        getLocal();
      }, []);


    return(<div>
            <div className="centerText">
            <h5>Tell us what industry you are looking for?</h5>
            </div>
            <br></br>
            <div className="centerA">
                <Select
                    className = "page"
                    isMulti={true}
                    onChange = {handleOnChange}
                    value = {selected}
                    options={options}
                    />      
            </div>
            </div>
            );
};

export default Q1;