import React, {useEffect, useState} from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Clear from "@material-ui/icons/Clear";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Q1 from "../../components/PreSignup/q1";
import Q2 from "../../components/PreSignup/q2";
import Q3 from "../../components/PreSignup/q3";
import Q4 from "../../components/PreSignup/q4";
import { faPenNib, faGraduationCap, faAward, faBriefcase, faTasks, faGlobe, faCog, faBookmark, faCheck } from '@fortawesome/free-solid-svg-icons';
import { setIt, getIt} from "../../views/preSignupQuestion/localStorageFunction";
import Images from "../../Images";
import ReactModal from "react-modal";
import dayjs from 'dayjs';
import "./profile.css";
import $ from "jquery";
import { getToken , logout } from "../../authetication/loginState";
import { useNavigate } from "react-router-dom";
import useAnalyticsEventTracker  from "../../helper/reactTracker"

const useStyles = makeStyles((theme) => ({
    root: {},
    resumeHeader: {},
    resumeContent: {
        paddingBottom: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            display: 'black',
        },
        [theme.breakpoints.up('md')]: {
            display: 'grid',
            gridTemplateColumns: '50% 50%',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'grid',
            gridTemplateColumns: '50% 50%',
        },
    },
    language: {
      backgroundColor: '#05747450',
      padding: theme.spacing(2)
    },
    subTitle: {
        fontSize: 20
    },
    companyName: {
        fontSize: 18,
        fontStyle: 'italic'
    },
    selectDropdown: {
        color: '#ffffff',
    },
    formControl: {
        color: '#ffffff',
        margin: theme.spacing(1),
        minWidth: 120,
        '& .MuiInput-underline:before': {
            borderColor: '#ffffff'
        },
        '& .MuiInput-underline:after': {
            borderColor: '#ffffff'
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderColor: '#ffffff'
        },
        '& .MuiSelect-icon': {
            color: '#ffffff'
        }
    },
    inputLabel: {
        color: '#ffffff',
        '&.Mui-focused': {
            color: '#ffffff'
        }
    }
  }));

  const Accordion = withStyles({
    root: {
        backgroundColor: '#05747450',
        color: "#FFFFFF",
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiAccordion);
  
  const AccordionSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
      '& .MuiIconButton-root': {
          color: '#FFFFFF'
      }
    },
    content: {
        display: "block",
        '&.Mui-expanded': {
            margin: '12px 0',
        }
    },
  })(MuiAccordionSummary);
  
  const AccordionDetails = withStyles((theme) => ({
    root: {
        display: "block",
        backgroundColor: '#255b68',
      padding: theme.spacing(2),
    },
  }))(MuiAccordionDetails);

function ProfilePage()
{
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [edit, setEdit] = useState(false);

    const [user, setUser] = useState({});
    const [resumeId, setResumeId] = useState('');
    const [experiences, setExperiences] = useState([]);
    const [projects, setProjects] = useState([]);
    const [educations, setEducations] = useState([]);
    const [skills, setSkills] = useState([]);
    const [awards, setAwards] = useState([]);
    const [userResumes, setUserResumes] = useState([]);

    const [preference, setPreference] = useState({});
    const [preferenceId, setPreferenceId] = useState(0);
    const [sizes, setSizes] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [titles, setTitles] = useState([]);
    const [titleField, setTitleField] = useState([]);
    const [titleFieldId, setTitleFieldId] = useState("BC290F9D-E490-44F6-B1CA-D873E27E9C1A");
    let navigate = useNavigate();

    const gaEventTracker = useAnalyticsEventTracker('Application');

    const [loggedIn, setLoggedIn] = useState(getToken() ? true : false);
    const logoutUser = () => {
      logout();
      setLoggedIn(false);
      navigate({
        pathname: '/login',
        state: "userSessionExpired" 
      });
    };

    const [savedInternships, setSavedInternships] = useState([]);
    const [appliedInternships, setAppliedInternships] = useState([]);
    const [showApply, setShowApply] = useState(false);
    const [topModal, setTopModal] = useState({});
    const [showApplyModal, setShowApplyModal] = useState(false);

    const [size, setSize] = useState(window.innerWidth);

    var sizesPrevious = useState({
        "Fortune 500" : false,
        "Midtier" : false,
        "Start ups" : false,
        "Get Me Anything!" : false,
    })[0];

    const industry_options = [
        { value: 1, label: 'Engineering' },
        { value: 2, label: 'Computer Science/IT' },
        { value: 3, label: 'Health-Care' },
        { value: 4, label: 'Business' },
        { value: 5, label: 'Marketing' },
        { value: 6, label: 'Accounting' },
        { value: 7, label: 'Financial' },
        { value: 8, label: 'Retail' },
        { value: 9, label: 'Legal' },
        { value: 10, label: 'Human Resource' },
        { value: 11, label: 'Public Relation' },
        { value: 12, label: 'Fashion' },
        { value: 13, label: 'Non Profit' },
        { value: 14, label: 'STEM' },
        { value: 15, label: 'Arts/Humanities' },
        { value: 16, label: 'Business' },
        { value: 17, label: 'Social Ecology' },
        { value: 18, label: 'Undecided' },
    ];

    const applyStyles =
    size > 720
      ? {
          overlay: { zIndex: 5 },
          content: {
            inset: "40px",
            padding: "40px 70px",
          },
        }
      : {
          overlay: { zIndex: 5 },
          content: {
            inset: "0",
          },
        };

    useEffect(() => {
        ajaxSetup();
        userInfo();
      }, [resumeId]);

    useEffect(()=>{
        preferenceId && $.get(`${process.env.REACT_APP_INTERNSHIP_SIZE_GET}?InternshipPreferenceId=${preferenceId}`, (sizeData) => {
            var toSet = [];
            sizeData.forEach(size => {
                toSet.push(size);
            });
            
            setSizes(toSet);
            //company size
            var localSizes = [];
            toSet.forEach(size => {
                    const sizeName = Object.keys(sizesPrevious)[size.internshipSize1-1];
                    localSizes.push(sizeName);
            });
            setIt(localSizes,"q3");
        }).catch(error =>
            {
                if(error.status === 401)
                    console.log("user Session Expired")
                    logoutUser();
            })

        $.get(process.env.REACT_APP_INTERNSHIP_PREFERENCE_GET, (data) => {
            if (data.length == 0){
                return;
            }
            setPreference(data[0]);
            var localPreferences = [];
            Object.keys(data[0]).forEach(preferenceKey=>{
                if(data[0][preferenceKey] === true) localPreferences.push(preferenceKey);
            })
            setIt(localPreferences, "q4");
        });
        preferenceId && $.get(`${process.env.REACT_APP_INTERNSHIP_INDUSTRY_GET}?InternshipPreferenceId=${preferenceId}`, (data) => {
            setIndustries(data);
            var localIndustries = [];
            data.forEach(industry =>{
                localIndustries.push(industry_options[industry.industryPicked-1]);
            });
            setIt(JSON.stringify(localIndustries), "q1");

        });
        preferenceId && $.get(`${process.env.REACT_APP_INTERNSHIP_TITLE_GET}?InternshipPreferenceId=${preferenceId}`, (data) => {
            setTitles(data);
            var localTitles = [];
            data.forEach(title =>{
                var temp = {};
                temp['value'] = title.titlePicked;
                temp['label'] = title.titlePicked;
                localTitles.push(temp);
            });
            setIt(JSON.stringify(localTitles), "q2");

        });
    }, [edit, preferenceId]);

    useEffect(() => {
        ajaxSetup();
        getSavedInternships();
    }, [])

    useEffect(() => {
        getAppliedInternships();
    }, [])

    useEffect(() => {
        function updateSize() {
          setSize(window.innerWidth);
        }
        window.addEventListener("resize", updateSize);
        return () => window.removeEventListener("resize", updateSize);
      });

    const ajaxSetup = () => {
        $.ajaxSetup({
            headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
            },
        });
    };

    const toggleModal = () => {
        const body = document.querySelector("body");
        body.style.overflow = showApply ? "auto": "hidden";
        setShowApply(showApply ? false : true);
    };

    const getSavedInternships = () => {
        $.get(process.env.REACT_APP_GET_SAVED_INTERNSHIPS, (data) => {
            setSavedInternships(data)
        })
    }

    const getAppliedInternships = () => {
        $.get(process.env.REACT_APP_GET_APPLIED_INTERNSHIPS, (data) => {
            setAppliedInternships(data)
            console.log("Reached")
        })
    }

    const deleteSavedInternship = (index) => {
        $.ajax({
            url: process.env.REACT_APP_DELETE_SAVED_INTERNSHIP + `?id=${index.internshipId}`,
            type: 'DELETE',
        });
        //$.delete(process.env.REACT_APP_DELETE_SAVED_INTERNSHIP + `?id=${savedInternships[index].internshipId}`)
        const newData = [...savedInternships]
        newData.splice(savedInternships.indexOf(index), 1)
        setSavedInternships(newData)
    }

    const applyInternship = (e) => {
        e.preventDefault();
    
        const applyInternshipData = {
          "internshipId": topModal.internshipId,
          "userId": user.userId,
          "firstName": $("#fname").val(),
          "lastName": $("#lname").val(),
          "phoneNumber": $("#phone").val(),
          "email": $("#email").val(),
          "resumeFileName": ""
        }
    
        $.post(`${process.env.REACT_APP_APPLY_INTERNSHIP}`, JSON.stringify(applyInternshipData), (data) => {
            const applicationId = data.studentAppliedIdReturn
    
            var fd = new FormData();
            var files = $('#resume')[0].files;
            if(files.length > 0 ){
              fd.append('file',files[0]);
              fetch(`${process.env.REACT_APP_ATTACH_RESUME}?student_applied_id=${applicationId}`, {
                method: "POST", 
                headers:{
                  Authorization: `Bearer ${getToken()}`,
                },
                body: fd
              }).then(res => {
                if (res.status === 200){
                  gaEventTracker('application')
                  alert("Application Successful")
                  deleteSavedInternship(topModal)
                  // Reloads for now have to change this later
                  window.location.reload();
                }
                else{
                  alert("Application Unsuccessfuly")
                }
              }); 
            }
        })
    
      }

    const userInfo = () => {
        $.get(process.env.REACT_APP_GET_USER, (data) => {
            setUser(data);
        })

        $.get(process.env.REACT_APP_GET_RESUMES, (data) => {
            let i= 0;
            let ResumeID;
            for(i = 0; i < data.length; i++)
            {
                if (data[i].pimaryResume === true)
                {
                    ResumeID = data[i].resumeId;
                }
            };

            $.get(process.env.REACT_APP_GET_EXPERIENCE, {ResumeID}, (data) => {
                setExperiences(data);
            })

             $.get(process.env.REACT_APP_GET_EDUCATION, {ResumeID}, (data) => {
                setEducations(data);
            })

            $.get(process.env.REACT_APP_GET_SKILL, {ResumeID}, (data) => {
                setSkills(data);
            })

            $.get(process.env.REACT_APP_GET_PROJECT, {ResumeID}, (data) => {
                setProjects(data);
            })

            $.get(process.env.REACT_APP_GET_AWARD, {ResumeID}, (data) => {
                setAwards(data);
            })

            $.get(process.env.REACT_APP_GET_RESUMES, (data) => {
                setUserResumes(
                  data.sort(
                    (x, y) =>
                      new Date(y.creationTimeStamp) - new Date(x.creationTimeStamp)
                  )
                );
            });
            $.get(process.env.REACT_APP_INTERNSHIP_PREFERENCE_GET, (data) => {
                if (data.length > 0){
                    setPreferenceId(data[0].internshipPreferenceId);
                }
            })
            $.get(`${process.env.REACT_APP_TITLE_GET}`, {titleFieldId}, (data) => {
                setTitleField(data);
            });

        }).catch(error =>
            {
                if(error.status === 401)
                    console.log("user Session Expired")
                    logoutUser();
            })
    };

    const toggleEdit = () => {
        setEdit(edit? false: true);

    }

    const updateInternshipType = () => {
        var x = getIt("q4");
        var x2 = x.split(",");
        var types = {
            fullTime : false,
            partTime : false,
            contract : false,
            paid : false,
            remote : false,
            temporary : false,
            volunteer : false,
            userId: user.userId,
            internshipPreferenceId: preferenceId
        };

        x2.forEach(element => {
            if(element !== "") types[element] = true;
        });
        $.post(process.env.REACT_APP_INTERNSHIP_PREFERENCE_EDIT, 
            JSON.stringify(types), 
            (data) => {}
        );
    };

    const updateCompanySize = () => {
        var x = getIt("q3");
        var x2 = x.split(",");
        const translate = {
            "Fortune 500" : 1,
            "Midtier" : 2,
            "Start ups" : 3,
            "Get Me Anything!" : 4,
        }
        var sizes_current={
            "Fortune 500" : false,
            "Midtier" : false,
            "Start ups" : false,
            "Get Me Anything!" : false,
        }
        x2.forEach(element => {
            if(element !== "") sizes_current[element] = true;
        }); 
        sizesPrevious={
            "Fortune 500" : false,
            "Midtier" : false,
            "Start ups" : false,
            "Get Me Anything!" : false,
        }
        sizes.forEach(size=>{
            if(size !== "") sizesPrevious[Object.keys(sizesPrevious)[size.internshipSize1-1]] = true;

        });
        for(var key of Object.keys(sizes_current)){
            if (sizes_current[key] && !sizesPrevious[key]){
                //add
                $.post(`${process.env.REACT_APP_INTERNSHIP_SIZE_ADD}`, JSON.stringify({
                    "internshipPreferenceId" : preferenceId,
                    "internshipSize1" : translate[key]
                }), (data) => {});
            }
            if(!sizes_current[key] && sizesPrevious[key]){
                //delete
                var deleteId = 0;
                sizes.forEach(size =>{
                    if(size.internshipSize1 === translate[key]) deleteId = size.internshipSizeId;
                });
                $.ajax(`${process.env.REACT_APP_INTERNSHIP_SIZE_DELETE}?id=${deleteId}`, {
                    type: "DELETE",
                });

            }
        }
        sizesPrevious = sizes_current;
    };

    const updateIndustry = () => {
        var industryPrevious = {
            1 : false,
            2 : false,
            3 : false,
            4 : false,
            5 : false,
            6 : false,
            7 : false,
            8 : false,
            9 : false,
            10 : false,
            11 : false,
            12 : false,
            13 : false,
            14 : false,
            15 : false,
            16 : false,
            17 : false,
            18 : false,
        };
        var industryCurrent = {
            1 : false,
            2 : false,
            3 : false,
            4 : false,
            5 : false,
            6 : false,
            7 : false,
            8 : false,
            9 : false,
            10 : false,
            11 : false,
            12 : false,
            13 : false,
            14 : false,
            15 : false,
            16 : false,
            17 : false,
            18 : false,
        };
        
        var x = JSON.parse(getIt("q1"));

        x.forEach(element => {
            if(element.label !== "") industryCurrent[element.value] = true;
        }); 

        industries.forEach(industry=>{
            if(industry !== "") industryPrevious[Object.keys(industryPrevious)[industry.industryPicked-1]] = true;

        });
        for(var key of Object.keys(industryCurrent)){
            if (industryCurrent[key] && !industryPrevious[key]){
                //add
                // console.log("add " + key);
                $.post(`${process.env.REACT_APP_INTERNSHIP_INDUSTRY_ADD}`, JSON.stringify({
                    "internshipPreferenceId" : preferenceId,
                    "industryPicked" : key
                }), (data) => {});
            }
            if(!industryCurrent[key] && industryPrevious[key]){
                //delete
                // console.log("delete " + key);
                var deleteId = 0;
                industries.forEach(industry =>{
                    if(industry.industryPicked === parseInt(key,10)) deleteId = industry.industryTypeId;
                });
                $.ajax(`${process.env.REACT_APP_INTERNSHIP_INDUSTRY_DELETE}?id=${deleteId}`, {
                    type: "DELETE",
                });

            }
        }
        industryPrevious = industryCurrent;
    };

    const updateJobTitle = () => {
        var titlesPrevious = {};
        var titlesCurrent = {};
        titleField.forEach(title=>{
            titlesPrevious[title.title1] = false;
        });
        titleField.forEach(title=>{
            titlesCurrent[title.title1] = false;
        });

        titles.forEach(title=>{
            if(title.titlePicked !== "") titlesPrevious[title.titlePicked] = true;
        });

        var x = JSON.parse(getIt("q2"));

        x.forEach(element => {
            if(element.label !== "") titlesCurrent[element.value] = true;
            if(element.__isNew__){
                var sending = {};
                sending["titleFieldId"] =  titleFieldId;
                sending["title1"] = element.value;
                $.post(`${process.env.REACT_APP_TITLE_ADD}`, JSON.stringify(sending), (data) => {});
            }
        }); 

        for(var key of Object.keys(titlesCurrent)){
            if (titlesCurrent[key] && !titlesPrevious[key]){
                //add
                $.post(`${process.env.REACT_APP_INTERNSHIP_TITLE_ADD}`, JSON.stringify({
                    "internshipPreferenceId" : preferenceId,
                    "titlePicked" : key
                }), (data) => {});
            }
            if(!titlesCurrent[key] && titlesPrevious[key]){
                //delete
                var deleteId = 0;
                titles.forEach(title =>{
                    if(title.titlePicked === key) deleteId = title.internshipTitleId;
                });
                $.ajax(`${process.env.REACT_APP_INTERNSHIP_TITLE_DELETE}?id=${deleteId}`, {
                    type: "DELETE",
                });

            }
        }
        titlesPrevious = titlesCurrent;
    };

    const handlePanelChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
      };

   const handleChangeResume = (event) => {
       const resumeId = event.target.value;
       setResumeId(resumeId);
        $.post(process.env.REACT_APP_SET_RESUME_DEFAULT+"?resumeId="+resumeId, (data) => {
        });
    };
    
    return(
        <div style={{ 
            backgroundImage: `url(${Images.BACKGROUND})`,
            backgroundRepeat: 'repeat-y',
            backgroundSize: 'cover',
            width: '100vw',
            minHeight: '100vh'
    }}>

        <ReactModal
        isOpen={showApply}
        contentLabel="Application Modal"
        style={applyStyles}
      >
        <button className="exit" onClick={() => {toggleModal()}}>
          X
        </button>

        {topModal && (
          <div className="apply-wrapper">
            <div className="apply-header">
              <img
                className="modal-company-logo"
                width="50"
                height="50"
                src={Images.LINKEDIN_COLOR}
              />
              <span>
                <h2>{topModal.internShipTitle}</h2>
                <h5>{`${topModal.internship && topModal.internship.company.companyName} - ${topModal.internship && topModal.internship.company.companyHeadquarters}`}</h5>
              </span>
            </div>

            <div className="apply-wrapper">
                <section className="description">
                <h4>Description</h4>
                <p>{topModal.internship &&  topModal.internship.internshipDescription}</p>
            </section>
                <section className="modal-section3">
                <h5>Responsibilities:</h5>

                <ul>
                    {
                    topModal.internship && topModal.internship.internshipDuties.split("|").map(
                        (duty) => duty !== "" && <li>{duty}</li>
                    )}
                </ul>
                </section>

                {showApplyModal && 
                <form>
                    <label for="fname">First name:</label><br/>
                    <input type="text" id="fname" name="fname"/><br/>
                    <label for="lname">Last name:</label><br/>
                    <input type="text" id="lname" name="lname"/><br/>
                    <label for="phone">Phone Number:</label><br/>
                    <input type="text" id="phone" name="phone"/><br/>
                    <label for="email">Email:</label><br/>
                    <input type="email" id="email" name="email"/><br/>
                    <label for="resume">Resume</label><br/>
                    <input type="file" id="resume" name="resume"/><br/> <br/>

                    <button type="submit" className="placard-applybtn" onClick={e => applyInternship(e)}>Apply</button>
                </form>
}
            </div>
          </div>
        )}
          </ReactModal>
            <Box className={classes.resumeHeader}>
                <div className = "maintext">
                    <Box>
                        <h1>{user.name}</h1>
                        <p><b className="smallHeading">About me</b>
                        <br></br>
                        <b>Location:  </b>
                        {user.city}, {user.state}, {user.zip}
                        <br></br>
                        <b>Github:  </b> {user.github}
                        <br></br>
                        <b>Linkedin:  </b> {user.linkedIn}
                        </p>
                    </Box>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="simple-select-label" classes={{root: classes.inputLabel}}>Switch profile</InputLabel>
                        <Select
                            classes={{root: classes.selectDropdown}}
                            labelId="simple-select-label"
                            id="simple-select"
                            value={resumeId}
                            onChange={handleChangeResume}
                        >
                            {userResumes.length > 0 && userResumes.map((resume) => (
                                <MenuItem key={resume.resumeId} value={resume.resumeId}>{resume.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </Box>
            <Box className={classes.resumeContent}>
                <Box className = "left-side">
                    <Box mb={7} className="education-section">
                        <Box className="section-header" display="flex">
                            <FontAwesomeIcon icon={faGraduationCap} size="2x" />
                            <h2 className="header-title">Education</h2>
                        </Box>
                        
                        <Box px={3}>
                            {educations.length > 0 && educations.map((education, index) => (
                                <Accordion square expanded={expanded === `education_${index}`} onChange={handlePanelChange(`education_${index}`)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="education-panel-content"
                                        id="education-panel-header"
                                    >
                                        <Box>
                                            <Typography classes={{subtitle1: classes.subTitle}} variant="subtitle1">{education.school}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="subtitle2">{dayjs(education.startDate).format('MMMM YYYY')} - {dayjs(education.endDate).format('MMMM YYYY')}</Typography>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box mb={2}>
                                            <Typography variant="body1">
                                                Major
                                            </Typography>
                                            <Typography variant="body2">
                                                {education.major}
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <Typography variant="body1">
                                                GPA
                                            </Typography>
                                            <Typography variant="body2">
                                                {education.gpa}
                                            </Typography>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Box>
                    </Box>

                    <Box mb={7} className="skills-section">
                        <Box className="section-header" display="flex">
                            <FontAwesomeIcon icon={faPenNib} size="2x" />
                            <h2 className="header-title">Skills</h2>
                        </Box>

                        <Box px={3}>
                            {skills.length > 0 && skills.map((skill, index) => (
                                <Accordion square expanded={expanded === `skill_${index}`} onChange={handlePanelChange(`skill_${index}`)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="skill-panel-content"
                                        id="skill-panel-header"
                                    >
                                        <Typography classes={{subtitle1: classes.subTitle}} variant="subtitle1">{skill.skillSectionName}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {skill.skills.length > 0 && skill.skills.map((subSkill, index) => (
                                            <Typography>{subSkill.name}</Typography>
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Box>
                    </Box>

                    <Box mb={7} className="awards-section">
                        <Box className="section-header" display="flex">
                            <FontAwesomeIcon icon={faAward} size="2x" />
                            <h2 className="header-title">Awards</h2>
                        </Box>
                        <Box px={3}>
                            {awards.length > 0 && awards.map((award, index) => (
                                <Accordion square expanded={expanded === `award_${index}`} onChange={handlePanelChange(`award_${index}`)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="award-panel-content"
                                        id="award-panel-header"
                                    >
                                        <Typography classes={{subtitle1: classes.subTitle}} variant="subtitle1">{award.name}, {award.awarder}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {award.description}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Box>
                    </Box>
                    <Box className="preferences-section">
                        <Box className="section-header" display="flex">
                            <FontAwesomeIcon icon={faCog} size="2x" />
                            <h2 className="header-title">Internship Preferences</h2>
                        </Box>
                        <Box px={3}>
                            <Accordion square expanded={expanded === `Industry`} onChange={handlePanelChange(`Industry`)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="skill-panel-content"
                                    id="skill-panel-header"
                                >
                                    <Typography classes={{subtitle1: classes.subTitle}} variant="subtitle1">Industry</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {!edit && 
                                        <>
                                        {industries.map((industry) => (
                                            <button className="tag-button border-gradient-purple">{industry_options[industry.industryPicked-1].label}</button>
                                        ))}
                                        <button className="tag-edit" onClick={toggleEdit}>Edit</button>
                                        </>
                                    }
                                    {edit && 
                                        <>
                                        <Q1></Q1>
                                        <button className="tag-edit" onClick={toggleEdit}>Back</button>
                                        <button className="tag-edit" onClick={updateIndustry}>Save</button>
                                        </>
                                    }

                                </AccordionDetails>
                            </Accordion>
                            <Accordion square expanded={expanded === `Job Title`} onChange={handlePanelChange(`Job Title`)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="skill-panel-content"
                                    id="skill-panel-header"
                                >
                                    <Typography classes={{subtitle1: classes.subTitle}} variant="subtitle1">Job Title</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {!edit && 
                                        <>
                                        {titles.map((title) => (
                                            <button className="tag-button border-gradient-purple">{title.titlePicked}</button>
                                        ))}
                                        <button className="tag-edit" onClick={toggleEdit}>Edit</button>
                                        </>                                    }
                                    {edit && 
                                        <>
                                        <Q2></Q2>
                                        <button className="tag-edit" onClick={toggleEdit}>Back</button>
                                        <button className="tag-edit" onClick={updateJobTitle}>Save</button>
                                        </>
                                    }


                                </AccordionDetails>
                            </Accordion>
                            <Accordion square expanded={expanded === `Company Size`} onChange={handlePanelChange(`Company Size`)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="skill-panel-content"
                                    id="skill-panel-header"
                                >
                                    <Typography classes={{subtitle1: classes.subTitle}} variant="subtitle1">Company Size</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {!edit &&
                                        <>
                                        {sizes.map((size) => (
                                            <button className="tag-button border-gradient-purple">{Object.keys(sizesPrevious)[size.internshipSize1-1]}</button>
                                        ))}
                                        <button className="tag-edit" onClick={toggleEdit}>Edit</button>
                                        </>  
                                    }
                                    {edit && 
                                        <>
                                        <Q3></Q3>
                                        <button className="tag-edit" onClick={toggleEdit}>Back</button>
                                        <button className="tag-edit" onClick={updateCompanySize}>Save</button>
                                        </>
                                    }

                                </AccordionDetails>
                            </Accordion>
                            <Accordion square expanded={expanded === `Internship Type`} onChange={handlePanelChange(`Internship Type`)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="skill-panel-content"
                                    id="skill-panel-header"
                                >
                                    <Typography classes={{subtitle1: classes.subTitle}} variant="subtitle1">Internship Type</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {!edit && 
                                        <>
                                        {preference.contract && <button className="tag-button border-gradient-purple">Contract</button>}
                                        {preference.fullTime && <button className="tag-button border-gradient-purple">Full-time</button>}
                                        {preference.paid && <button className="tag-button border-gradient-purple">Paid</button>}
                                        {preference.partTime && <button className="tag-button border-gradient-purple">Part-time</button>}
                                        {preference.remote && <button className="tag-button border-gradient-purple">Remote</button>}
                                        {preference.temporary && <button className="tag-button border-gradient-purple">Temporary</button>}
                                        {preference.volunteer && <button className="tag-button border-gradient-purple">Volunteer</button>}

                                        <button onClick={toggleEdit} className="tag-edit">Edit</button>
                                        </>
                                    }
                                    {edit && 
                                        <>
                                        <Q4></Q4>
                                        <button className="tag-edit" onClick={toggleEdit}>Back</button>
                                        <button className="tag-edit" onClick={updateInternshipType}>Save</button>
                                        </>
                                    }

                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </Box>
                </Box>
                <Box className = "right-side">
                    <Box mb={7} className="experience-section">
                        <Box className="section-header" display="flex">
                            <FontAwesomeIcon icon={faBriefcase} size="2x" />
                            <h2 className="header-title">Experience</h2>
                        </Box>
                        <Box px={3}>
                            {experiences.length > 0 && experiences.map((experience, index) => (
                                <Accordion square expanded={expanded === `experience_${index}`} onChange={handlePanelChange(`experience_${index}`)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="experience-panel-content"
                                        id="experience-panel-header"
                                    >
                                        <Typography classes={{subtitle1: classes.subTitle}} variant="subtitle1">{experience.jobTitle}</Typography>
                                        <Typography classes={{subtitle2: classes.companyName}} variant="subtitle2">{experience.companyName}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <ul>
                                            {experience.experienceDescriptions.length > 0 && experience.experienceDescriptions.map((description, index) => (
                                                <li>{description.description}</li>
                                            ))}
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Box>
                    </Box>

                    <Box mb={7} className="project-section">
                        <Box className="section-header" display="flex">
                            <FontAwesomeIcon icon={faTasks} size="2x" />
                            <h2 className="header-title">Project</h2>
                        </Box>
                        <Box px={3}>
                            {projects.length > 0 && projects.map((project, index) => (
                                <Accordion square expanded={expanded === `project_${index}`} onChange={handlePanelChange(`project_${index}`)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="project-panel-content"
                                        id="project-panel-header"
                                    >
                                        <Typography classes={{subtitle1: classes.subTitle}} variant="subtitle1">{project.projectName}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>{project.projectDescription}</Typography>
                                        <Box mt={1} display="flex">
                                            <Box mr={2}>
                                                <Typography variant="body2">Skills: </Typography>
                                            </Box>
                                            
                                            {project.projectTools.length > 0 && project.projectTools.map((tool, index) => (
                                                <Box mr={1}>
                                                    <Typography variant="body2">{tool.toolName}</Typography>
                                                </Box>
                                                
                                            ))}
                                        </Box>
                                        
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Box>
                    </Box>

                    <Box classname="language-section">
                        <Box className="section-header" display="flex">
                            <FontAwesomeIcon icon={faGlobe} size="2x" />
                            <h2 className="header-title">Language</h2>
                        </Box>
                        {user.language ? (
                            <Box mx={3} className={classes.language}>
                                {user.language}
                            </Box>
                            ) : null
                        }
                    </Box>

                    <Box classname="savedInternship-section">
                        <Box className="section-header" display="flex">
                            <FontAwesomeIcon icon={faBookmark} size="2x" />
                            <h2 className="header-title">Saved Internships</h2>
                        </Box>
                        {
                            savedInternships.map((internship, index) => {
                                return (<Box px={3}>
                                    <Accordion square expanded={false} onChange={() => {setTopModal(savedInternships[index]); setShowApplyModal(true); toggleModal()}}>
                                        <AccordionSummary
                                            expandIcon={<Clear onClick={(e) => {e.stopPropagation(); deleteSavedInternship(index)}}/>}
                                            aria-controls="experience-panel-content"
                                            id="experience-panel-header"
                                        >
                                            <Typography classes={{subtitle1: classes.subTitle}} variant="subtitle1">{internship.internship.internshipTitle}</Typography>
                                            <Typography classes={{subtitle2: classes.companyName}} variant="h6">{internship.internship.company.companyName}</Typography>
                                            <Typography classes={{subtitle3: classes.location}} variant="subtitle3">{internship.internship.company.companyHeadquarters}</Typography>
                                        </AccordionSummary>
                                    </Accordion>
                                </Box>)
                            })
                        }
                        
                    </Box>

                    <Box classname="appliedInternship-section">
                        <Box className="section-header" display="flex">
                            <FontAwesomeIcon icon={faCheck} size="2x" />
                            <h2 className="header-title">Applied Internships</h2>
                        </Box>
                        {
                            appliedInternships.map((internship, index) => {
                                return (<Box px={3}>
                                    <Accordion square expanded={false} onChange={() => {setTopModal(appliedInternships[index]); setShowApplyModal(false); toggleModal()}}>
                                        <AccordionSummary
                                            aria-controls="experience-panel-content"
                                            id="experience-panel-header"
                                        >
                                            <Typography classes={{subtitle1: classes.subTitle}} variant="subtitle1">{internship.internship.internshipTitle}</Typography>
                                            <Typography classes={{subtitle2: classes.companyName}} variant="h6">{internship.internship.company.companyName}</Typography>
                                            <Typography classes={{subtitle3: classes.location}} variant="subtitle3">{internship.internship.company.companyHeadquarters}</Typography>
                                        </AccordionSummary>
                                    </Accordion>
                                </Box>)
                            })
                        }
                    </Box>
                </Box>
            </Box>
        </div>
    )
}

export default ProfilePage;