import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import "./style.css";
import NoraHome from "../../components/NoraHome/NoraHome";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { useMediaQuery } from '@mui/material';
import Footer from '../../components/Footer';
import Review5 from "../../images/homepage/review5.png";
import Review6 from "../../images/homepage/review6.png";
import Review7 from "../../images/homepage/review7.png";
import Review8 from "../../images/homepage/review8.png";
import Review9 from "../../images/homepage/review9.png";
import PersonalizedPositions from "../../images/homepage/personalizedPositions.png";
import InteractiveResponses from "../../images/homepage/interactiveResponses.png";
import ComprehensiveFeedback from "../../images/homepage/comprehensiveFeedback.png";

import 'swiper/swiper-bundle.css';
import 'swiper/css/pagination';
import SwiperCore from 'swiper';
SwiperCore.use([Pagination]);

const MockInterviewer = () => {

    const [openIndex, setOpenIndex] = useState(null);
    const faqRefs = useRef([]);
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');

    const faqs = [
        {
            question: "Can Nora help me improve specific skills, like communication or technical knowledge?",
            answer: "Yes! Nora's got you covered. Whether it's technical know-how, communication skills, or overall interview performance, Nora gives you feedback on where you can improve and offers tips to help you level up."
        },
        {
            question: "Does Nora handle non-native speakers or users with accents?",
            answer: "Nora is designed to understand a variety of accents and speech patterns - you'll have an awesome experience regardless :)"
        },
        {
            question: "Can I track my progress and compare different interview sessions?",
            answer: "Yep, you sure can! Nora lets you look back at each mock interview, track your progress, and compare how you've done over time. It's a great way to see what's improving and what might still need some work."
        },
        {
            question: "Does Nora provide questions for both technical and non-technical roles?",
            answer: "Nora's got questions for all kinds of roles, whether you're going for something technical or more on the non-tech side. You can also toggle how technical or behavioral you want the interview to be. "
        }
    ];

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    useEffect(() => {
        const isMobile = window.innerWidth <= 600;
        faqRefs.current.forEach((ref, idx) => {
            if (ref) {
                if (openIndex === idx) {
                    const contentHeight = ref.scrollHeight;
                    ref.style.maxHeight = `${contentHeight}px`;

                    if (isMobile) {
                        // For mobile: start at 170px, add extra padding based on content
                        const basePadding = 165;
                        const extraPadding = Math.min(contentHeight * 0.1, 100);
                        const totalPadding = basePadding + extraPadding;
                        ref.style.padding = `0 20px ${totalPadding}px`;
                    } else {
                        // For desktop: fixed 50px padding
                        ref.style.padding = '0 20px 70px';
                    }
                } else {
                    ref.style.maxHeight = '0';
                    ref.style.padding = '0 20px';
                }
            }
        });
    }, [openIndex]);

    return (
        <div className="mock-interview-page">
            <NoraHome />
            <div className={!isMobile && "noraRow"}>
                <div>
                    <div className={isMobile ? "noraRowHead-mobile" : "noraRowHead"}>Personalized Positions</div>
                    <div className={isMobile ? "noraRowText-mobile" : "noraRowText"}>Customize the interview to the company and position you want 
                        by uploading your resume and adding a custom job description
                    </div>
                </div>
                <img src={PersonalizedPositions} className={isMobile ? "imageStyle-mobile" : "imageStyle1"} alt="Personalized Positions" />
            </div>
            <div className={!isMobile && "noraRow"}>
               { !isMobile && <img src={InteractiveResponses} alt="Interactive Responses" className="imageStyle2" />}
               <div style={isMobile ? {} : { marginLeft: "16%" }}>
                    <div className={isMobile ? "noraRowHead-mobile" : "noraRowHead"}>Interactive Responses</div>
                    <div className={isMobile ? "noraRowText-mobile" : "noraRowText"}>Simulate real life interviews with interactive and
                        intelligent responses to your answers
                    </div>
                </div>
                { isMobile && <img src={InteractiveResponses} alt="Interactive Responses" className="imageStyle-mobile"/>}
            </div>
            <div className={!isMobile && "noraRow"}>
            <div>
                    <div className={isMobile ? "noraRowHead-mobile" : "noraRowHead"}>Comprehensive Feedback</div>
                    <div className={isMobile ? "noraRowText-mobile" : "noraRowText"}>Receive customized feedback on each technical and behavioral question, 
                        as well as next steps and suggestions for improvement 
                    </div>
                </div>
                <img src={ComprehensiveFeedback} alt="Comprehensive Feedback" className={isMobile ? "imageStyle-mobile" : "imageStyle3"}/>
            </div>
            <div className="interviewReviews pad alignLeft">Hear From Interviewees Like You</div>
            <div>
                <Swiper
                    spaceBetween={0}
                    slidesPerView={isMobile ? 1 : 3}
                    modules={[Pagination]}
                    pagination={{ clickable: true }}
                    style={{ maxWidth: window.innerWidth * 0.9, margin: 'auto', paddingBottom: '70px', paddingTop: '20px' }}
                >
                    <SwiperSlide>
                        <div className="card">
                            <div className="card-top">
                                <img
                                    src={Review5}
                                    alt="Samantha Chou Photo"
                                    className="card-image-styling"
                                />
                            </div>
                            <div className="card-content">
                                <h3>Samantha Chou</h3>
                                <p className="title">Cognitive Science, Senior</p>
                                <p className="quote">
                                    “Nora is a helpful and easy-to-use mock interview tool. 
                                    Love the interactive nature and the question-by-question feedback and analysis! 
                                    Very useful in the job searching process!”
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="card">
                            <div className="card-top">
                                <img
                                    src={Review6}
                                    alt="Carissa Wu Photo"
                                    className="card-image-styling"
                                />
                            </div>
                            <div className="card-content">
                                <h3>Carissa Wu</h3>
                                <p className="title">Computer Engineering, Senior</p>
                                <p className="quote">
                                “Great tool that simulates real life interviews. 
                                I used it to practice for a web developer position, 
                                and the questions Nora asked were what my interviewer asked me.”
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="card">
                            <div className="card-top">
                                <img
                                    src={Review7}
                                    alt="Dev Popat Photo"
                                    className="card-image-styling"
                                />
                            </div>
                            <div className="card-content">
                                <h3>Dev Popat</h3>
                                <p className="title">SWE at Roku</p>
                                <p className="quote">
                                “I completed 3 mock interviews before my actual interview at Roku, 
                                and 4 out of the 7 questions were exactly the same! 
                                This is a must-use tool for anyone preparing for interviews!”
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="card">
                            <div className="card-top">
                                <img
                                    src={Review8}
                                    alt="Julia Son Photo"
                                    className="card-image-styling"
                                />
                            </div>
                            <div className="card-content">
                                <h3>Julia Son</h3>
                                <p className="title">Cognitive Science, Junior</p>
                                <p className="quote">
                                “Easily the best way to prepare for an interview. 
                                With constructive, personalized feedback, I'd recommend Nora to anybody looking to 
                                prepare for any upcoming interviews. Such a great resource!”
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="card">
                            <div className="card-top">
                                <img
                                    src={Review9}
                                    alt="Sydney Nguyen Photo"
                                    className="card-image-styling"
                                />
                            </div>
                            <div className="card-content">
                                <h3>Sydney Nguyen</h3>
                                <p className="title">Political Science, Senior</p>
                                <p className="quote">
                                “Nora is super helpful and gives realistic questions. 
                                I love the specific focus on information provided from your resume - it's especially helpful for creative positions 
                                that require a wide variety of skills. 100% recommend!”
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>

            </div>
            <div className={isMobile ? "pad-mobile" : "pad"}>
                <h2 style={{ fontWeight: 'bold', fontSize: '35px', marginBottom: '30px'}} className="alignLeft">FAQ</h2>
                {faqs.map((faq, index) => (
                    <div className="faq-item" key={index}>
                        <button
                            className="faq-question"
                            onClick={() => toggleFAQ(index)}
                        >
                            {faq.question}
                            <span className="arrow">{openIndex === index ? '▲' : '▼'}</span>
                        </button>
                        <div
                            className="faq-answer"
                            ref={el => faqRefs.current[index] = el}
                        >
                            {faq.answer}
                        </div>
                    </div>
                ))}
            </div>
            
            <div className={isMobile ? "practiceNoraCard-mobile": "practiceNoraCard"}>
                <div style={{ textAlign: "center" }}>
                    <div className={isMobile ? "practiceNoraCardHead-mobile": "practiceNoraCardHead"}>Ready to begin mastering interviews with Nora?</div>
                    <button className="practiceNoraButton" onClick={() => navigate('/login')}>Practice Now</button>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default MockInterviewer;
