import React, { useState, useEffect } from "react";
import $ from "jquery";
import { getToken } from "../../authetication/loginState";
import { setIt, getIt} from "../../views/preSignupQuestion/localStorageFunction";
import "./style.css"
import CreatableSelect from 'react-select/creatable';

const Q2 = () => {
    const [selected, setSelected] = useState([]);
    const [option, setOption] = useState(0);
    const [titleFieldId, setTitleFieldId] = useState("BC290F9D-E490-44F6-B1CA-D873E27E9C1A");

    const ajaxSetup = () => {
        $.ajaxSetup({
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          });
    }

    const getData = () => {
        $.get(`${process.env.REACT_APP_TITLE_GET}`, {titleFieldId}, (data) => {
            var i;
            var options = [];
            var temp;
            for(i=0; i<data.length; i++){
                temp = {};
                temp['value'] = data[i].title1;
                temp['label'] = data[i].title1;
                options.push(temp);
            }
            setOption(options);
        });
    }

    const handleOnChange = (values) => {
        setSelected(values);
        setIt(JSON.stringify(values), "q2");
    }

    const getLocal = () => {
        var x = JSON.parse(getIt("q2"));
        console.log((getIt("q2")));
        console.log(JSON.parse(getIt("q2")));
        setSelected(x);
    };

    useEffect(() => {
        getLocal();
        ajaxSetup();
        getData();
      }, []);

    return(<div>
            <div className="centerText">
            <h5>What's your title in upcoming future?</h5>
            </div>
            <br></br>
            <div className="centerA">
                <CreatableSelect
                    isClearable
                    className = "page"
                    isMulti={true}
                    onChange = {handleOnChange}
                    value = {selected}
                    options={option}
                    />    
            </div>
            </div>
            );
};

export default Q2;