import React from 'react'
import "./style.css"
import Podcast from "../../images/press/podcast.jpg"
import UCI_innovation from "../../images/press/UCI.png"
import Canvas_rebel from "../../images/press/canvas_rebel.jpg"
import Shoutout_la from "../../images/press/Shoutout_LA.PNG"
import Footer from '../../components/Footer';

function PressPage() {
  return (
    <div className="press_page">
        <div className="header_section">
            <h1>Intern Guys Press</h1>
            <h4>Contact us directly at contact@internguys.com with questions or interview requests</h4>
        </div>
        <main className="press_content_container">
            <section className="grid_item">
                <div className="img_container">
                    <img src={Podcast} alt="podcast" />
                    <figcaption>Bold Journey</figcaption>
                </div>
                <p>Hear from Intern Guys founder: Hannan Hussain</p>
                <a href="https://boldjourney.com/news/meet-hannan-hussain/" target="_blank" rel="noopener noreferrer">
                    <div className="button">
                        <p>Read Now</p>
                    </div>
                </a>
            </section>
            <section className="grid_item">
                <div className="img_container">
                    <img src={UCI_innovation} alt="podcast" />
                    <figcaption>UCI Innovation</figcaption>
                </div>
                <p>Student-Founded Startup Streamlines Internships</p>
                <a href="https://innovation.uci.edu/news/student-founded-startup-streamlines-the-internship-hunt/" target="_blank" rel="noopener noreferrer">
                    <div className="button">
                        <p>Read Now</p>
                    </div>
                </a>
            </section>
            <section className="grid_item">
                <div className="img_container">
                    <img src={Canvas_rebel} alt="podcast" />
                    <figcaption>Canvas Rebel</figcaption>
                </div>
                <p>Founder's Take: Hannan's Vision for Intern Guys</p>
                <a href="https://canvasrebel.com/meet-hannan-hussain/" target="_blank" rel="noopener noreferrer">
                    <div className="button">
                        <p>Read Now</p>
                    </div>
                </a>
            </section>
            <section className="grid_item">
                <div className="img_container">
                    <img src={Shoutout_la} alt="podcast" />
                    <figcaption>Shoutout LA</figcaption>
                </div>
                <p>Meet Hannan Hussain | CEO and Founder at Intern Guys</p>
                <a href="https://shoutoutla.com/meet-hannan-hussain-ceo-and-founder-at-intern-guys/" target="_blank" rel="noopener noreferrer">
                    <div className="button">
                        <p>Read Now</p>
                    </div>
                </a>
            </section>
        </main>
        <div className="bottom_section">
            <h4>Contact us directly at contact@internguys.com with questions or interview requests</h4>
        </div>
        <Footer />
    </div>
  )
}

export default PressPage